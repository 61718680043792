const isTooManyLabels = (legends) => {
    return legends.length > 2;
};

const legendAlign = (legends) => {
    return {
        center: {
            container: {
                width: isTooManyLabels(legends) ? '33%' : `${100 / legends.length}%`,
                textAlign: isTooManyLabels(legends) ? 'left' : 'center',
            },
            label: {
                marginLeft: isTooManyLabels(legends) ? '33%' : 0,
            },
        },
    };
};

export default legendAlign;
