import React, { useRef, useState } from 'react';
import { Grid, Paper } from '@mui/material';
import SubcategoryBreakdown from '../../dashboard/components/SubcategoryBreakdown';
import ByCategory from '../../dashboard/components/ByCategory';
import { DASHBOARD_VISUALIZATION_TYPES } from '../../../helpers/constans';
import TotalEmissionsPaper from '../../dashboard/components/TotalEmissionsPaper';
import ByScope from '../../dashboard/components/ByScope';
import BySelectedCategories from '../../dashboard/components/BySelectedCategories';
import BySelectedTags from '../../dashboard/components/BySelectedTags';
import BySelectedCategory from '../../dashboard/components/BySelectedCategory';
import BySelectedScope from '../../dashboard/components/BySelectedScope';
import BySelectedSite from '../../dashboard/components/BySelectedSite';
import BySelectedSites from '../../dashboard/components/BySelectedSites';
import BySelectedScenarios from '../../dashboard/components/BySelectedScenarios';
import PanelTitle from './PanelTitle';
import BySelectedSource from '../../dashboard/components/BySelectedSource';
import BySelectedSuppliers from '../../dashboard/components/BySelectedSuppliers';
import BySelectedSubcategory from '../../dashboard/components/BySelectedSubcategory';
import BySelectedScopesPerIntensity from '../../dashboard/components/BySelectedScopesPerIntensity';
import NetZeroTargets from '../../suppliers/components/NetZeroTargets';
import BySelectedEsgQuestion from '../../dashboard/components/BySelectedEsgQuestion';
import BySelectedIntensityPerSites from '../../dashboard/components/BySelectedIntensityPerSites';
import BySourcePerDates from '../../dashboard/components/BySourcePerDates';
import BySelectedEsgScenario from '../../dashboard/components/BySelectedEsgScenario';
import BySelectedEsgLineQuestion from '../../dashboard/components/BySelectedEsgLineQuestion';
import AddPlaceholder from '../../../components/atom/AddPlaceholder';
import BySelectedEsgPieQuestion from '../../dashboard/components/BySelectedEsgPieQuestion';

export const DisplayPanel = ({ panel, visible, yearSumData, setChartData, isPreview = false }) => {
    if (visible) {
        switch (panel.visual_type) {
            case DASHBOARD_VISUALIZATION_TYPES.BAR:
                return (
                    <ByCategory
                        yearSumData={yearSumData}
                        setChartData={setChartData}
                        isPreview={isPreview}
                    />
                );
            // case DASHBOARD_VISUALIZATION_TYPES.RADIAL_BAR:
            //     return (
            //         <Grid container>
            //             <DataCompletion yearSumData={yearSumData} />
            //         </Grid>
            //     );
            case DASHBOARD_VISUALIZATION_TYPES.PIE:
                return (
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <SubcategoryBreakdown yearSumData={yearSumData} />
                        </Grid>
                    </Grid>
                );
            case DASHBOARD_VISUALIZATION_TYPES.TOTAL_EMISSIONS:
                return <TotalEmissionsPaper yearSumData={yearSumData} customDashboard />;
            case DASHBOARD_VISUALIZATION_TYPES.SCOPE_LINE_CHART:
                return <ByScope scopes={panel.config.scopes} setChartData={setChartData} />;
            case DASHBOARD_VISUALIZATION_TYPES.CATEGORY_BAR_CHART:
                return (
                    <BySelectedCategories
                        categories={panel.config.categories}
                        years={panel.config.years}
                        setChartData={setChartData}
                    />
                );
            case DASHBOARD_VISUALIZATION_TYPES.TAG_BAR_CHART:
                return (
                    <BySelectedTags
                        tags={panel.config.tags}
                        years={panel.config.years}
                        setChartData={setChartData}
                    />
                );
            case DASHBOARD_VISUALIZATION_TYPES.SCOPE_PIE_CHART:
                return (
                    <BySelectedScope
                        scope={panel.config.scope}
                        year={panel.config.year}
                        setChartData={setChartData}
                    />
                );
            case DASHBOARD_VISUALIZATION_TYPES.CATEGORY_PIE_CHART:
                return (
                    <BySelectedCategory
                        category={panel.config.category}
                        year={panel.config.year}
                        setChartData={setChartData}
                    />
                );
            case DASHBOARD_VISUALIZATION_TYPES.SUBCATEGORY_PIE_CHART:
                return (
                    <BySelectedSubcategory
                        subcategory={panel.config.subcategory}
                        year={panel.config.year}
                        setChartData={setChartData}
                    />
                );
            case DASHBOARD_VISUALIZATION_TYPES.SITE_PIE_CHART:
                return (
                    <BySelectedSite
                        site={panel.config.site}
                        year={panel.config.year}
                        setChartData={setChartData}
                    />
                );
            case DASHBOARD_VISUALIZATION_TYPES.SITE_BAR_CHART:
                return (
                    <BySelectedSites
                        sites={panel.config.sites}
                        year={panel.config.year}
                        categories={panel.config.categories}
                        scopes={panel.config.scopes}
                        setChartData={setChartData}
                    />
                );
            case DASHBOARD_VISUALIZATION_TYPES.SCENARIO_LINE_CHART:
                return (
                    <BySelectedScenarios
                        scenarios={panel.config.scenarios}
                        setChartData={setChartData}
                    />
                );
            case DASHBOARD_VISUALIZATION_TYPES.SOURCE_BAR_CHART:
                return (
                    <BySelectedSource
                        source={panel.config.source}
                        years={panel.config.years}
                        setChartData={setChartData}
                    />
                );
            case DASHBOARD_VISUALIZATION_TYPES.SUPPLIER_BAR_CHART:
                return (
                    <BySelectedSuppliers
                        suppliers={panel.config.suppliers}
                        years={panel.config.years}
                        setChartData={setChartData}
                    />
                );
            case DASHBOARD_VISUALIZATION_TYPES.SCOPES_PER_INTENSITY_LINE_CHART:
                return (
                    <BySelectedScopesPerIntensity
                        scopes={panel.config.scopes}
                        years={panel.config.years}
                        intensityMetric={panel.config.intensityMetric}
                        aggregated={panel.config.aggregated}
                        setChartData={setChartData}
                    />
                );
            case DASHBOARD_VISUALIZATION_TYPES.SUPPLIER_NETZERO_TARGETS_BAR_CHART:
                return <NetZeroTargets year={panel.config.year} setChartData={setChartData} />;
            case DASHBOARD_VISUALIZATION_TYPES.ESG_QUESTION_BAR_CHART:
                return (
                    <BySelectedEsgQuestion
                        esgQuestion={panel.config.esgQuestion}
                        sites={panel.config.sites}
                        includeTotal={panel.config.includeTotal}
                        years={panel.config.years.sort((a, b) => a - b)}
                        fields={panel.config.fields}
                        setChartData={setChartData}
                    />
                );
            case DASHBOARD_VISUALIZATION_TYPES.SITES_PER_INTENSITY_BAR_CHART:
                return (
                    <BySelectedIntensityPerSites
                        intensityMetric={panel.config.intensityMetric}
                        siteIdList={panel.config.sites}
                        years={panel.config.years}
                        scope={panel.config?.scope}
                        catId={panel.config?.category}
                        setChartData={setChartData}
                    />
                );
            case DASHBOARD_VISUALIZATION_TYPES.SOURCE_PER_DATES_LINE_CHART:
                return (
                    <BySourcePerDates
                        year={panel.config.year}
                        source={panel.config.source}
                        sites={panel.config.sites}
                        aggregated={panel.config.aggregated}
                        unit={panel.config.unit}
                        setChartData={setChartData}
                    />
                );
            case DASHBOARD_VISUALIZATION_TYPES.ESG_SCENARIO_LINE_CHART:
                return (
                    <BySelectedEsgScenario
                        scenario={panel.config.scenario}
                        esgQuestion={panel.config.esgQuestion}
                        fields={panel.config.fields}
                        setChartData={setChartData}
                    />
                );
            case DASHBOARD_VISUALIZATION_TYPES.ESG_QUESTION_LINE_CHART:
                return (
                    <BySelectedEsgLineQuestion
                        esgQuestion={panel.config.esgQuestion}
                        sites={panel.config.sites}
                        includeTotal={panel.config.includeTotal}
                        fromYear={panel.config.fromYear}
                        toYear={panel.config.toYear}
                        fields={panel.config.fields}
                        setChartData={setChartData}
                    />
                );
            case DASHBOARD_VISUALIZATION_TYPES.ESG_QUESTION_PIE_CHART:
                return (
                    <BySelectedEsgPieQuestion
                        esgQuestion={panel.config.esgQuestion}
                        fields={panel.config.fields}
                        year={panel.config.year}
                        setChartData={setChartData}
                    />
                );
            default:
                return null;
        }
    } else {
        return <AddPlaceholder />;
    }
};

export default function DashboardPanel({
    panel,
    visible,
    yearSumData,
    onPanelUpdate,
    onPanelDelete,
    panelId,
    readOnly,
}) {
    const chartRef = useRef(null);
    const [chartData, setChartData] = useState(null);

    return (
        <Grid>
            <Paper sx={{ p: 2, borderColor: 'transparent' }} ref={chartRef}>
                <PanelTitle
                    panel={panel}
                    panelId={panelId}
                    onPanelUpdate={onPanelUpdate}
                    onPanelDelete={onPanelDelete}
                    readOnly={readOnly}
                    chartRef={chartRef}
                    hideExportButtons={
                        panel.visual_type === DASHBOARD_VISUALIZATION_TYPES.PIE ||
                        panel.visual_type === DASHBOARD_VISUALIZATION_TYPES.TOTAL_EMISSIONS
                    }
                    chartData={chartData}
                />
                <DisplayPanel
                    visible={visible}
                    yearSumData={yearSumData}
                    panel={panel}
                    setChartData={setChartData}
                />
            </Paper>
        </Grid>
    );
}
