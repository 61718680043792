import { Grid } from '@mui/material';
import React from 'react';
import SurveyQuestionHeadline from '../../components/SurveyQuestionHeadline';
import { SURVEY_BUTTON_TYPES, SurveyButton } from '../../components/SurveyButton';
import {
    withFormikContext,
    withStepAndValidation,
    withSurveySection,
} from '../../components/SurveyStep';

function QReportPerScopeHas({ values, setFieldValue, year, companyName }) {
    const havePerScopeData = values?.details?.havePerScopeData;
    const setHavePerScopeData = (newState) => setFieldValue('details.havePerScopeData', newState);

    return (
        <>
            <Grid item xs={12}>
                <SurveyQuestionHeadline>
                    Do you have a per scope breakdown of the CO2e (mt) of products/services sold to{' '}
                    {companyName} in {year}?
                </SurveyQuestionHeadline>
                <small>
                    If not, we will automatically calculate based on a 15% - 15% - 70% (70% to Scope
                    3) split between scopes.
                </small>
            </Grid>
            <Grid item xs={6}>
                <SurveyButton
                    type={SURVEY_BUTTON_TYPES.YES}
                    active={havePerScopeData === true}
                    onClick={() => {
                        setHavePerScopeData(true);
                    }}
                >
                    Yes
                </SurveyButton>
            </Grid>
            <Grid item xs={6}>
                <SurveyButton
                    type={SURVEY_BUTTON_TYPES.NO}
                    active={havePerScopeData === false}
                    onClick={() => {
                        setHavePerScopeData(false);
                    }}
                >
                    No
                </SurveyButton>
            </Grid>
        </>
    );
}

export default withStepAndValidation(
    withSurveySection(withFormikContext(QReportPerScopeHas)),
    3,
    ['details.havePerScopeData'], // no validation
    (values, errors) => {
        if (typeof values?.details?.havePerScopeData === 'undefined') {
            // eslint-disable-next-line no-param-reassign
            errors['details.havePerScopeData'] = 'Please pick an answer!';
        }
        return errors;
    },
    (values) => values?.details?.haveExactData === true
);
