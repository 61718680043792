import { Alert, Grid } from '@mui/material';
import React from 'react';
import SurveyQuestionHeadline from '../../components/SurveyQuestionHeadline';
import { SURVEY_BUTTON_TYPES, SurveyButton } from '../../components/SurveyButton';
import {
    withFormikContext,
    withStepAndValidation,
    withSurveySection,
} from '../../components/SurveyStep';

function QScopeThreeHasCatOne({ values, setFieldValue, year }) {
    const hasScope3Cat1Report = values?.details?.hasScope3Cat1Report;
    const setHasScope3Cat1Report = (newState) =>
        setFieldValue('details.hasScope3Cat1Report', newState);

    const disabledStep3 = values?.details?.datalines?.length > 0 && hasScope3Cat1Report === false;
    return (
        <>
            <Grid item xs={12}>
                <SurveyQuestionHeadline>
                    Have you quantified the following Scope 3: Purchased Goods and Services CO2mte
                    (carbon dioxide metric tons equivalent) associated with your business in {year}?
                </SurveyQuestionHeadline>
            </Grid>
            {disabledStep3 === true && (
                <Grid item xs={12}>
                    <Alert sx={{ mt: 1 }} severity="warning">
                        You already provided information in the &quot;GHG Emissions calculator&quot;
                        section.
                        <br />
                        If you provide information here, GHG Emissions calculator inputs will be
                        ignored.
                    </Alert>
                </Grid>
            )}
            <Grid item xs={6}>
                <SurveyButton
                    type={SURVEY_BUTTON_TYPES.YES}
                    active={hasScope3Cat1Report === true}
                    onClick={() => {
                        setHasScope3Cat1Report(true);
                    }}
                >
                    Yes
                </SurveyButton>
            </Grid>
            <Grid item xs={6}>
                <SurveyButton
                    type={SURVEY_BUTTON_TYPES.NO}
                    active={hasScope3Cat1Report === false}
                    onClick={() => {
                        setHasScope3Cat1Report(false);
                    }}
                >
                    No
                </SurveyButton>
            </Grid>
        </>
    );
}

export default withStepAndValidation(
    withSurveySection(withFormikContext(QScopeThreeHasCatOne)),
    4,
    ['details.hasScope3Cat1Report'], // no validation
    (values, errors) => {
        if (typeof values?.details?.hasScope3Cat1Report === 'undefined') {
            // eslint-disable-next-line no-param-reassign
            errors['details.hasScope3Cat1Report'] = 'Please select an option.';
        }
        return errors;
    },
    (values) =>
        values?.details?.hasScope12FullReport === true && values?.details?.haveExactData === false
);
