import React from 'react';
import {
    FormControl,
    Grid,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    Tooltip,
} from '@mui/material';
import {
    filterJsonConstantsByMeasure,
    unitConverter,
    WITHOUT_UNIT,
} from '../../../../helpers/esgUnitConversions';
import QCustomText from './QCustomText';
import QCustomSelect from './QCustomSelect';
import QCustomCertification from './QCustomCertification';
import QCustomFile from './QCustomFile';
import QCustomNumber from './QCustomNumber';

// check if the measure is visible, type is default to number since there is a case where we do not need this check
function isMeasureVisible(measure, defaultMeasure, type = 'number') {
    if (type === 'text' || type === 'select' || type === 'file' || type === 'certification') {
        return false;
    }
    if (measure && measure === WITHOUT_UNIT) {
        return false;
    }
    if (!measure && defaultMeasure === WITHOUT_UNIT) {
        return false;
    }
    return true;
}

const QCustomForm = ({
    id,
    idx,
    qIdx,
    label,
    type,
    required,
    defaultMeasure,
    values,
    setFieldValue,
    error,
}) => {
    const commonInputProps = {
        endAdornment: isMeasureVisible(
            values.customDetails[qIdx].fields[idx]?.measure,
            defaultMeasure,
            type,
            values.customDetails[qIdx].fields[idx]?.forceOverride
        ) && (
            <Grid item>
                {!isMeasureVisible(
                    values.customDetails[qIdx].fields[idx]?.measure,
                    defaultMeasure,
                    type
                ) ? (
                    <Tooltip title="This unit is not editable" placement="top">
                        <InputAdornment position="end">
                            {values.customDetails[qIdx].fields[idx]?.measure || defaultMeasure}
                        </InputAdornment>
                    </Tooltip>
                ) : (
                    <FormControl size="small" fullWidth>
                        <InputLabel id="measure-select" />
                        <Select
                            labelId="measure-select"
                            className="question-field"
                            value={
                                values.customDetails[qIdx].fields[idx]?.measure || defaultMeasure
                            }
                            size="small"
                            onChange={(e) => {
                                setFieldValue(`customDetails[${qIdx}].fields[${idx}]`, {
                                    id,
                                    value: unitConverter(
                                        values.customDetails[qIdx].fields[idx]?.value,
                                        values.customDetails[qIdx].fields[idx]?.measure ||
                                            defaultMeasure,
                                        e.target.value
                                    ),
                                    measure: e.target.value,
                                });
                            }}
                        >
                            {filterJsonConstantsByMeasure(
                                values.customDetails[qIdx].fields[idx]?.measure || defaultMeasure
                            ).map((item) => (
                                <MenuItem key={item.name} value={item.name}>
                                    {item.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                )}
            </Grid>
        ),
    };

    return (
        <Grid item xs={12} key={id} sx={{ mt: 2 }}>
            {type === 'number' && (
                <QCustomNumber
                    label={label}
                    values={values}
                    qIdx={qIdx}
                    idx={idx}
                    id={id}
                    setFieldValue={setFieldValue}
                    error={error}
                    required={required}
                    defaultMeasure={defaultMeasure}
                    commonInputProps={commonInputProps}
                />
            )}
            {type === 'text' && (
                <QCustomText
                    label={label}
                    values={values}
                    id={id}
                    qIdx={qIdx}
                    idx={idx}
                    defaultMeasure={defaultMeasure}
                    setFieldValue={setFieldValue}
                    required={required}
                    error={error}
                />
            )}
            {type === 'select' && (
                <QCustomSelect
                    label={label}
                    values={values}
                    qIdx={qIdx}
                    idx={idx}
                    id={id}
                    setFieldValue={setFieldValue}
                    error={error}
                    required={required}
                    defaultMeasure={defaultMeasure}
                    commonInputProps={commonInputProps}
                />
            )}
            {type === 'certification' && (
                <QCustomCertification
                    label={label}
                    values={values}
                    qIdx={qIdx}
                    idx={idx}
                    id={id}
                    setFieldValue={setFieldValue}
                    error={error}
                    required={required}
                    defaultMeasure={defaultMeasure}
                    commonInputProps={commonInputProps}
                />
            )}
            {type === 'file' && (
                <QCustomFile
                    label={label}
                    values={values}
                    qIdx={qIdx}
                    idx={idx}
                    id={id}
                    setFieldValue={setFieldValue}
                    error={error}
                    required={required}
                    defaultMeasure={defaultMeasure}
                />
            )}
        </Grid>
    );
};

export default QCustomForm;
