import React, { useContext, useState, useEffect, useCallback, useMemo } from 'react';
import { useFormikContext } from 'formik';
import SurveySection from './SurveySection';
import useNotify from '../../../hooks/useNotify';

const SurveyStepContext = React.createContext();

const SHOW_ALL_QUESTIONS_AT_ONCE = false;

// just a static object to ignore all rerenders
const NO_ERRORS_GLOBAL = {};

export function SurveyStepContextProvider({
    setStep,
    step,
    validationFunctionRef,
    isGhg,
    isCustomQuestion,
    children,
}) {
    const { isSubmitting, errors } = useFormikContext();
    const [validationFunctions, setValidationFunctions] = useState([]);
    const [fieldInStep, setFieldInStep] = useState({});
    const { notifyError } = useNotify();

    // on submit, we need to check if there is any error, and if there is, we need to jump to the question
    useEffect(() => {
        if (!isSubmitting) {
            return;
        }
        if (Object.keys(errors).length === 0) {
            return;
        }
        // find the first error
        let firstStepWithError = Infinity;
        Object.entries(fieldInStep).forEach(([stepNumber, fields]) => {
            fields.forEach((field) => {
                if (typeof errors[field] !== 'undefined') {
                    firstStepWithError = Math.min(stepNumber, firstStepWithError);
                }
            });
        });
        setStep(firstStepWithError);
        notifyError('Cannot save survey, please fix the errors first.');
    }, [isSubmitting, errors, fieldInStep, setStep, notifyError]);

    /**
     * Add a validation function to the list of validation functions
     */
    const addValidation = useCallback((validationFunction) => {
        setValidationFunctions((old) =>
            !old.includes(validationFunction) ? [...old, validationFunction] : old
        );
    }, []);

    /**
     * Add a field to the proper step, since we need to know which fields are in which step
     */
    const addFieldIntoStep = useCallback(
        (field, targetStep) => {
            setFieldInStep((old) => {
                const newFieldsInStep = { ...old };
                if (!newFieldsInStep[targetStep]) {
                    newFieldsInStep[targetStep] = [];
                }
                if (!newFieldsInStep[targetStep].includes(field)) {
                    newFieldsInStep[targetStep] = [...newFieldsInStep[targetStep], field];
                }
                return newFieldsInStep;
            });
        },
        [step]
    );

    useEffect(() => {
        const newValidationFunction = (values) => {
            // do each validation one by one, ranking up the errors in there
            let errors = {};
            for (let i = 0; i < validationFunctions.length; i += 1) {
                const validationFunction = validationFunctions[i];
                try {
                    errors = validationFunction(values, errors);
                    if (typeof errors !== 'object') {
                        throw new Error('Validation function did not return an object');
                    }
                } catch (err) {
                    // eslint-disable-next-line no-console
                    console.error(`Validation function errored out`, validationFunction, err);
                }
            }
            return Object.keys(errors).length > 0 ? errors : NO_ERRORS_GLOBAL;
        };

        // need to fake a function creation, since storing functions in state is not that nice
        // eslint-disable-next-line no-param-reassign
        validationFunctionRef.current = newValidationFunction;
    }, [validationFunctions, validationFunctionRef]);

    const contextValue = useMemo(() => {
        return {
            setStep,
            step,
            addValidation,
            addFieldIntoStep,
            fieldInStep,
            isGhg,
            isCustomQuestion,
        };
    }, [setStep, step, addValidation, addFieldIntoStep, fieldInStep, isGhg, isCustomQuestion]);
    return <SurveyStepContext.Provider value={contextValue}>{children}</SurveyStepContext.Provider>;
}

SurveyStepContext.displayName = 'SurveyStepContext';

export function useSurveyStep() {
    return useContext(SurveyStepContext);
}

// helper function to list all fields in customDetails
function listCustomDetails(values) {
    const list = [];
    values.customDetails.forEach((question, qIdx) => {
        question.fields.forEach((field, idx) => {
            list.push(`customDetails-${qIdx}-fields-${idx}`);
        });
    });
    return list;
}

export function withStepAndValidation(
    Component,
    inStep,
    fields = [],
    validation = false,
    activeCheck = false,
    customQuestions = false
) {
    return (props) => {
        const { values, errors } = useFormikContext();
        const { step, addValidation, addFieldIntoStep, isGhg } = useSurveyStep();
        let resultFields = fields;

        // if the question is not a GHG question, we need to modify the step
        let newInStep = inStep;
        if (!isGhg && inStep !== 1) {
            newInStep = inStep - 5;
        }

        useEffect(() => {
            if (validation) {
                // if the "active check" is also present, we need to run it first, and if the question is not active
                // should not run the validation (the user cannot fix an answer that is not visible)
                let validationFunction = validation;
                if (activeCheck) {
                    validationFunction = (valuesInValidation, errorsInValidation) => {
                        if (!activeCheck(valuesInValidation)) {
                            return errorsInValidation;
                        }
                        // only run the validation if the question is active
                        return validation(valuesInValidation, errorsInValidation);
                    };
                }
                addValidation(validationFunction);
            }

            if (customQuestions) {
                resultFields = listCustomDetails(values);
            }

            resultFields.forEach((field) => {
                addFieldIntoStep(field, newInStep);
            });
        }, [step, addValidation, addFieldIntoStep, isGhg, validation, fields, inStep, activeCheck]);

        if (step !== newInStep || SHOW_ALL_QUESTIONS_AT_ONCE) {
            return null;
        }

        // !! is used to convert to boolean
        const active = activeCheck ? !!activeCheck(values) : true;

        const validationErrors = Object.keys(errors)
            .filter((field) => {
                if (customQuestions) {
                    // eslint-disable-next-line react/destructuring-assignment
                    if (field.startsWith(`customDetails-${props?.qIdx}-fields-`)) {
                        return true;
                    }
                }
                return resultFields.includes(field);
            })
            .map((field) => {
                return errors[field];
            });

        return <Component validationErrors={validationErrors} active={active} {...props} />;
    };
}

export function withSurveySection(Component) {
    return (props) => {
        const { validationErrors = false, active = true } = props;
        return (
            <SurveySection validationErrors={validationErrors} active={active}>
                <Component {...props} />
            </SurveySection>
        );
    };
}

export function withFormikContext(Component) {
    return (props) => {
        const { setFieldValue, values, errors } = useFormikContext();
        return (
            <Component setFieldValue={setFieldValue} values={values} errors={errors} {...props} />
        );
    };
}
