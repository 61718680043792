import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Grid,
    Typography,
    TextField,
} from '@mui/material';
import React from 'react';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Form, Formik } from 'formik';
import SkeletonForm from '../../../components/atom/SkeletonForm';
import Conversion from './Conversion';
import BlockWithTitle from '../../../components/atom/BlockWithTitle';
import { doSwaggerCall } from '../../../hooks/useApi';
import { useModals, MODAL_TYPE } from '../../../hooks/useModal';
import useNotify from '../../../hooks/useNotify';
import CommonButton from '../../../components/atom/CommonButton';
import { WARNING_CONTENT_RECALCULATE_TEXT } from '../../../helpers/constans';

const ConversionLogicForm = ({ data, loading, reloadData, setData }) => {
    const { notifyError, notifySuccess } = useNotify();
    const { showModal } = useModals();

    const trimFieldIds = (unitConversionLogic) => {
        const trimmedUnitConversionLogic = unitConversionLogic;
        trimmedUnitConversionLogic.map((logic, logicIdx) => {
            logic.fields.map((field, fieldIdx) => {
                trimmedUnitConversionLogic[logicIdx].fields[fieldIdx].field_id =
                    field?.field_id?.trim();
                return true;
            });
            return true;
        });
        return trimmedUnitConversionLogic;
    };

    const addNewConversionLogic = (values, setFieldValue) => {
        const newEmptyLogic = {
            id: parseInt(Math.floor(Math.random() * 1000), 10),
            name: 'New calculation',
            source: null,
            admin_note: null,
            formula: null,
            fields: [],
        };
        const editUnitConversionLogic = [...values.conversionLogics, newEmptyLogic];
        setFieldValue('conversionLogics', editUnitConversionLogic);
    };

    const onSubmit = async (values) => {
        const trimmedUnitConversionLogic = await trimFieldIds(values.conversionLogics);
        showModal(MODAL_TYPE.CONFIRMATION, {
            title: `Are you sure you want to edit this template?`,
            warningContent: WARNING_CONTENT_RECALCULATE_TEXT,
            confirm: () => {
                doSwaggerCall(
                    'ConversionTemplates',
                    'editConversionTemplate',
                    { id: data.id },
                    {
                        conversionLogics: trimmedUnitConversionLogic,
                        name: values.name,
                        baseUnit: values.baseUnit,
                    }
                )
                    .then(() => {
                        reloadData();
                        notifySuccess('Conversion logic template edited');
                    })
                    .catch((err) => notifyError(err));
            },
        });
    };

    const removeConversionLogic = (values, logicIdx, setFieldValue) => {
        const editedLogics = [...values.conversionLogics];
        editedLogics.splice(logicIdx, 1);
        setFieldValue('conversionLogics', editedLogics);
    };

    return (
        <Formik onSubmit={onSubmit} enableReinitialize initialValues={data}>
            {({ isSubmitting, values, setFieldValue }) => (
                <Form>
                    <BlockWithTitle title="Conversion logic">
                        {loading ? (
                            <SkeletonForm rows={2} />
                        ) : (
                            <>
                                <Grid item xs={12}>
                                    <TextField
                                        autoComplete="off"
                                        label="Base unit name"
                                        fullWidth
                                        required
                                        value={data?.baseUnit}
                                        onChange={(ev) =>
                                            setData({
                                                ...data,
                                                baseUnit: ev.target.value,
                                            })
                                        }
                                    />
                                </Grid>
                                <Grid item>
                                    <CommonButton
                                        onClick={() => {
                                            addNewConversionLogic(values, setFieldValue);
                                        }}
                                        label="Add new logic"
                                        buttonType="primary"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    {values?.conversionLogics?.map((_, idx) => (
                                        <Accordion>
                                            <AccordionSummary
                                                expandIcon={<ExpandMore />}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header"
                                            >
                                                <Typography>
                                                    {values.conversionLogics[idx].name}
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Conversion
                                                    idx={idx}
                                                    values={values}
                                                    setFieldValue={setFieldValue}
                                                    removeConversionLogic={removeConversionLogic}
                                                />
                                            </AccordionDetails>
                                        </Accordion>
                                    ))}
                                </Grid>
                                <Grid item xs={12}>
                                    <CommonButton
                                        fullWidth
                                        disabled={isSubmitting}
                                        type="submit"
                                        label="Save"
                                        buttonType="primary"
                                    />
                                </Grid>
                            </>
                        )}
                    </BlockWithTitle>
                </Form>
            )}
        </Formik>
    );
};

export default ConversionLogicForm;
