import {
    ButtonGroup,
    CircularProgress,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Paper,
    Select,
} from '@mui/material';
import React, { useState, useCallback, useEffect } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TreeView from '@mui/lab/TreeView';
import { useParams } from 'react-router-dom';
import { doSwaggerCall } from '../../hooks/useApi';
import ComponentTitle from '../ComponentTitle';
import CategoryLevel from './components/CategoryLevel';
import { useModals, MODAL_TYPE } from '../../hooks/useModal';
import useNotify from '../../hooks/useNotify';
import SearchInput from '../atom/SearchInput';
import recursiveTreeFilter from '../../helpers/recursiveTreeFilter';
import getCheckedChildrenNumberRecursive from '../../pages/companies/components/helpers/getCheckedChildrenNumberRecursive';
import CommonButton from '../atom/CommonButton';
import { transformHierarchyToItem } from '../../helpers/groups';
import setAvailableYear from '../../pages/companies/components/helpers/setAvailableYear';

const RequirementsHierarchy = ({
    localCompanyYear,
    setLocalCompanyYear,
    availableYears,
    checkedData,
    setCheckedData,
    reloadData,
    newCompany,
    newSite,
}) => {
    const { companyId, siteId } = useParams();
    const [dataGroups, setDataGroups] = useState([]);
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const { showModal } = useModals();
    const { notifyError } = useNotify();

    const loadHierarchy = useCallback((targetYear) => {
        setLoading(true);
        doSwaggerCall('Hierarchy', 'getSourceHierarchy', {
            year: targetYear,
            hasanydatalines: false,
            hasFactor: true,
        })
            .then((hierarchy) => {
                const transformResult = hierarchy.map((e) => transformHierarchyToItem(e));
                setDataGroups(transformResult);
                setLoading(false);
            })
            .catch((error) => {
                notifyError(error);
            });
    }, []);

    useEffect(() => {
        loadHierarchy(localCompanyYear);
    }, [localCompanyYear]);

    // search all hierarchy
    useEffect(() => {
        if (search === '') {
            setFilteredData(dataGroups);
            return;
        }
        setFilteredData(recursiveTreeFilter(dataGroups, search.toLowerCase()));
    }, [search, dataGroups]);

    const getCheckedChildrenNumber = useCallback(
        (item) => {
            return getCheckedChildrenNumberRecursive(checkedData, item);
        },
        [checkedData]
    );

    // TODO: right now, we allow the company admins to modify boundaries for closed years as well (that changes the inventory for the closed year too)

    return (
        <Grid item xs={12} sx={{ pt: 2 }}>
            <Paper sx={{ p: 2, width: '100%' }}>
                <Grid
                    container
                    item
                    xs={12}
                    justifyContent="space-between"
                    spacing={2}
                    sx={{ pb: 2 }}
                >
                    <Grid item style={{ alignSelf: 'center' }}>
                        <ComponentTitle variant="h5" title="Inventory Boundary (Hierarchy)" />
                    </Grid>
                    <Grid item>
                        <FormControl fullWidth>
                            <InputLabel id="year-select">Year</InputLabel>
                            <Select
                                fullWidth
                                labelId="year-select"
                                id="demo-simple-select"
                                value={setAvailableYear(localCompanyYear, availableYears)}
                                size="small"
                                label="Year"
                                onChange={(e) => {
                                    setLocalCompanyYear(parseInt(e.target.value, 10));
                                }}
                            >
                                {availableYears.map((yearItem) => (
                                    <MenuItem key={yearItem} value={yearItem}>
                                        {yearItem}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <ButtonGroup>
                            <CommonButton
                                onClick={() => {
                                    showModal(MODAL_TYPE.COPY_TO_ANOTHER_YEAR, {
                                        title: `Are you sure you want to copy inventory boundary from ${localCompanyYear}?`,
                                        content: 'This action is irreversible.',
                                        companyId,
                                        siteId,
                                        year: localCompanyYear,
                                        availableYears,
                                        type: 'requirements',
                                        reloadData: () => reloadData(),
                                    });
                                }}
                                disabled={newCompany || newSite}
                                label="Copy to ..."
                                buttonType="outlined"
                            />
                            <CommonButton
                                onClick={() => {
                                    showModal(MODAL_TYPE.COPY_FROM_ANOTHER_YEAR, {
                                        title: `Are you sure you want to copy inventory boundary to ${localCompanyYear}?`,
                                        content: 'This action is irreversible.',
                                        descriptionType: 'requirements',
                                        companyId,
                                        siteId,
                                        year: localCompanyYear,
                                        availableYears,
                                        reloadData: () => reloadData(),
                                    });
                                }}
                                disabled={newCompany || newSite}
                                label="Copy from ..."
                                buttonType="outlined"
                            />
                        </ButtonGroup>
                    </Grid>
                </Grid>
                <Grid container>
                    {loading && (
                        <Grid item xs={12}>
                            <CircularProgress size="1.5rem" />
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <SearchInput fullWidth search={search} setSearch={setSearch} />
                    </Grid>
                    {filteredData.map((scope) => (
                        <Grid item xs={12} key={`${scope.id} - ${scope.name}`}>
                            {scope.name}
                            {scope.children.map((category) => (
                                <TreeView
                                    key={`${scope.name} - ${category.id} - ${category.name}`}
                                    aria-label="icon expansion"
                                    defaultCollapseIcon={<ExpandMoreIcon />}
                                    defaultExpandIcon={<ChevronRightIcon />}
                                    defaultExpanded={[]}
                                >
                                    <CategoryLevel
                                        key={`${category.id} - ${category.name}`}
                                        checkedData={checkedData}
                                        category={category}
                                        setCheckedData={setCheckedData}
                                        getCheckedChildrenNumber={getCheckedChildrenNumber}
                                    />
                                </TreeView>
                            ))}
                        </Grid>
                    ))}
                </Grid>
            </Paper>
        </Grid>
    );
};

export default RequirementsHierarchy;
