import React from 'react';
import {
    Chip,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import { BACKEND_URL } from '../../../config';
import { FIELD_TYPES } from '../../../helpers/constans';

const openDocument = (document) => {
    const documentUrl = `${BACKEND_URL}/s3proxy?url=${encodeURIComponent(document)}`;
    window.open(documentUrl);
};

const SurveyCustomQuestions = ({ survey }) => {
    return (
        <>
            <Grid item xs={12}>
                <Typography variant="h5">Additional Questions</Typography>
            </Grid>
            {survey.customQuestions.length > 0 ? (
                survey.customQuestions.map((question, questionIdx) => (
                    <Grid item xs={12}>
                        <Typography variant="subtitle1">{question.name}</Typography>
                        <Table sx={{ mt: 2 }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Question</TableCell>
                                    <TableCell>Answer</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {question.fields.map(({ id, label, type }) => (
                                    <TableRow>
                                        <TableCell>{label}</TableCell>
                                        <TableCell>
                                            {(() => {
                                                const field = survey.customDetails.details[
                                                    questionIdx
                                                ]?.fields.find((f) => f.id === id);
                                                if (!field) return null;
                                                // render chips for files (if it's an array basically)
                                                if (Array.isArray(field.value)) {
                                                    return field.value.map((v) => (
                                                        <Chip
                                                            sx={{ mr: 1 }}
                                                            key={v.id}
                                                            label={v.name}
                                                            disabled={!v.url}
                                                            size="small"
                                                            onClick={() => openDocument(v.url)}
                                                            icon={<DownloadIcon />}
                                                        />
                                                    ));
                                                }
                                                // display number fields with measure if it has a measure
                                                return type === FIELD_TYPES.NUMBER &&
                                                    field.measure !== 'Without unit' &&
                                                    field.value !== ''
                                                    ? `${field.value} ${field.measure}`
                                                    : field.value || '';
                                            })()}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Grid>
                ))
            ) : (
                <Grid item xs={12}>
                    <Typography variant="body1">No Additional Questions</Typography>
                </Grid>
            )}
        </>
    );
};

export default SurveyCustomQuestions;
