import React from 'react';
import { TextField } from '@mui/material';

const QCustomText = ({
    label,
    values,
    id,
    qIdx,
    idx,
    defaultMeasure,
    setFieldValue,
    required,
    error,
}) => {
    return (
        <TextField
            multiline
            fullWidth
            autoComplete="off"
            label={label}
            value={values.customDetails[qIdx].fields[idx]?.value || ''}
            onChange={(ev) => {
                setFieldValue(`customDetails[${qIdx}].fields[${idx}]`, {
                    id,
                    value: ev.target.value,
                    measure: values.customDetails[qIdx].fields[idx]?.measure || defaultMeasure,
                });
            }}
            InputLabelProps={{
                shrink: true,
                sx: { fontSize: '1.5rem', fontWeight: '700' },
            }}
            required={required || false}
            error={Boolean(error)}
            helperText={error}
        />
    );
};

export default QCustomText;
