import { Grid, Typography } from '@mui/material';
import React from 'react';
import SurveyQuestionHeadline from '../../components/SurveyQuestionHeadline';
import {
    withFormikContext,
    withStepAndValidation,
    withSurveySection,
} from '../../components/SurveyStep';
import QCustomForm from './QCustomForm';

function QCustom({ values, setFieldValue, q, qIdx, errors }) {
    return (
        <Grid item xs={12}>
            <SurveyQuestionHeadline>{q.name}</SurveyQuestionHeadline>
            <Typography variant="body1">{q.guideNote}</Typography>
            <Grid item xs={12} sx={{ mt: 2 }}>
                {q.fields.map(({ id, label, type, required, defaultMeasure }, idx) => (
                    <QCustomForm
                        id={id}
                        idx={idx}
                        qIdx={qIdx}
                        label={label}
                        type={type}
                        required={required}
                        defaultMeasure={defaultMeasure}
                        values={values}
                        setFieldValue={setFieldValue}
                        error={errors[`customDetails-${qIdx}-fields-${idx}`]}
                    />
                ))}
            </Grid>
        </Grid>
    );
}

export default withStepAndValidation(
    withSurveySection(withFormikContext(QCustom)),
    7,
    [], // list of fields to validate (empty for now, list in withStepAndValidation)
    (values, errors) => {
        values.customDetails.forEach((question, qIdx) => {
            question.fields.forEach((field, idx) => {
                const isRequiredField = values.customQuestions[qIdx].fields[idx].required;
                if (
                    isRequiredField &&
                    (!field.value || field.value === '' || field.value?.length === 0)
                ) {
                    // eslint-disable-next-line no-param-reassign
                    errors[`customDetails-${qIdx}-fields-${idx}`] =
                        'Required field, please fill it.';
                }
            });
        });
        return errors;
    },
    false, // always active, no need to set that check
    true // custom validation
);
