import { Grid, InputAdornment } from '@mui/material';
import React from 'react';
import SurveyQuestionHeadline from '../../components/SurveyQuestionHeadline';
import {
    withFormikContext,
    withStepAndValidation,
    withSurveySection,
} from '../../components/SurveyStep';
import FloatInput from '../../../../components/atom/FloatInput';

function QReportTotal({ values, setFieldValue, year, companyName }) {
    return (
        <>
            <Grid item xs={12}>
                <SurveyQuestionHeadline>
                    Total CO2e (mt) of products/services sold to {companyName} in {year}
                </SurveyQuestionHeadline>
            </Grid>
            <Grid item xs={12}>
                <FloatInput
                    fullWidth
                    required
                    type="number"
                    label="Total sum CO2e (mt)"
                    value={values?.details?.totalCo2e}
                    onChange={(ev) => {
                        setFieldValue('details.totalCo2e', ev.target.rawValue);
                    }}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">CO2e (mt)</InputAdornment>,
                    }}
                />
            </Grid>
        </>
    );
}

export default withStepAndValidation(
    withSurveySection(withFormikContext(QReportTotal)),
    3,
    ['details.totalCo2e'],
    // no validation
    (values, errors) => {
        const total = parseFloat(values?.details?.totalCo2e);
        if (Number.isNaN(total) || total <= 0) {
            // eslint-disable-next-line no-param-reassign
            errors['details.totalCo2e'] = 'Please enter a value.';
        }
        return errors;
    },
    // always active
    (values) => values?.details?.haveExactData === true
);
