import {
    FormControl,
    Grid,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    Tooltip,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import SurveyQuestionHeadline from '../../components/SurveyQuestionHeadline';
import {
    withFormikContext,
    withStepAndValidation,
    withSurveySection,
} from '../../components/SurveyStep';
import FloatInput from '../../../../components/atom/FloatInput';

const POSSIBLE_FORMULAS = ['AR6', 'AR5', 'AR4', 'SAR'];

function QCalculatorPercentage({ values, setFieldValue, companyName, companyFormula }) {
    // TODO: move the formula thing to the init part of the formik
    const { formula, calculationPercentage } = values.details;
    // to set AR5 as default formula and manage it in the formik
    const [initialFormula, setInitialFormula] = useState(formula || companyFormula || 'AR5');

    useEffect(() => {
        setFieldValue('details.formula', initialFormula);
    }, [initialFormula, setFieldValue]);

    return (
        <>
            <Grid item xs={12}>
                <SurveyQuestionHeadline>
                    Please enter the allocated percentage of your Company&apos;s total emissions.
                </SurveyQuestionHeadline>
            </Grid>
            <Grid
                item
                xs={6}
                style={{
                    display: 'none', // temporary hide the formula select
                }}
            >
                <FormControl fullWidth>
                    <InputLabel id="formula-select">Formula</InputLabel>
                    <Select
                        fullWidth
                        labelId="formula-select"
                        id="demo-simple-select"
                        value={formula || companyFormula}
                        size="small"
                        label="Formula"
                        onChange={(e) => {
                            setFieldValue('details.formula', e.target.value);
                            setInitialFormula(e.target.value);
                        }}
                    >
                        {POSSIBLE_FORMULAS.map((formulaItem) => (
                            <MenuItem key={formulaItem} value={formulaItem}>
                                {formulaItem}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <FloatInput
                    fullWidth
                    required
                    label={
                        <>
                            Percentage of total emissions allocated to {companyName} (%){' '}
                            <Tooltip
                                title={
                                    <div style={{ whiteSpace: 'pre-line' }}>
                                        Percentage of your company&apos;s total revenue that is made
                                        up of revenue from this customer (e.g. this customer makes
                                        up 1% of your total revenue, so 1% of total emissions would
                                        be allocated to them)
                                    </div>
                                }
                                sx={{ cursor: 'pointer' }}
                                placement="right"
                            >
                                <InfoOutlinedIcon />
                            </Tooltip>
                        </>
                    }
                    type="number"
                    value={calculationPercentage}
                    onChange={(ev) => {
                        setFieldValue('details.calculationPercentage', ev.target.rawValue);
                    }}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                    }}
                />
            </Grid>
        </>
    );
}

export default withStepAndValidation(
    withSurveySection(withFormikContext(QCalculatorPercentage)),
    5,
    ['details.calculationPercentage'], // no validation
    (values, errors) => {
        const perc = parseFloat(values?.details?.calculationPercentage);
        if (typeof perc !== 'number' || perc < 0 || perc > 100 || Number.isNaN(perc)) {
            // eslint-disable-next-line no-param-reassign
            errors['details.calculationPercentage'] = 'Percentage should be between 0 - 100 %';
        }
        return errors;
    },
    (values) => {
        // Quanitity based report is filled out fully
        const filledUpScopeBasedReport =
            // if the user does not have scope 3 report, but has hasScope12FullReport, we already asked for a percentage
            (values?.details?.hasScope3Cat1Report === true ||
                values?.details?.hasScope3Cat1Report === false) &&
            (values?.details?.hasScope3Cat5Report === true ||
                values?.details?.hasScope3Cat5Report === false) &&
            values?.details?.hasScope12FullReport === true;
        // Directly associated emission is given
        const haveExactData = values?.details?.haveExactData === true;

        // none of the previous steps are not fully filled out
        const anyOfItIsUndefined = [
            // hasScope3Cat1Report and hasScope3Cat5Report answer only needs to be validated if hasScope12FullReport is set
            values?.details?.hasScope12FullReport === true
                ? values?.details?.hasScope3Cat1Report
                : true,
            values?.details?.hasScope12FullReport === true
                ? values?.details?.hasScope3Cat5Report
                : true,
            values?.details?.hasScope12FullReport,
            values?.details?.haveExactData,
        ].some((e) => typeof e === 'undefined');

        return (
            filledUpScopeBasedReport === false &&
            haveExactData === false &&
            anyOfItIsUndefined === false
        );
    }
);
