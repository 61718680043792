import React, { useEffect, useState } from 'react';
import {
    DialogActions,
    DialogContentText,
    DialogContent,
    DialogTitle,
    Dialog,
    Grid,
    FormControlLabel,
    Checkbox,
    Typography,
    Tab,
    Tabs,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Paper,
    Alert,
} from '@mui/material';
import CommonButton from '../components/atom/CommonButton';
import CustomQuestionsTable from '../components/questioncomponents/CustomQuestionsTable';
import useDebounce from '../hooks/useDebounce';
import { doSwaggerCall } from '../hooks/useApi';
import useNotify from '../hooks/useNotify';

const ContactTable = ({ contactsData }) => {
    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Survey</TableCell>
                        <TableCell>Type</TableCell>
                        <TableCell>Contact Name</TableCell>
                        <TableCell>Email</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {contactsData.map((contactData) =>
                        contactData.contacts.map((contact, idx) => (
                            <TableRow key={idx}>
                                {idx === 0 && (
                                    <TableCell rowSpan={contactData.contacts.length}>
                                        {contactData.name}
                                    </TableCell>
                                )}
                                <TableCell>{idx === 0 ? 'to' : 'cc'}</TableCell>
                                <TableCell>{contact.name}</TableCell>
                                <TableCell>{contact.email}</TableCell>
                            </TableRow>
                        ))
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

const ConfirmationSendSurveyModal = ({
    title,
    contactsData,
    limit,
    page,
    setParams,
    confirm,
    showModal,
    MODAL_TYPE,
}) => {
    const closeAction = () => showModal(MODAL_TYPE.NONE);
    const [selectedCustomQuestions, setSelectedCustomQuestions] = useState([]);
    const [isGhg, setIsGhg] = useState(true);
    const { notifyError } = useNotify();
    const [customQuestions, setCustomQuestions] = useState([]);
    const [questionLoading, setQuestionLoading] = useState(false);
    const [search, setSearch] = useState('');
    const [inputValue, setInputValue] = useState(search);
    const debouncedSearch = useDebounce(inputValue, 500);
    const [tabValue, setTabValue] = useState(0);

    const loadCustomQuestions = async (searchParam) => {
        setQuestionLoading(true);
        try {
            const res = await doSwaggerCall('CustomQuestions', 'getCustomQuestions', {
                limit,
                offset: page * limit,
                search: searchParam,
            });
            setCustomQuestions(res);
            setQuestionLoading(false);
        } catch (error) {
            notifyError(error);
            setQuestionLoading(false);
        }
    };

    useEffect(() => {
        if (debouncedSearch !== search) {
            if (setParams) {
                setParams({ page: 0, limit });
            }
            setSearch(debouncedSearch);
        }
    }, [debouncedSearch, search, setSearch, setParams, limit]);

    useEffect(() => {
        loadCustomQuestions(search);
    }, [page, limit, search]);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    return (
        <Dialog
            open
            onClose={closeAction}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="xl"
            fullWidth
        >
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
            <Tabs
                value={tabValue}
                onChange={handleTabChange}
                centered
                TabIndicatorProps={{
                    style: {
                        // remove underline since position not follows sidebar change
                        display: 'none',
                    },
                }}
            >
                <Tab label="Contact Details" />
                <Tab label="Additional Questions" />
            </Tabs>
            {selectedCustomQuestions.length === 0 && !isGhg && (
                <DialogContent>
                    <DialogContentText>
                        <Alert severity="warning">
                            This survey is empty. Please select at least one additional question or
                            include GHG related questions.
                        </Alert>
                    </DialogContentText>
                </DialogContent>
            )}
            <DialogContent>
                {tabValue === 0 && <ContactTable contactsData={contactsData} />}
                {tabValue === 1 && (
                    <>
                        <Grid item xs={12} sx={{ pt: 1, pb: 3 }}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={isGhg}
                                        onChange={(e) => {
                                            setIsGhg(!!e.target.checked);
                                        }}
                                    />
                                }
                                label="Should GHG related questions be included?"
                                labelPlacement="end"
                            />
                        </Grid>
                        <CustomQuestionsTable
                            customQuestions={customQuestions}
                            selectedCustomQuestions={selectedCustomQuestions}
                            setSelectedCustomQuestions={setSelectedCustomQuestions}
                            limit={limit}
                            page={page}
                            setParams={setParams}
                            loading={questionLoading}
                            inputValue={inputValue}
                            setInputValue={setInputValue}
                        />
                    </>
                )}
            </DialogContent>
            <DialogActions>
                <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ width: '100%' }}
                >
                    <Grid item>
                        <Typography variant="body1">
                            Number of selected additional questions:{' '}
                            <b>{selectedCustomQuestions.length}</b>, GHG related questions are{' '}
                            <b>{isGhg ? 'included' : 'excluded'}</b>.
                        </Typography>
                    </Grid>
                    <Grid item>
                        <CommonButton
                            onClick={closeAction}
                            autoFocus
                            label="Cancel"
                            buttonType="secondary"
                        />
                        <CommonButton
                            onClick={() => {
                                if (tabValue === 0) {
                                    setTabValue(1);
                                } else {
                                    confirm(selectedCustomQuestions, isGhg);
                                    showModal(MODAL_TYPE.NONE);
                                }
                            }}
                            label={tabValue === 0 ? 'Next' : 'Send'}
                            buttonType="primary"
                            sx={{ ml: 1 }}
                            disabled={selectedCustomQuestions.length === 0 && !isGhg} // prevent empty survey
                        />
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmationSendSurveyModal;
