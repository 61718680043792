import React, { useCallback, useState, useMemo } from 'react';
import {
    DialogActions,
    DialogContentText,
    DialogContent,
    DialogTitle,
    Dialog,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    TableContainer,
    Paper,
    IconButton,
    Grid,
    TableFooter,
} from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import SortableTableCell from '../components/atom/SortableTableCell';
import useSorting from '../hooks/useSorting';
import { checkResultForRow, iconByType } from '../helpers/modalHelper';
import CommonButton from '../components/atom/CommonButton';
import ResponsibilityTableCell from '../components/atom/ResponsibilityTableCell';
import ImportResultsHeader from './components/ImportResultsHeader';

function ImportTableRow({ row }) {
    return (
        <TableRow key={row.id}>
            <TableCell align="left">
                <IconButton disabled>{iconByType(row.datalineType)}</IconButton>
                {row.datalineType}
            </TableCell>
            <TableCell align="right">{row.emission_description}</TableCell>
            <TableCell align="right">{row.site_name}</TableCell>
            <TableCell align="right">
                {Object.entries(row.fields).length > 0
                    ? Object.entries(row.fields).map((field) => (
                          <p>
                              {field[0]}: {field[1].displayValue}
                          </p>
                      ))
                    : `${row.amount} ${row.base_unit}`}
            </TableCell>
            <TableCell align="right">{row.notes}</TableCell>
            <TableCell align="right">{row.sop}</TableCell>
            <TableCell align="right">{row.is_estimated === true ? 'x' : ''}</TableCell>
            <TableCell align="right">{row.cost}</TableCell>
            <ResponsibilityTableCell row={row.assignedUser} type="assignedToMe" />
            <ResponsibilityTableCell row={row.ownerUser} type="ownedByMe" />
            <TableCell align="right">
                {row.from_date} - {row.to_date}
            </TableCell>
            <TableCell align="right">
                {row.tags.length > 0
                    ? row.tags.map((tag) => (
                          <p>
                              {tag.name}: {tag.percentage}%
                          </p>
                      ))
                    : ''}
            </TableCell>
            <TableCell align="left">{checkResultForRow(row)}</TableCell>
        </TableRow>
    );
}

const ConfirmationImportDatalinesModal = ({
    title,
    content,
    data,
    confirm,
    close = false,
    showModal,
    setImportingXlsx,
    MODAL_TYPE,
}) => {
    const closeAction = useCallback(
        close ||
            (() => {
                showModal(MODAL_TYPE.NONE);
                setImportingXlsx(false);
            }),
        [close]
    );
    const [showCorrectItems, setShowCorrectItems] = useState(false);
    // prevent stack overflow
    const { allData, correctItemsLength } = useMemo(() => {
        const allData = [].concat(data.toCreate, data.toUpdate, data.toDelete);
        const correctItemsLength = allData.filter(
            (row) => !row.error && !row.markedForDeletion
        ).length;
        return { allData, correctItemsLength };
    }, [data]);
    const { sort, sortDir, setSorting } = useSorting(['result', 'ASC']);
    const [page, setPage] = useState(0);

    const sortedData = useMemo(() => {
        return allData
            .filter((row) => showCorrectItems || row.error)
            .sort(
                sortDir === 'DESC'
                    ? (a, b) => (a.error < b.error) - (a.error > b.error)
                    : (a, b) => (a.error > b.error) - (a.error < b.error)
            )
            .splice(page * 1000, 1000);
    }, [sortDir, allData, showCorrectItems, page]);

    return (
        <Dialog
            open
            onClose={closeAction}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="xl"
            fullWidth
        >
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">{content}</DialogContentText>
                <ImportResultsHeader
                    correctItemsLength={correctItemsLength}
                    showCorrectItems={showCorrectItems}
                    setShowCorrectItems={setShowCorrectItems}
                    sumTitle="Sum of correct rows"
                />
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell align="left">Type</TableCell>
                                <TableCell align="right">Description</TableCell>
                                <TableCell align="right">Site</TableCell>
                                <TableCell align="right">Fields / amount and base unit</TableCell>
                                <TableCell align="right">Notes</TableCell>
                                <TableCell align="right">SOP</TableCell>
                                <TableCell align="right">Estimated</TableCell>
                                <TableCell align="right">Cost</TableCell>
                                <TableCell>Assignee</TableCell>
                                <TableCell>Owner</TableCell>
                                <TableCell align="right">Dates</TableCell>
                                <TableCell align="right">Tags</TableCell>
                                <SortableTableCell
                                    arrow={sort === 'result' ? sortDir : ''}
                                    onClick={() => {
                                        setSorting('result');
                                        setPage(0);
                                    }}
                                >
                                    Result
                                </SortableTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {showCorrectItems
                                ? sortedData.map((row) => <ImportTableRow row={row} />)
                                : sortedData
                                      .filter((row) => row.error)
                                      .map((row) => <ImportTableRow row={row} />)}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TableCell colSpan={12}>
                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="flex-end"
                                        alignItems="center"
                                    >
                                        {`Showing ${page * 1000 + 1} - ${Math.min(
                                            page * 1000 + 1000,
                                            allData.length
                                        )} of ${allData.length}`}
                                        <IconButton
                                            onClick={() => setPage(page - 1)}
                                            disabled={page === 0}
                                        >
                                            <NavigateBeforeIcon />
                                        </IconButton>
                                        <IconButton
                                            onClick={() => setPage(page + 1)}
                                            disabled={page * 1000 + 1000 >= allData.length}
                                        >
                                            <NavigateNextIcon />
                                        </IconButton>
                                    </Grid>
                                </TableCell>
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            </DialogContent>
            <DialogActions>
                <CommonButton
                    onClick={closeAction}
                    autoFocus
                    label="Cancel"
                    buttonType="secondary"
                />
                <CommonButton
                    onClick={() => {
                        confirm();
                        showModal(MODAL_TYPE.NONE);
                    }}
                    label="Yes"
                    buttonType="primary"
                />
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmationImportDatalinesModal;
