import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useNavigate } from 'react-router-dom';
import { useApi } from '../../hooks/useApi';
import useNotify from '../../hooks/useNotify';
import SearchInput from '../../components/atom/SearchInput';
import useSorting from '../../hooks/useSorting';
import { TABLE_ROW_COUNT_DEFAULT } from '../../helpers/constans';
import QuestionsTable from '../../components/questioncomponents/QuestionsTable';
import { MODAL_TYPE, useModals } from '../../hooks/useModal';
import PageWithHeader from '../../components/header/PageWithHeader';
import ButtonWithIcon from '../../components/header/ButtonWithIcon';

const CustomQuestionsPage = () => {
    const { notifyError } = useNotify();
    const [search, setSearch] = useState('');
    const { sort, sortDir, setSorting } = useSorting(['created_at', 'ASC']);
    const [{ limit, page }, setParams] = useState({ limit: TABLE_ROW_COUNT_DEFAULT, page: 0 });
    const navigate = useNavigate();
    const { showModal } = useModals();

    const [data, loading, error, setData, reloadData] = useApi(
        'CustomQuestions',
        'getCustomQuestions',
        {
            search,
            limit,
            offset: page * limit,
            sort,
            sortDir,
        }
    );

    useEffect(() => {
        if (error) {
            notifyError(error);
        }
    }, [error, notifyError]);

    if (error) {
        return null;
    }

    return (
        <PageWithHeader
            title="Custom Questions"
            searchInput={
                <SearchInput
                    fullWidth
                    search={search}
                    setSearch={setSearch}
                    setParams={setParams}
                    limit={limit}
                />
            }
            buttons={[
                <ButtonWithIcon
                    title="Add Custom Question"
                    onClick={() => navigate('/customquestions/new')}
                    icon={<AddIcon />}
                />,
                <ButtonWithIcon
                    title="Copy From ESG Questions"
                    onClick={() => {
                        showModal(MODAL_TYPE.COPY_ESG_QUESTIONS, {
                            title: 'Copy ESG Questions',
                            content: 'Select ESG questions to copy into Custom Survey Questions.',
                            reloadData,
                        });
                    }}
                    icon={<ContentCopyIcon />}
                />,
            ]}
        >
            <Grid item xs={12}>
                <QuestionsTable
                    rows={data?.customQuestions}
                    limit={limit}
                    page={page}
                    setParams={setParams}
                    total={data?.total}
                    loading={loading}
                    reloadData={reloadData}
                    sort={sort}
                    sortDir={sortDir}
                    setSorting={setSorting}
                    type="custom"
                />
            </Grid>
        </PageWithHeader>
    );
};

export default CustomQuestionsPage;
