import React, { useEffect, useState } from 'react';
import { Grid, LinearProgress, Typography } from '@mui/material';
import useNotify from '../../../hooks/useNotify';
import { doSwaggerCall } from '../../../hooks/useApi';
import EsgPieChart from './charts/EsgPieChart';

const ChartWrapper = ({
    esgQuestionKeys,
    pieData,
    esgQuestionFieldMeasures,
    esgQuestionName,
    year,
}) => {
    return esgQuestionKeys.map((key, index) => {
        return (
            <EsgPieChart
                data={pieData.filter((item) => item.label === key)}
                fieldName={key}
                measure={esgQuestionFieldMeasures[index]}
                esgQuestionName={esgQuestionName}
                year={year}
            />
        );
    });
};

const BySelectedEsgPieQuestion = ({ esgQuestion, fields, year, setChartData }) => {
    const { notifyError } = useNotify();
    const [pieData, setPieData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [esgQuestionKeys, setEsgQuestionKeys] = useState([]);
    const [esgQuestionFieldMeasures, setEsgQuestionFieldMeasures] = useState([]);
    const [esgQuestionName, setEsgQuestionName] = useState('');

    useEffect(() => {
        doSwaggerCall(
            'Dashboards',
            'getDataByEsgQuestionForPieChart',
            {
                esgQuestion,
                year,
            },
            { fields }
        )
            .then((res) => {
                setEsgQuestionName(res.esgQuestionName);
                setPieData(
                    res.esgData?.reduce((acc, curr) => {
                        // Find existing entry by site (id) and label
                        let existing = acc.find(
                            (item) => item.id === curr.id && item.label === curr.label
                        );

                        // If not found, create a new entry
                        if (!existing) {
                            existing = {
                                id: curr.id,
                                label: curr.label,
                            };
                            acc.push(existing);
                        }

                        // Add data to the existing or new entry
                        Object.keys(curr).forEach((key) => {
                            existing[key] = curr[key];
                        });

                        return acc;
                    }, [])
                );
                setEsgQuestionKeys(res.esgQuestionFields.map((field) => field.label));
                setEsgQuestionFieldMeasures(
                    res.esgQuestionFields.map((field) => field.defaultMeasure)
                );
                setChartData(res.esgData);
                setLoading(false);
            })
            .catch((error) => {
                notifyError(error);
            });
    }, [
        setLoading,
        setPieData,
        setEsgQuestionKeys,
        setEsgQuestionFieldMeasures,
        esgQuestion,
        year,
        JSON.stringify(fields),
        setChartData,
    ]);

    return (
        <Grid item xs={12}>
            {pieData.length === 0 ? (
                <Grid item>
                    {loading ? (
                        <LinearProgress sx={{ mt: 0.25 }} />
                    ) : (
                        <Typography>No data available</Typography>
                    )}
                </Grid>
            ) : (
                <Grid container>
                    <ChartWrapper
                        esgQuestionKeys={esgQuestionKeys}
                        pieData={pieData}
                        esgQuestionFieldMeasures={esgQuestionFieldMeasures}
                        esgQuestionName={esgQuestionName}
                        year={year}
                    />
                </Grid>
            )}
        </Grid>
    );
};

export default BySelectedEsgPieQuestion;
