import { Grid, TextField, Tooltip } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import WarningIcon from '@mui/icons-material/Warning';
import BlockWithTitle from '../atom/BlockWithTitle';
import SkeletonForm from '../atom/SkeletonForm';
import AutocompleteSelect from '../atom/AutoCompleteSelect';
import { esgDisclosureAutocomplete } from '../../autocomplete';
import EsgQuestionFieldEditor from '../../pages/esgquestionedit/components/EsgQuestionFieldEditor';
import CommonButton from '../atom/CommonButton';
import CustomQuestionFieldEditor from '../../pages/customquestionsedit/components/CustomQuestionFieldEditor';

function groupSubcategoryAndCategory(item) {
    return `${item?.category_name} - ${item?.subcategory_name}`;
}

const QuestionForm = ({
    loading,
    values,
    setFieldValue,
    touched,
    isSubmitting,
    errors,
    type = 'esg',
    isNew = false,
}) => {
    const navigate = useNavigate();

    return (
        <BlockWithTitle
            title={`${isNew ? 'Add' : 'Edit'} ${type === 'esg' ? 'ESG' : 'Custom'} Question`}
        >
            <Grid item xs={12}>
                <Grid container>
                    {loading ? (
                        <SkeletonForm rows={6} />
                    ) : (
                        <>
                            <Grid item xs={12}>
                                <TextField
                                    label="Name"
                                    autoComplete="off"
                                    fullWidth
                                    value={values?.name}
                                    onChange={(ev) => setFieldValue('name', ev.target.value)}
                                    error={touched.name && Boolean(errors.name)}
                                    helperText={touched.name ? errors.name : ''}
                                    InputProps={{
                                        // only esg questions need a disclosure
                                        startAdornment: type === 'esg' &&
                                            values.esg_disclosure_id === null && (
                                                <Tooltip title="This question is not connected to any disclosure">
                                                    <WarningIcon sx={{ mr: 0.5, color: 'red' }} />
                                                </Tooltip>
                                            ),
                                    }}
                                />
                            </Grid>
                            {type === 'esg' && (
                                <Grid item xs={12}>
                                    <AutocompleteSelect
                                        grouping={groupSubcategoryAndCategory}
                                        initCall={esgDisclosureAutocomplete.init}
                                        autocompleteCall={esgDisclosureAutocomplete.search}
                                        value={values?.esg_disclosure_id}
                                        // not required in case of edit
                                        setValue={(ev) => setFieldValue('esg_disclosure_id', ev)}
                                    >
                                        <TextField label="Disclosure" />
                                    </AutocompleteSelect>
                                </Grid>
                            )}
                            {type === 'custom' && (
                                <Grid item xs={12}>
                                    <TextField
                                        label="Rank"
                                        fullWidth
                                        type="number"
                                        value={values?.rank}
                                        onChange={(ev) => setFieldValue('rank', ev.target.value)}
                                        error={touched.rank && Boolean(errors.rank)}
                                        helperText={touched.rank ? errors.rank : ''}
                                    />
                                </Grid>
                            )}
                            <Grid item xs={12}>
                                {type === 'esg' ? (
                                    <EsgQuestionFieldEditor
                                        values={values}
                                        setFieldValue={setFieldValue}
                                    />
                                ) : (
                                    <CustomQuestionFieldEditor
                                        values={values}
                                        setFieldValue={setFieldValue}
                                    />
                                )}
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="Note for guidance (optional)"
                                    fullWidth
                                    multiline
                                    value={values?.guide_note}
                                    onChange={(ev) => setFieldValue('guide_note', ev.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <CommonButton
                                    fullWidth
                                    onClick={() => navigate(`/${type}questions`)}
                                    label="Back"
                                    buttonType="secondary"
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <CommonButton
                                    type="submit"
                                    disabled={isSubmitting}
                                    fullWidth
                                    label="Save"
                                    buttonType="primary"
                                />
                            </Grid>
                        </>
                    )}
                </Grid>
            </Grid>
        </BlockWithTitle>
    );
};

export default QuestionForm;
