import {
    Checkbox,
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
} from '@mui/material';
import React from 'react';
import SkeletonTable from '../atom/SkeletonTable';
import { handleChangeRowsPerPage, handlePageChange } from '../../helpers/handleTableFunctions';
import { TABLE_ROW_COUNT_OPTIONS } from '../../helpers/constans';
import SearchInput from '../atom/SearchInput';

const CustomQuestionsTable = ({
    customQuestions,
    selectedCustomQuestions,
    setSelectedCustomQuestions,
    limit,
    page,
    setParams,
    loading,
    inputValue,
    setInputValue,
}) => {
    return (
        <Grid item xs={12}>
            <TableContainer component={Paper}>
                <Grid item>
                    <SearchInput
                        search={inputValue}
                        setSearch={setInputValue}
                        setParams={setParams}
                        limit={limit}
                        fullWidth
                    />
                </Grid>
                <div style={{ maxHeight: '450px', overflowY: 'auto', marginTop: '4px' }}>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell width="5%" />
                                <TableCell width="45%">Name</TableCell>
                                <TableCell width="50%">Fields</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {loading ? (
                                <SkeletonTable rows={limit} cells={3} />
                            ) : (
                                customQuestions?.customQuestions?.map((customQuestion) => (
                                    <TableRow key={customQuestion.id}>
                                        <TableCell>
                                            <Checkbox
                                                onChange={(e) => {
                                                    if (e.target.checked) {
                                                        setSelectedCustomQuestions([
                                                            ...selectedCustomQuestions,
                                                            customQuestion,
                                                        ]);
                                                    } else {
                                                        setSelectedCustomQuestions(
                                                            selectedCustomQuestions.filter(
                                                                (q) => q.id !== customQuestion.id
                                                            )
                                                        );
                                                    }
                                                }}
                                                checked={selectedCustomQuestions.some(
                                                    (q) => q.id === customQuestion.id
                                                )}
                                            />
                                        </TableCell>
                                        <TableCell>{customQuestion.name}</TableCell>
                                        <TableCell>
                                            {customQuestion.fields?.map((field) => (
                                                <p>{field?.label}</p>
                                            ))}
                                        </TableCell>
                                    </TableRow>
                                ))
                            )}
                        </TableBody>
                    </Table>
                    <TablePagination
                        count={customQuestions?.total || 0}
                        rowsPerPage={limit}
                        component="div"
                        page={page}
                        onPageChange={(_, newPage) => handlePageChange(newPage, setParams, limit)}
                        rowsPerPageOptions={TABLE_ROW_COUNT_OPTIONS}
                        onRowsPerPageChange={(event) => handleChangeRowsPerPage(event, setParams)}
                    />
                </div>
            </TableContainer>
        </Grid>
    );
};

export default CustomQuestionsTable;
