import React from 'react';
import { Container, Grid } from '@mui/material';
import { useLegends } from '../../../hooks/useLegends';
import legendAlign from './charts/legendAlignHelper';

const LineLegendDisplay = () => {
    const { lineLegends } = useLegends();

    return lineLegends
        ?.filter((e) => !e.goal)
        .map((e) => {
            return (
                <React.Fragment key={e.id}>
                    <Container
                        maxWidth="xl"
                        sx={{
                            display: 'inline-block',
                            whiteSpace: 'nowrap',
                            ...legendAlign(lineLegends).center.container,
                        }}
                    >
                        <svg
                            style={{
                                backgroundColor: e.color,
                                width: '10px',
                                height: '10px',
                                marginRight: '5px',
                                ...legendAlign(lineLegends).center.label,
                            }}
                        />
                        <Grid display="inline" item xs={12}>
                            {e.id}
                        </Grid>
                    </Container>
                </React.Fragment>
            );
        });
};

export default LineLegendDisplay;
