import React, { useEffect, useState } from 'react';
import { ButtonGroup, CircularProgress, Grid, IconButton, Typography } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import AddIcon from '@mui/icons-material/Add';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { useNavigate } from 'react-router-dom';
import QuizOutlinedIcon from '@mui/icons-material/QuizOutlined';
import FeedOutlinedIcon from '@mui/icons-material/FeedOutlined';
import useSorting from '../../../hooks/useSorting';
import { doSwaggerCall, doSwaggerCallForPdf, useApi } from '../../../hooks/useApi';
import { useGlobalFilters } from '../../../hooks/useGlobalFilters';
import useNotify from '../../../hooks/useNotify';
import SuppliersTable from './SuppliersTable';
import { parseXlsxSheets, runImport } from '../../../helpers/excelImportSuppliersHelpers';
import { MODAL_TYPE, useModals } from '../../../hooks/useModal';
import { exportToXlsx, getData } from '../../../helpers/excelExportHelpers';
import {
    EXPORT_SHEET_TYPES,
    FIELD_TYPES,
    ROLES,
    TABLE_ROW_COUNT_DEFAULT,
} from '../../../helpers/constans';
import InputButton from '../../../components/atom/InputButton';
import ButtonWithIcon from '../../../components/header/ButtonWithIcon';
import { useAuth } from '../../../hooks/useAuth';
import { BACKEND_URL } from '../../../config';
import filterSuppliersForReport from './helpers/filterSuppliersForReport';
import { convertUnitGToKg } from '../../../helpers/convertUnits';

// transform per scope data to object
const transformPerScope = (scopeData) => {
    const transformedFields = {};
    scopeData.forEach((target) => {
        const { scopeId, co2e } = target;
        transformedFields[`Scope ${scopeId}`] = convertUnitGToKg(co2e) / 1000;
    });
    return transformedFields;
};

// transform custom questions
const transformCustomDetails = (customDetails, allSupplierCustomQuestions) => {
    const transformedFields = {};
    // iterate over all custom questions and find the corresponding question
    allSupplierCustomQuestions.forEach((customQuestion) => {
        const question = customDetails.details.find((q) => q.id === customQuestion.id);
        // if the question is found, transform the fields
        if (question) {
            const value = question.fields
                .map((field) => {
                    const customField = customQuestion.fields.find((f) => f.id === field.id);
                    // file field value is an array of files
                    let fieldValue = field?.value || [];
                    if (customField.type === FIELD_TYPES.FILE) {
                        fieldValue = fieldValue.map((file) => file.name).join(', ');
                    }
                    // display number fields with measure if it has a measure
                    if (
                        customField.type === FIELD_TYPES.NUMBER &&
                        field.measure !== 'Without unit' &&
                        fieldValue !== ''
                    ) {
                        fieldValue = `${field.value} ${field.measure}`;
                    }
                    return `${customField.label}: ${fieldValue}`;
                })
                .join('\n');
            transformedFields[customQuestion.name] = value;
        } else {
            // if the question is not found, set the value to empty string
            transformedFields[customQuestion.name] = '';
        }
    });
    return transformedFields;
};

export default function SupplierSection({
    search,
    sectionHeader,
    groupOpen,
    item,
    open,
    setOpen,
    groupby,
    setSuppliersChanged,
}) {
    const { notifyError, notifySuccess } = useNotify();
    const { year } = useGlobalFilters();
    const navigate = useNavigate();
    const [selectMode, setSelectMode] = useState(false);
    const [{ limit, page }, setParams] = useState({ limit: TABLE_ROW_COUNT_DEFAULT, page: 0 });
    const { sort, sortDir, setSorting } = useSorting(['created_at', 'ASC']);
    const [importingXlsx, setImportingXlsx] = useState(false);
    const [generatingXlsx, setGeneratingXlsx] = useState(false);
    const { showModal } = useModals();
    const { user } = useAuth();
    const [generatingPdf, setGeneratingPdf] = useState(false);

    useEffect(() => {
        setParams({ page: 0, limit });
    }, [year, search]);

    let getSuppliersParams = {
        search,
        limit,
        offset: page * limit,
        sort,
        sortDir,
        year,
    };
    if (groupby === 'INDUSTRY') {
        getSuppliersParams = {
            ...getSuppliersParams,
            industry: item.industry,
        };
    }
    if (groupby === 'LOCATION') {
        getSuppliersParams = {
            ...getSuppliersParams,
            locationId: item.locationId,
        };
    }

    const [data, loading, error, setData, reloadData] = useApi('Suppliers', 'getSuppliers', {
        ...getSuppliersParams,
    });

    const sampleData = [
        {
            id: '',
            name: 'Example Name',
            industry: 'Example Industry',
            location: 'Budapest',
            scope: '',
            category: '',
            tags: '',
            marked_for_deletion: '',
            contact_name1: 'Example Name',
            contact_email1: 'example@gmail.com',
            contact_phone1: '123456789',
            contact_name2: 'Example Name2',
            contact_email2: 'example2@gmail.com',
            contact_phone2: '',
            contact_name3: 'Example Name3',
            contact_email3: 'example3@gmail.com',
            contact_phone3: '',
        },
    ];

    const getContactsForExport = (suppliers) => {
        const resultSupplier = [];
        suppliers.forEach((supplier) => {
            let resultContacts = {};
            supplier.contacts.forEach((contact, idx) => {
                resultContacts = {
                    ...resultContacts,
                    [`contact_name${idx + 1}`]: contact.name,
                    [`contact_email${idx + 1}`]: contact.email,
                    [`contact_phone${idx + 1}`]: contact.phone,
                };
            });

            // need to store contacts in one object
            resultSupplier.push({
                id: supplier.id,
                name: supplier.name,
                industry: supplier.industry,
                location: supplier.location,
                scope: supplier.scope_name,
                category: supplier.category_name,
                tags: supplier.tags,
                marked_for_deletion: supplier.marked_for_deletion,
                ...resultContacts,
            });
        });
        return resultSupplier;
    };

    const handleImport = (e) => {
        try {
            return parseXlsxSheets(e.target.files[0], (parsedData) => {
                runImport(parsedData, true, year).then((dryRunResult) => {
                    const dryRunFlat = Object.entries(dryRunResult).reduce(
                        (sum, item) => [...sum, ...item[1]],
                        []
                    );
                    showModal(MODAL_TYPE.CONFIRMATION_IMPORT_SUPPLIERS, {
                        title: `Are you sure you want to import this xlsx?`,
                        content: 'This action is irreversible.',
                        data: dryRunFlat,
                        setImportingXlsx,
                        confirm: async () => {
                            await runImport(parsedData, false, year);
                            notifySuccess('Import successful');
                            setImportingXlsx(false);
                            reloadData();
                        },
                    });
                });
            });
        } catch (err) {
            setImportingXlsx(false);
            notifyError(err);
        }
    };

    // Helper function to get all locations for export
    const getAllLocationsForExport = async () => {
        const locations = await doSwaggerCall('Locations', 'getAllLocations', {
            pageSize: 10000,
            page: 0,
        });
        return locations.locations.map((l) => ({ name: l.name }));
    };
    // Helper function to get all supplier tags for export
    const getAllSupplierTagsForExport = async () => {
        const supplierTags = await doSwaggerCall('SupplierTags', 'getAllSupplierTags', {
            pageSize: 10000,
            page: 0,
        });
        return supplierTags.supplierTags
            .map((s) => ({ name: s.name }))
            .sort((a, b) => a.name.localeCompare(b.name));
    };

    useEffect(() => {
        if (error) {
            notifyError(error);
        }
    }, [error, notifyError]);

    const supplierExport = async (auditReportFormat, selectedSupplierTag) => {
        const suppliersResult = await filterSuppliersForReport(
            data?.suppliers,
            selectedSupplierTag
        );

        if (suppliersResult.length === 0) {
            notifyError('There are no closed surveys to export');
            return;
        }

        try {
            const reportRequest = await doSwaggerCall('Reports', 'supplierReportRequest', {
                supplierIds: suppliersResult.map((s) => s.id),
            });
            let url = `${BACKEND_URL}/1.0/reports/supplierdownload?token=${reportRequest.token}`;
            if (auditReportFormat) {
                url += `&auditReportFormat=${auditReportFormat}`;
            }
            window.open(url, '_blank', 'noopener,noreferrer');
        } catch (err) {
            notifyError(err);
        }
    };

    const pdfExport = async (auditReportFormat, selectedSupplierTag) => {
        const suppliersResult = await filterSuppliersForReport(
            data?.suppliers,
            selectedSupplierTag
        );

        if (suppliersResult.length === 0) {
            notifyError('There are no closed surveys to export');
            return;
        }

        setGeneratingPdf(true);

        let reportRequest;
        const params = {
            supplierIds: suppliersResult.map((s) => s.id),
        };
        if (auditReportFormat) {
            params.auditReportFormat = auditReportFormat;
        }
        try {
            reportRequest = await doSwaggerCallForPdf('Reports', 'supplierReportRequestPdf', {
                ...params,
            });
            const link = document.createElement('a');
            link.href = URL.createObjectURL(reportRequest);
            link.download = `suppliers.pdf`;
            link.click();
        } catch (err) {
            notifyError(err);
            setGeneratingPdf(false);
        } finally {
            setGeneratingPdf(false);
        }
    };

    const xlsExport = async (selectedSupplierTag, sheetType) => {
        const suppliersResult = await filterSuppliersForReport(
            data?.suppliers,
            selectedSupplierTag
        );

        if (suppliersResult.length === 0) {
            notifyError('There are no closed surveys to export');
            return;
        }

        try {
            const reportRequest = await doSwaggerCall('Reports', 'supplierReportRequest', {
                supplierIds: suppliersResult.map((s) => s.id),
            });
            const suppliers = await doSwaggerCall('Reports', 'getXlsxSuppliersReport', {
                token: reportRequest.token,
            });
            const listToExport = [];
            const allSupplierCustomQuestions = [
                ...new Set(suppliers.flatMap((supplier) => supplier.custom_questions)),
            ];
            suppliers.forEach((supplier) => {
                listToExport.push({
                    id: supplier.id,
                    name: supplier.name,
                    year: supplier.year,
                    co2e: convertUnitGToKg(supplier.co2e) / 1000,
                    netzero: !!supplier.netzero,
                    netzero_targets: supplier.netzero
                        ? supplier.netzero_targets
                              .map(
                                  (target) =>
                                      `${target.scope}: ${target.year ? `${target.year}` : '-'}`
                              )
                              .join('\n')
                        : null,
                    ...transformPerScope(supplier.per_scope),
                    ...transformCustomDetails(supplier.custom_details, allSupplierCustomQuestions),
                });
            });
            exportToXlsx(listToExport, 'suppliers', sheetType || EXPORT_SHEET_TYPES.SUPPLIERS);
        } catch (err) {
            notifyError(err);
        }
    };

    // different confirm function for report by reportType
    const confirmFunction = (
        reportType,
        auditReportFormat,
        selectedSupplierTag,
        sheetType = null
    ) => {
        switch (reportType) {
            case 'HTML':
                return supplierExport(auditReportFormat, selectedSupplierTag).catch((err) => {
                    notifyError(err);
                });
            case 'PDF':
                return pdfExport(auditReportFormat, selectedSupplierTag).catch((err) => {
                    notifyError(err);
                });
            case 'XLSX':
                return xlsExport(selectedSupplierTag, sheetType).catch((err) => {
                    notifyError(err);
                });
            default:
                return () => {
                    notifyError('Something went wrong');
                };
        }
    };

    return (
        <>
            <Grid
                container
                item
                xs={12}
                justifyContent="space-between"
                spacing={0}
                sx={{ mt: groupby === 'NONE' ? 1 : 0 }}
            >
                <Grid item>
                    <Typography variant="h4">
                        {sectionHeader}
                        {(groupby === 'INDUSTRY' && item.id !== 0) || groupby === 'LOCATION' ? (
                            <IconButton
                                onClick={() => {
                                    groupOpen.filter((idx) => idx !== item.id);
                                    setOpen(!open);
                                }}
                            >
                                <KeyboardArrowUpIcon />
                            </IconButton>
                        ) : null}
                    </Typography>
                </Grid>
                {groupby === 'NONE' ? (
                    <Grid item>
                        <Grid
                            item
                            container
                            direction="row"
                            justifyContent="flex-end"
                            alignItems="center"
                        >
                            <Grid item>
                                <ButtonGroup color="primary">
                                    <ButtonWithIcon
                                        title="Add supplier"
                                        onClick={() => navigate('/suppliers/new')}
                                        icon={<AddIcon />}
                                    />
                                    {user.role === ROLES.SUPERADMIN ? (
                                        <ButtonWithIcon
                                            title="Custom Question Editor"
                                            onClick={() => navigate('/customquestions')}
                                            icon={<QuizOutlinedIcon />}
                                        />
                                    ) : null}
                                    <ButtonWithIcon
                                        title="Download report"
                                        onClick={() => {
                                            showModal(MODAL_TYPE.SELECT_SUPPLIER_REPORT, {
                                                confirm: (
                                                    reportType,
                                                    auditReportFormat,
                                                    selectedSupplierTagId
                                                ) => {
                                                    confirmFunction(
                                                        reportType,
                                                        auditReportFormat,
                                                        selectedSupplierTagId,
                                                        EXPORT_SHEET_TYPES.SUPPLIER_REPORT
                                                    ).catch((err) => {
                                                        notifyError(err);
                                                    });
                                                },
                                                multipleSupplier: true,
                                            });
                                        }}
                                        disabled={generatingPdf}
                                        icon={
                                            generatingPdf ? (
                                                <CircularProgress color="inherit" size={20} />
                                            ) : (
                                                <FeedOutlinedIcon />
                                            )
                                        }
                                    />
                                    <ButtonWithIcon
                                        title="Export sample xlsx"
                                        onClick={async () => {
                                            try {
                                                const allLocations =
                                                    await getAllLocationsForExport();
                                                const allSupplierTags =
                                                    await getAllSupplierTagsForExport();
                                                exportToXlsx(
                                                    sampleData,
                                                    'samplesupplierslist',
                                                    EXPORT_SHEET_TYPES.SUPPLIERS,
                                                    allLocations,
                                                    allSupplierTags
                                                );
                                            } catch (err) {
                                                notifyError(err);
                                            }
                                            notifySuccess('Sample suppliers list exported');
                                        }}
                                        icon={<FileDownloadIcon />}
                                    />
                                    <ButtonWithIcon
                                        title="Export to xlsx"
                                        onClick={async () => {
                                            setGeneratingXlsx(true);
                                            try {
                                                const allLocations =
                                                    await getAllLocationsForExport();
                                                const allSupplierTags =
                                                    await getAllSupplierTagsForExport();
                                                const listToExport = (
                                                    await getData(
                                                        EXPORT_SHEET_TYPES.SUPPLIERS,
                                                        year
                                                    )
                                                ).map((s) => ({
                                                    id: s.id,
                                                    name: s.name,
                                                    industry: s.industry,
                                                    location: s.locationName,
                                                    marked_for_deletion: '',
                                                    contacts: s.contacts,
                                                    category_name: s.categoryName,
                                                    scope_name: s.scopeName,
                                                    tags: s.supplierTags
                                                        ?.map((tag) => tag.name)
                                                        .join('\n'),
                                                }));
                                                const listToExportWithContacts =
                                                    getContactsForExport(listToExport);
                                                exportToXlsx(
                                                    listToExportWithContacts,
                                                    'Suppliers',
                                                    EXPORT_SHEET_TYPES.SUPPLIERS,
                                                    allLocations,
                                                    allSupplierTags
                                                );
                                            } catch (err) {
                                                notifyError(err);
                                            }
                                            setGeneratingXlsx(false);
                                            notifySuccess('Suppliers exported');
                                        }}
                                        icon={
                                            generatingXlsx ? (
                                                <CircularProgress color="inherit" size={20} />
                                            ) : (
                                                <FileDownloadIcon />
                                            )
                                        }
                                    />
                                    <InputButton
                                        fullWidth
                                        id="supplier-list-upload"
                                        onChange={(e) => {
                                            if (e.target.files.length === 0) {
                                                return;
                                            }
                                            setImportingXlsx(true);
                                            handleImport(e).catch((err) => {
                                                notifyError(err);
                                            });
                                            e.target.value = '';
                                        }}
                                        accept=".csv, .xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                        title="Import from xlsx"
                                        type="file"
                                        loading={importingXlsx}
                                    />
                                </ButtonGroup>
                            </Grid>
                        </Grid>
                    </Grid>
                ) : null}
            </Grid>
            <SuppliersTable
                rows={data?.suppliers}
                limit={limit}
                page={page}
                setParams={setParams}
                total={data?.total}
                loading={loading}
                selectMode={selectMode}
                setSelectMode={setSelectMode}
                reloadData={reloadData}
                sort={sort}
                sortDir={sortDir}
                setSorting={setSorting}
                groupby={groupby}
                year={year}
                setSuppliersChanged={setSuppliersChanged}
            />
        </>
    );
}
