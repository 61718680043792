// transform esg question fields
export default function transformEsgQuestionFields(fields) {
    const counters = { numeric: 0, text: 0, select: 0 };
    const transformedFields = {};

    fields.forEach((field) => {
        const { type, label, defaultMeasure } = field;
        if (type === 'number') {
            const index = counters.numeric + 1;
            transformedFields[`numeric_field_label${index}`] = label;
            if (defaultMeasure) {
                transformedFields[`default_measure_numeric_value${index}`] = defaultMeasure;
            }
            counters.numeric++;
        } else if (type === 'text') {
            const index = counters.text + 1;
            transformedFields[`text_field_label${index}`] = label;
            counters.text++;
        } else if (type === 'select') {
            const index = counters.select + 1;
            transformedFields[`select_field_label${index}`] = label;
            counters.select++;
        }
    });

    for (let i = counters.numeric + 1; i <= 6; i++) {
        transformedFields[`numeric_field_label${i}`] = '';
        transformedFields[`default_measure_numeric_value${i}`] = '';
    }

    for (let i = counters.text + 1; i <= 6; i++) {
        transformedFields[`text_field_label${i}`] = '';
    }

    for (let i = counters.select + 1; i <= 8; i++) {
        transformedFields[`select_field_label${i}`] = '';
    }

    const orderedFields = {};

    // add numeric fields first
    for (let i = 1; i <= 6; i++) {
        orderedFields[`numeric_field_label${i}`] =
            transformedFields[`numeric_field_label${i}`] || '';
        orderedFields[`default_measure_numeric_value${i}`] =
            transformedFields[`default_measure_numeric_value${i}`] || '';
    }

    // add text fields
    for (let i = 1; i <= 6; i++) {
        orderedFields[`text_field_label${i}`] = transformedFields[`text_field_label${i}`] || '';
    }

    // add select fields
    for (let i = 1; i <= 8; i++) {
        orderedFields[`select_field_label${i}`] = transformedFields[`select_field_label${i}`] || '';
    }

    return orderedFields;
}
