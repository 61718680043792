/**
 * Convert field to lines
 * @param input input
 */
export function convertFieldToLines(input) {
    const fieldLines = [];
    Object.entries(input).forEach(([key, value]) => {
        fieldLines.push({
            id: `${Math.random()}`.substring(2),
            name: key,
            field: value,
        });
    });
    return fieldLines;
}

/**
 * Convert lines to field
 * @param newLines newLines
 */
export function convertLinesToField(newLines) {
    const fieldValue = {};
    for (let i = 0; i < newLines.length; i++) {
        fieldValue[newLines[i].name] = newLines[i].field;
    }
    return fieldValue;
}

/**
 * Array of available formula options.
 * @type {string[]}
 */
export const FORMULA_OPTIONS = ['AR6', 'AR5', 'AR4', 'SAR'];
