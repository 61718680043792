import { Tooltip } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import React, { useEffect, useState } from 'react';
import { useDarkMode } from '../../hooks/useDarkMode';
import { useGuideBoxSidebar } from '../../hooks/useGuideBoxSidebar';
import { doSwaggerCall } from '../../hooks/useApi';
import useNotify from '../../hooks/useNotify';
import { useEsgFrameworkFilters } from '../../hooks/useEsgFrameworkFilters';

function applyStyleForGuidance(guide_note, esgFrameworkName) {
    if (!guide_note) return guide_note;

    // Display selected ESG framework first if it exists
    const sortedKeys = Object.keys(guide_note).sort((keyA, keyB) => {
        if (keyA === esgFrameworkName) return -1;
        if (keyB === esgFrameworkName) return 1;
        return 0;
    });

    // return the guide note with some styling
    return sortedKeys.map((key) => (
        <div key={key} style={{ marginBottom: '16px' }}>
            <div>
                <b>{key}</b>
            </div>
            <div>{guide_note[key]}</div>
        </div>
    ));
}

const EsgHelperIcon = ({ title, guideNote, esgQuestionId }) => {
    const { darkMode } = useDarkMode();
    const { openSidebar } = useGuideBoxSidebar();
    const { notifyError } = useNotify();
    const [loading, setLoading] = useState(true);
    const [guide, setGuide] = useState(guideNote);
    const { esgFrameworkName } = useEsgFrameworkFilters();

    // silent check if we have the guide note, so we can render the icon
    useEffect(() => {
        setLoading(true);
        doSwaggerCall('EsgQuestions', 'getEsgQuestionGuideNotes', {
            id: esgQuestionId,
        })
            .then((data) => {
                if (
                    data.guide_note !== '' &&
                    data.guide_note !== '\n' &&
                    Object.keys(data.guide_note).length > 0
                ) {
                    setGuide(applyStyleForGuidance(data.guide_note, esgFrameworkName));
                    setLoading(false);
                }
            })
            .catch((error) => {
                notifyError(error);
                setLoading(false);
            });
    }, [esgQuestionId, notifyError]);

    if (loading) {
        return null;
    }

    return (
        <sup>
            <Tooltip
                title="Click here to open guidance"
                sx={{ cursor: 'pointer' }}
                placement="right"
                onClick={() => openSidebar({ title, content: guide })}
            >
                <HelpOutlineIcon
                    style={{
                        fontSize: '15px',
                        color: !darkMode ? 'grey' : 'white',
                    }}
                />
            </Tooltip>
        </sup>
    );
};

export default EsgHelperIcon;
