import { Grid, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { Form, Formik } from 'formik';
import { doSwaggerCall, useApi } from '../../../hooks/useApi';
import useNotify from '../../../hooks/useNotify';
import { MODAL_TYPE, useModals } from '../../../hooks/useModal';
import { useAuth } from '../../../hooks/useAuth';
import CommonButton from '../../../components/atom/CommonButton';
import YearsOfReportingTable from './YearsOfReportingTable';
import { WARNING_CONTENT_RECALCULATE_TEXT } from '../../../helpers/constans';

const HelperSection = () => {
    return (
        <Typography variant="caption">
            In this table you can edit the company years. Each year has an own IMP.
            <br />- You can <strong>audit a year</strong> by opening a modal and uploading an audit
            document.
            <br />- You can either <strong>upload a PDF file</strong> or fill the form
            <strong> manually from the Inventory management plan</strong> menu.
            <br />- <strong> Under the IMP Status section</strong> you can see the status of the
            IMP. If it is in progress, it means that the survey is not closed yet, or there
            aren&apos;t any uploaded file.
            <br />- <strong> The Download completed IMP section</strong> lets you download the
            uploaded PDF file or the completed form.
            <br />- <strong> The IMP Actions section</strong> lets you upload or download attached
            files, copy or clear the IMP.
        </Typography>
    );
};

// helper function to remove default formula and years that are not in company years from formulaEditorValues
const keepOnlyOverrideValues = (defaultFormula, formulaEditorValues, companyYears) => {
    return Object.keys(formulaEditorValues).reduce((acc, year) => {
        if (companyYears.includes(Number(year)) && formulaEditorValues[year] !== defaultFormula) {
            acc[year] = formulaEditorValues[year];
        }
        return acc;
    }, {});
};

const YearsOfReportingEditor = ({
    companyId,
    defaultFormula,
    setFormulaEditorValues,
    formulaEditorValues,
    setCompanyYears,
    showHelp,
}) => {
    const { user, setUser } = useAuth();
    const { notifyError, notifySuccess } = useNotify();
    const { showModal } = useModals();
    const [data, loading, error, setData, reloadData] = useApi(
        'CompanyYears',
        'getCompanyYearsWithImp',
        {
            companyId,
        }
    );

    const onSubmit = (values) => {
        const req = doSwaggerCall(
            'CompanyYears',
            'saveCompanyYears',
            { companyId },
            {
                companyYears: values.map((companyYear) => ({
                    year: parseInt(companyYear.year, 10),
                    state: companyYear.state,
                })),
                co2eLogicOverride: keepOnlyOverrideValues(
                    defaultFormula,
                    formulaEditorValues,
                    values.map((y) => parseInt(y.year, 10))
                ),
            }
        );
        req.then(() => {
            // update available years in user when user is in the same company
            if (user.company_id === parseInt(companyId, 10)) {
                setUser({
                    ...user,
                    availableYears: values
                        .map((companyYear) => parseInt(companyYear.year, 10))
                        .sort((a, b) => a - b),
                });
            }
            notifySuccess('Company years updated');
            reloadData();
            setCompanyYears(values.map((y) => parseInt(y.year, 10)));
        }).catch((err) => {
            notifyError(err);
        });
    };

    useEffect(() => {
        if (error) {
            notifyError(error);
        }
    }, [error, notifyError]);

    if (loading) {
        return true;
    }

    const isValidData = (values) => {
        // check if there are any duplicates in years
        const years = values.map((companyYear) => parseInt(companyYear.year, 10));
        const uniqueYears = [...new Set(years)];
        if (years.length !== uniqueYears.length) {
            return false;
        }
        // check if there are any empty values
        if (values.map((v) => v.year).includes('') || values.map((v) => v.state).includes('')) {
            return false;
        }
        // check if any year is greater than 2100 and less than 2000
        if (years.some((year) => year > 2100 || year < 2000)) {
            return false;
        }
        return true;
    };

    const initData = {
        companyYears: data?.companyYears
            .map((companyYear) => ({
                year: companyYear.year,
                state: companyYear.state,
                disabled: true,
                impId: companyYear.impForm?.id,
                impState: companyYear.impForm?.isFilled,
                uploadedBy: companyYear.impForm?.uploaded_by,
                url: companyYear.impForm?.url,
                audited: companyYear.audited,
                auditUrl: companyYear.audit_url,
                yearId: companyYear.id,
            }))
            .sort((a, b) => a.year - b.year),
    };

    return (
        // enableReinitialize always false here
        <Formik initialValues={initData} enableReinitialize={false}>
            {({ isSubmitting, values, setFieldValue }) => (
                <Form>
                    {showHelp && (
                        <Grid item xs={12}>
                            <HelperSection />
                        </Grid>
                    )}
                    <YearsOfReportingTable
                        values={values}
                        setFieldValue={setFieldValue}
                        formulaEditorValues={formulaEditorValues}
                        setFormulaEditorValues={setFormulaEditorValues}
                        defaultFormula={defaultFormula}
                        reloadData={reloadData}
                        companyId={companyId}
                        setCompanyYears={setCompanyYears}
                    />
                    <Grid item xs={12}>
                        <CommonButton
                            fullWidth
                            disabled={isSubmitting || !isValidData(values.companyYears)}
                            onClick={() => {
                                showModal(MODAL_TYPE.CONFIRMATION, {
                                    title: `Are you sure you want to edit company years?`,
                                    content: 'This action is irreversible.',
                                    warningContent: WARNING_CONTENT_RECALCULATE_TEXT,
                                    confirm: () => onSubmit(values.companyYears),
                                });
                            }}
                            label="Save"
                            buttonType="primary"
                        />
                    </Grid>
                </Form>
            )}
        </Formik>
    );
};

export default YearsOfReportingEditor;
