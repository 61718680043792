import { ResponsiveBar } from '@nivo/bar';
import React from 'react';
import { Grid, Typography } from '@mui/material';
import { useDarkMode } from '../../../../hooks/useDarkMode';
import {
    BARCHART_PROPS,
    customAxisColor,
    customNivoTheme,
} from '../../../../helpers/chartStyleHelper';
import { formatNumber } from '../../../../helpers/convertUnits';

const CategoryOrTagOrSourceOrSupplierBarChart = ({
    data,
    keys,
    axisLeftLegend,
    indexBy,
    selectedLevel = false,
    name = false,
}) => {
    const { darkMode } = useDarkMode();

    // this format function is temporarily not needed
    /*
    const formatAxis = (value) => {
        let response = value;
        // trimStart for remove starting space
        if (value[0] === ' ') {
            response = value.trimStart();
        }
        if (response.length > 8) {
            response = `${response.substring(0, 8)}...`;
        }
        if (data.length > 10) {
            response = '';
            const words = value.trimStart().split(' ');
            for (let i = 0; i < words.length - 2; i++) {
                response += Number.isNaN(parseFloat(words[i])) ? `${words[i][0]}` : `${words[i]}`;
            }
            if (response.charAt(response.length - 1) === '(') {
                response = response.slice(0, -1);
            }
        }
        return response;
    };
    */

    return (
        <>
            {selectedLevel && name && (
                <Grid item xs={12}>
                    <Typography variant="h5">
                        {selectedLevel} - {name}
                    </Typography>
                </Grid>
            )}
            <Grid
                item
                xs={12}
                style={{
                    height: '40vh',
                    marginBottom: '2vh',
                    marginRight: '-25px',
                    marginLeft: '5px',
                }}
            >
                <ResponsiveBar
                    theme={customNivoTheme(darkMode)}
                    colors={BARCHART_PROPS.colors}
                    enableLabel={BARCHART_PROPS.enableLabel}
                    borderRadius={BARCHART_PROPS.borderRadius}
                    borderWidth={BARCHART_PROPS.borderWidth}
                    labelSkipHeight={BARCHART_PROPS.labelSkipHeight}
                    borderColor={BARCHART_PROPS.borderColor}
                    labelTextColor={BARCHART_PROPS.labelTextColor}
                    padding={BARCHART_PROPS.padding}
                    labelSkipWidth={BARCHART_PROPS.labelSkipWidth}
                    valueFormat={BARCHART_PROPS.valueFormat}
                    data={data}
                    keys={keys}
                    groupMode="grouped"
                    indexBy={indexBy}
                    margin={{ right: 60, bottom: 60, left: 100, top: 20 }}
                    label={(e) => e.id}
                    axisTop={null}
                    axisRight={null}
                    axisBottom={{
                        tickSize: 5,
                        tickPadding: 5,
                        // format: (value) => {
                        //     return formatAxis(value);
                        // },
                    }}
                    axisLeft={{
                        tickSize: 5,
                        tickPadding: 5,
                        legend: axisLeftLegend,
                        legendPosition: 'middle',
                        legendOffset: -90,
                        renderTick: (props) => {
                            return (
                                <text
                                    style={{
                                        fontWeight: 'bolder',
                                    }}
                                    textAnchor="end"
                                    fill={customAxisColor(darkMode)}
                                    fontSize={12}
                                    x={props.x - 4}
                                    y={props.y + 4}
                                >
                                    {formatNumber(props.value)}
                                </text>
                            );
                        },
                    }}
                    valueScale={{ type: 'linear' }}
                />
            </Grid>
        </>
    );
};

export default CategoryOrTagOrSourceOrSupplierBarChart;
