import React, { useCallback, useEffect, useState } from 'react';
import { Alert, Grid } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { doSwaggerCall } from '../../hooks/useApi';
import useNotify from '../../hooks/useNotify';
import QuestionForm from '../../components/questioncomponents/QuestionForm';
import PageWithHeader from '../../components/header/PageWithHeader';

const validateSchema = Yup.object().shape({
    name: Yup.string().required('Required'),
    rank: Yup.number().required('Required'),
});

const defaultCustomQuestion = {
    name: 'New Custom Question',
    rank: 1,
    fields: [],
    guide_note: null,
};

const CustomQuestionEditPage = ({ newCustomQuestion }) => {
    const { notifyError, notifySuccess } = useNotify();
    const { customQuestionId } = useParams();
    const navigate = useNavigate();
    const [customQuestion, setCustomQuestion] = useState(null);
    const [loading, setLoading] = useState(true);

    const navigateBack = '/customquestions';

    const loadCustomQuestion = useCallback(() => {
        setLoading(true);
        doSwaggerCall('CustomQuestions', 'getCustomQuestion', {
            id: customQuestionId,
        })
            .then((res) => {
                setCustomQuestion({
                    id: res.id,
                    name: res.name,
                    rank: res.rank,
                    fields: res.fields,
                    guide_note: res.guide_note,
                });
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                notifyError(err);
                navigate(navigateBack);
            });
    }, []);

    useEffect(() => {
        if (!newCustomQuestion) {
            loadCustomQuestion();
        } else {
            setLoading(false);
        }
    }, [customQuestionId]);

    if (loading) {
        return null;
    }

    const onSubmit = async (values) => {
        try {
            if (newCustomQuestion) {
                await doSwaggerCall(
                    'CustomQuestions',
                    'addCustomQuestion',
                    {},
                    {
                        name: values.name.trim(),
                        rank: parseInt(values.rank, 10),
                        fields: values.fields,
                        guideNote: values.guide_note,
                    }
                );

                navigate(navigateBack);
                notifySuccess('Custom question added');
            } else {
                await doSwaggerCall(
                    'CustomQuestions',
                    'editCustomQuestion',
                    { id: customQuestionId },
                    {
                        name: values.name.trim(),
                        rank: parseInt(values.rank, 10),
                        fields: values.fields,
                        guideNote: values.guide_note,
                    }
                );
                loadCustomQuestion();
                navigate(navigateBack);
                notifySuccess('Custom question updated');
            }
        } catch (err) {
            notifyError(err);
        }
    };

    return (
        <PageWithHeader title={`${customQuestion?.name || ''}`}>
            <Formik
                onSubmit={onSubmit}
                enableReinitialize
                initialValues={customQuestion || { ...defaultCustomQuestion }}
                validationSchema={validateSchema}
            >
                {({ isSubmitting, values, touched, errors, setFieldValue }) => (
                    <Form>
                        <Grid container spacing={2} sx={{ mt: 1 }}>
                            {Object.keys(errors).length > 0 && (
                                <Grid item xs={12}>
                                    <Alert severity="error">
                                        Fix the following errors:
                                        <ul>
                                            {Object.entries(errors).map(([field, errorMessage]) => (
                                                <li key={field}>
                                                    <b>{field}:</b> {errorMessage}
                                                </li>
                                            ))}
                                        </ul>
                                    </Alert>
                                </Grid>
                            )}
                            <Grid item xs={12}>
                                <QuestionForm
                                    values={values}
                                    setFieldValue={setFieldValue}
                                    loading={loading}
                                    touched={touched}
                                    isSubmitting={isSubmitting}
                                    errors={errors}
                                    type="custom"
                                    isNew={newCustomQuestion}
                                />
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </PageWithHeader>
    );
};

export default CustomQuestionEditPage;
