import { Autocomplete, Chip, CircularProgress, Grid, TextField } from '@mui/material';
import React from 'react';
import { useApi } from '../../hooks/useApi';

const FreeSoloSupplierTagAutoComplete = ({ value, setValue, fullWidth, label = '' }) => {
    const [supplierTags, loading, error] = useApi('SupplierTags', 'getAllSupplierTags');

    if (loading) {
        return (
            <Grid
                item
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                }}
            >
                <CircularProgress size="1.5rem" />
            </Grid>
        );
    }

    if (error) {
        return (
            <Grid
                item
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                }}
            >
                <p>Error fetching Supplier tags</p>
            </Grid>
        );
    }

    return (
        <Grid item xs={12}>
            <Autocomplete
                multiple
                freeSolo
                options={supplierTags.supplierTags.map((tag) => tag.name)}
                value={value}
                onChange={(_, newValue) => {
                    setValue(newValue);
                }}
                renderTags={(tags, getTagProps) =>
                    tags.map((option, index) => {
                        return <Chip label={`${option}`} {...getTagProps({ index })} />;
                    })
                }
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={label}
                        placeholder="Press enter to add new"
                        fullWidth={fullWidth}
                        style={{ minWidth: '200px' }}
                    />
                )}
                getOptionLabel={(option) => {
                    return `${option}`;
                }}
            />
        </Grid>
    );
};

export default FreeSoloSupplierTagAutoComplete;
