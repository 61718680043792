import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import React from 'react';

const QCustomCertification = ({
    label,
    values,
    qIdx,
    idx,
    id,
    setFieldValue,
    error,
    required,
    defaultMeasure,
    commonInputProps,
}) => {
    return (
        <FormControl size="small" fullWidth>
            <InputLabel id="answer-select" sx={{ mt: 1 }}>
                Do you have {label} certification?
            </InputLabel>
            <Select
                label={`Do you have ${label} certification?`}
                labelId="answer-select"
                value={values.customDetails[qIdx].fields[idx]?.value || ''}
                onChange={(ev) => {
                    setFieldValue(`customDetails[${qIdx}].fields[${idx}]`, {
                        id,
                        value: ev.target.value,
                        measure: values.customDetails[qIdx].fields[idx]?.measure || defaultMeasure,
                    });
                }}
                InputProps={commonInputProps}
                required={required || false}
                error={Boolean(error)}
                helperText={error}
            >
                <MenuItem value="">Not answered yet</MenuItem>
                <MenuItem value="yes">Yes</MenuItem>
                <MenuItem value="no">No</MenuItem>
            </Select>
        </FormControl>
    );
};

export default QCustomCertification;
