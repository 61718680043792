import { Grid, InputAdornment } from '@mui/material';
import React from 'react';
import SurveyQuestionHeadline from '../../components/SurveyQuestionHeadline';
import {
    withFormikContext,
    withStepAndValidation,
    withSurveySection,
} from '../../components/SurveyStep';
import FloatInput from '../../../../components/atom/FloatInput';

function QScopeThreeCategoryFive({ values, setFieldValue }) {
    return (
        <>
            <Grid item xs={12}>
                <SurveyQuestionHeadline>
                    Please enter your company&apos;s total Scope 3: Waste Generated in Operations
                    emissions in metric tons (mt) of CO2e.
                </SurveyQuestionHeadline>
            </Grid>
            <Grid item xs={12}>
                <FloatInput
                    fullWidth
                    required
                    type="number"
                    label="Scope 3: Purchased Goods and Services CO2e (mt)"
                    value={values?.details?.scope3Cat5}
                    onChange={(ev) => {
                        setFieldValue('details.scope3Cat5', ev.target.rawValue);
                    }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                CO<sub>2</sub>e (mt)
                            </InputAdornment>
                        ),
                    }}
                />
            </Grid>
        </>
    );
}

export default withStepAndValidation(
    withSurveySection(withFormikContext(QScopeThreeCategoryFive)),
    4,
    ['details.scope3Cat5'], // no validation
    (values, errors) => {
        const val = parseFloat(values?.details?.scope3Cat5);
        if (Number.isNaN(val) || val <= 0) {
            // eslint-disable-next-line no-param-reassign
            errors['details.scope3Cat5'] = 'Please enter a value for Scope 3 Category 1';
        }
        return errors;
    },
    (values) =>
        values?.details?.hasScope12FullReport === true &&
        values?.details?.hasScope3Cat5Report === true
);
