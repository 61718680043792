import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { doSwaggerCall } from '../../hooks/useApi';
import useNotify from '../../hooks/useNotify';
import AuditLogsTable from './components/AuditLogsTable';
import useSorting from '../../hooks/useSorting';
import { TABLE_ROW_COUNT_DEFAULT } from '../../helpers/constans';
import PageWithHeader from '../../components/header/PageWithHeader';
import FilterComponent from '../../components/atom/FilterComponent';

const AuditLogsPage = () => {
    const { notifyError } = useNotify();
    const [companyFilter, setCompanyFilter] = useState(0);
    const [{ limit, page }, setParams] = useState({ limit: TABLE_ROW_COUNT_DEFAULT, page: 0 });
    const { sort, sortDir, setSorting } = useSorting(['created_at', 'DESC']);
    const [loading, setLoading] = useState(true);
    const [auditLog, setAuditLog] = useState([]);

    useEffect(() => {
        setLoading(true);
        doSwaggerCall('Logs', 'getAuditLogs', {
            limit,
            offset: page * limit,
            sort,
            sortDir,
            companyFilter,
        })
            .then((res) => {
                setAuditLog(res);
            })
            .catch((error) => {
                notifyError(error);
            })
            .then(() => {
                setLoading(false);
            });
    }, [page, limit, sort, sortDir, setLoading, companyFilter, setAuditLog]);

    return (
        <PageWithHeader
            title="Audit Logs"
            filters={[
                <FilterComponent
                    filterValue={companyFilter}
                    setFilterValue={setCompanyFilter}
                    apiEndpoint="Companies"
                    apiFunction="getAllCompanies"
                    itemName="companies"
                />,
            ]}
        >
            <Grid item xs={12}>
                <AuditLogsTable
                    rows={auditLog?.auditlogs}
                    limit={limit}
                    page={page}
                    setParams={setParams}
                    total={auditLog?.total}
                    loading={loading}
                    sort={sort}
                    sortDir={sortDir}
                    setSorting={setSorting}
                />
            </Grid>
        </PageWithHeader>
    );
};

export default AuditLogsPage;
