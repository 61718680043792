import {
    Autocomplete,
    Chip,
    CircularProgress,
    Grid,
    IconButton,
    InputAdornment,
    TextField,
} from '@mui/material';
import React from 'react';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { useApi } from '../../hooks/useApi';
import useNotify from '../../hooks/useNotify';
import FloatInput from './FloatInput';

const FreeSoloDatalineTagAutoComplete = ({
    value,
    setValue,
    fullWidth,
    label = '',
    percentages,
    setPercentages,
    basicModifyDatalinePermission,
    complexModifyDatalinePermission,
    closedYear = false,
}) => {
    const [datalineTags, loading, error] = useApi('DatalineTags', 'getAllDatalineTags');
    const { notifyError } = useNotify();

    if (loading) {
        return (
            <Grid
                item
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                }}
            >
                <CircularProgress size="1.5rem" />
            </Grid>
        );
    }

    if (error) {
        return (
            <Grid
                item
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                }}
            >
                <p>Error fetching dataline tags</p>
            </Grid>
        );
    }

    // remove from dataline tag list and percentages list
    const onDelete = (name, idx) => () => {
        setValue(value.filter((v) => v !== name));
        const newPercentages = percentages;
        newPercentages.splice(idx, 1);
        setPercentages(newPercentages);
    };

    return (
        <>
            <Grid item xs={12}>
                <Autocomplete
                    multiple
                    freeSolo
                    options={datalineTags.datalineTags.map((tag) => tag.name)}
                    value={value}
                    onChange={(_, newValue) => {
                        // change percentages if necessary
                        if (newValue.length < percentages.length) {
                            const newPercentages = percentages;
                            const removeIdx = value.findIndex((v) => !newValue.includes(v));
                            newPercentages.splice(removeIdx, 1);
                            setPercentages(newPercentages);
                        } else if (newValue.length > percentages.length) {
                            const newPercentages = percentages;
                            setPercentages([...newPercentages, 100]);
                        }
                        setValue(newValue);
                    }}
                    renderTags={(tags, getTagProps) =>
                        tags.map((option, index) => {
                            return <Chip label={`${option}`} {...getTagProps({ index })} />;
                        })
                    }
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={label}
                            placeholder="Press enter to add new"
                            fullWidth={fullWidth}
                            style={{ minWidth: '200px' }}
                        />
                    )}
                    getOptionLabel={(option) => {
                        return `${option}`;
                    }}
                    disabled={
                        (!basicModifyDatalinePermission && !complexModifyDatalinePermission) ||
                        closedYear
                    }
                />
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={2}>
                    {value.map((tag, idx) => (
                        <Grid item key={idx}>
                            <>
                                {/* <Chip key={tag} label={tag} onDelete={onDelete(tag, idx)} /> */}
                                <FloatInput
                                    label=""
                                    type="number"
                                    size="small"
                                    required
                                    fullWidth={false}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">{tag}</InputAdornment>
                                        ),
                                        inputProps: {
                                            min: 1,
                                            max: 100,
                                            sx: {
                                                width: 30,
                                                textAlign: 'right',
                                            },
                                        },
                                        endAdornment: (
                                            <>
                                                <InputAdornment position="end">%</InputAdornment>
                                                <IconButton
                                                    onClick={onDelete(tag, idx)}
                                                    disabled={
                                                        (!basicModifyDatalinePermission &&
                                                            !complexModifyDatalinePermission) ||
                                                        closedYear
                                                    }
                                                >
                                                    <HighlightOffIcon />
                                                </IconButton>
                                            </>
                                        ),
                                    }}
                                    value={percentages[idx] || 100}
                                    onChange={(ev) => {
                                        const resultPercentages = percentages;
                                        if (
                                            parseInt(ev.target.rawValue, 10) > 100 ||
                                            parseInt(ev.target.rawValue, 10) < 1
                                        ) {
                                            notifyError(
                                                `${tag} percentage should be between 1 - 100 %`
                                            );
                                            return;
                                        }
                                        resultPercentages[idx] = parseInt(ev.target.rawValue, 10);
                                        setPercentages(resultPercentages);
                                    }}
                                    disabled={
                                        (!basicModifyDatalinePermission &&
                                            !complexModifyDatalinePermission) ||
                                        closedYear
                                    }
                                />
                            </>
                        </Grid>
                    ))}
                </Grid>
            </Grid>
        </>
    );
};

export default FreeSoloDatalineTagAutoComplete;
