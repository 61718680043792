/**
 * Access token key name in local storage
 * @type {string}
 */
export const ACCESS_TOKEN_KEY = 'accessToken';

/**
 * Logged in user key name in local storage
 * @type {string}
 */
export const LOGGED_IN_USER_KEY = 'loggedInUser';

/**
 * Backend url for openapi
 * @type {string}
 */
export const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || `http://localhost:4000`;

/**
 * Sentry DSN url
 * @type {string|string}
 */
export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN || 'nosentry';
export const SENTRY_ENV = process.env.REACT_APP_SENTRY_ENV || '';

/**
 * HotJar configs
 * @type {string|string}
 */
export const HOTJAR_ID = process.env.REACT_APP_HOTJAR_ID || '';
export const HOTJAR_SV = process.env.REACT_APP_HOTJAR_SV || '';

/**
 * Multivariable emission sources flag
 *  @type {boolean}
 */
export const MULTIVARIABLE_EMISSION_SOURCES = process.env.REACT_APP_MULTIVARIABLE === '1';
console.log(
    `Starting with ${MULTIVARIABLE_EMISSION_SOURCES ? 'multivariable' : 'single'} emission sources`
);

// By default disable the supplier menu
export const SUPPLIER_FEATURE_ENABLED = process.env.REACT_APP_SUPPLIER_FEATURE_ENABLED === '1';

// By default disable the esg menu
export const ESG_FEATURE_ENABLED = process.env.REACT_APP_ESG_FEATURE_ENABLED === '1';

// North Star Carbon & Impact URL
export const NORTH_STAR_CARBON_MANAGEMENT_URL = 'https://www.northstarcarbon.com/';
