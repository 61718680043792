import React from 'react';
import FloatInput from '../../../../components/atom/FloatInput';

const QCustomNumber = ({
    label,
    values,
    qIdx,
    idx,
    id,
    setFieldValue,
    error,
    required,
    defaultMeasure,
    commonInputProps,
}) => {
    return (
        <FloatInput
            fullWidth
            autoComplete="off"
            label={label}
            type="number"
            value={values.customDetails[qIdx].fields[idx]?.value || ''}
            onChange={(ev) => {
                setFieldValue(`customDetails[${qIdx}].fields[${idx}]`, {
                    id,
                    value: ev.target.rawValue,
                    measure: values.customDetails[qIdx].fields[idx]?.measure || defaultMeasure,
                });
            }}
            InputProps={commonInputProps}
            sx={{
                '& .MuiInputLabel-root': {
                    fontSize: '1.5rem',
                    fontWeight: '700',
                },
            }}
            required={required || false}
            error={Boolean(error)}
            helperText={error}
        />
    );
};

export default QCustomNumber;
