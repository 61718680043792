import { Grid, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import BlockWithTitle from '../../../components/atom/BlockWithTitle';

const link = (esgFrameworkQuestion) => {
    return `/esgframeworks/${esgFrameworkQuestion.framework_id}/esgframeworkquestions/edit/${esgFrameworkQuestion.question_id}`;
};

const ConnectedFrameworksTable = ({ esgFrameworkQuestions }) => {
    return (
        <BlockWithTitle title="Connected ESG Frameworks">
            <Grid item xs={12}>
                <Table aria-label="connected frameworks table">
                    <TableHead>
                        <TableRow>
                            <TableCell>ESG Framework</TableCell>
                            <TableCell>ESG Framework Question</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {esgFrameworkQuestions?.map((esgFrameworkQuestion) => (
                            <TableRow
                                key={`${esgFrameworkQuestion.question_id}-${esgFrameworkQuestion.framework_id}`}
                            >
                                <TableCell>{esgFrameworkQuestion.framework_name}</TableCell>
                                <TableCell>
                                    <Link
                                        to={link(esgFrameworkQuestion)}
                                        style={{ color: 'inherit' }}
                                        target="_blank"
                                    >
                                        {esgFrameworkQuestion.question_name}
                                    </Link>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Grid>
        </BlockWithTitle>
    );
};

export default ConnectedFrameworksTable;
