import { Grid, TextField } from '@mui/material';
import React from 'react';
import SurveyQuestionHeadline from '../../components/SurveyQuestionHeadline';
import {
    withFormikContext,
    withStepAndValidation,
    withSurveySection,
} from '../../components/SurveyStep';

function QAssurrance({ values, setFieldValue }) {
    return (
        <>
            <Grid item xs={12}>
                <SurveyQuestionHeadline>
                    Has the data been assured/verified, and if so, what type of assurance was
                    achieved?
                </SurveyQuestionHeadline>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    fullWidth
                    value={values.notes?.assurance}
                    label="Assurance"
                    autoComplete="off"
                    onChange={(e) => {
                        setFieldValue('notes.assurance', e.target.value);
                    }}
                    multiline
                />
            </Grid>
        </>
    );
}

export default withStepAndValidation(
    withSurveySection(withFormikContext(QAssurrance)),
    6,
    ['notes.assurance']
    // no validation
    // always active, no need to set that check
);
