import { Button, Chip, Grid, InputLabel } from '@mui/material';
import React from 'react';
import { useParams } from 'react-router-dom';
import { uploadSurveyDocument } from '../../../../hooks/useApi';
import useNotify from '../../../../hooks/useNotify';

const QCustomFile = ({
    label,
    values,
    qIdx,
    idx,
    id,
    setFieldValue,
    error,
    required,
    defaultMeasure,
}) => {
    const { token } = useParams();
    const { notifyInfo, notifyError } = useNotify();

    const uploadEventHandler = async (event) => {
        if (!event.target.files || !event.target.files.length) {
            return;
        }

        const fileList = Array.from(event.target.files);

        fileList.forEach(async (file) => {
            try {
                notifyInfo(`Upload started for ${file.name}`);
                const newDocument = await uploadSurveyDocument(file, token);
                notifyInfo(`Upload finished for ${file.name}`);

                const currentValue = values.customDetails[qIdx]?.fields[idx]?.value || [];
                const newFile = { id: newDocument.id, name: file.name };

                // Update form value with the new file
                setFieldValue(`customDetails[${qIdx}].fields[${idx}]`, {
                    id,
                    value: [...currentValue, newFile],
                    measure: defaultMeasure,
                });
            } catch (err) {
                notifyError(`Upload failed for ${file.name}`);
            }
        });
    };

    const handleDeleteFile = (fileId) => {
        const updatedFiles = values.customDetails[qIdx]?.fields[idx]?.value.filter(
            (file) => file.id !== fileId
        );
        setFieldValue(`customDetails[${qIdx}].fields[${idx}]`, {
            id,
            value: updatedFiles,
            measure: defaultMeasure,
        });
    };

    const files = values.customDetails[qIdx]?.fields[idx]?.value || [];

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <InputLabel
                    variant="body2"
                    sx={{ mb: 1, fontSize: '1.5rem', fontWeight: '700' }}
                    required={required || false}
                    error={Boolean(error)}
                    helperText={error}
                >
                    {label}
                </InputLabel>
                <Button fullWidth variant="outlined" component="label">
                    Upload document(s)
                    <input
                        type="file"
                        multiple
                        hidden
                        onChange={(e) => uploadEventHandler(e).catch(console.log)}
                    />
                </Button>
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={1}>
                    {/* prevent error that question type doesn't changed */}
                    {Array.isArray(files) &&
                        files.map((file) => (
                            <Grid item key={file.id}>
                                <Chip
                                    error={Boolean(error)}
                                    label={file.name}
                                    onDelete={() => handleDeleteFile(file.id)}
                                    variant="outlined"
                                />
                            </Grid>
                        ))}
                </Grid>
            </Grid>
        </Grid>
    );
};

export default QCustomFile;
