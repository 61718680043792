import {
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    Tooltip,
} from '@mui/material';
import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import WarningIcon from '@mui/icons-material/Warning';
import { MODAL_TYPE, useModals } from '../../hooks/useModal';
import useNotify from '../../hooks/useNotify';
import { useEsgFilters } from '../../hooks/useEsgFilters';
import { doSwaggerCall } from '../../hooks/useApi';
import SortableTableCell from '../atom/SortableTableCell';
import SkeletonTable from '../atom/SkeletonTable';
import { handleChangeRowsPerPage, handlePageChange } from '../../helpers/handleTableFunctions';
import { TABLE_ROW_COUNT_OPTIONS } from '../../helpers/constans';

const QuestionsTable = ({
    rows,
    limit,
    page,
    setParams,
    total,
    loading,
    reloadData,
    sort,
    sortDir,
    setSorting,
    type = 'esg',
}) => {
    const { showModal } = useModals();
    const { notifyError, notifySuccess } = useNotify();
    const { setEsgFilters } = useEsgFilters();

    const api = type === 'esg' ? 'EsgQuestions' : 'CustomQuestions';
    const operation = type === 'esg' ? 'deleteEsgQuestion' : 'deleteCustomQuestion';

    const deleteQuestion = useCallback((id) => {
        doSwaggerCall(api, operation, { id })
            .then(() => {
                notifySuccess(type === 'esg' ? 'Esg question deleted' : 'Custom question deleted');
                // this step is only for esg questions table
                if (type === 'esg') {
                    setEsgFilters({
                        esgCategoryFilter: null,
                        esgSubcategoryFilter: null,
                        esgDisclosureFilter: null,
                        esgQuestionFilter: null,
                    });
                }
                reloadData();
            })
            .catch((err) => {
                notifyError(err);
            });
    }, []);

    return (
        <>
            <Table>
                <TableHead>
                    <TableRow>
                        <SortableTableCell
                            arrow={sort === 'name' ? sortDir : ''}
                            onClick={() => setSorting('name')}
                        >
                            Name
                        </SortableTableCell>
                        {type === 'esg' ? (
                            <SortableTableCell
                                arrow={sort === 'disclosure' ? sortDir : ''}
                                onClick={() => setSorting('disclosure')}
                            >
                                Disclosure
                            </SortableTableCell>
                        ) : (
                            <SortableTableCell
                                arrow={sort === 'rank' ? sortDir : ''}
                                onClick={() => setSorting('rank')}
                            >
                                Rank
                            </SortableTableCell>
                        )}
                        <TableCell>Fields</TableCell>
                        <SortableTableCell
                            arrow={sort === 'guide_note' ? sortDir : ''}
                            onClick={() => setSorting('guide_note')}
                        >
                            Guide note
                        </SortableTableCell>
                        <TableCell />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {loading ? (
                        <SkeletonTable rows={limit} cells={5} />
                    ) : (
                        rows.map((row) => (
                            <TableRow key={row.id}>
                                <TableCell>{row.name}</TableCell>
                                {type === 'esg' ? (
                                    <Tooltip title={`${row.esg_hierarchy}`}>
                                        <TableCell>
                                            {row.esg_disclosure_id === null && (
                                                <Tooltip
                                                    title="This question is not connected to any disclosure"
                                                    placement="right"
                                                >
                                                    <WarningIcon sx={{ mr: 1, color: 'red' }} />
                                                </Tooltip>
                                            )}
                                            {row.esg_disclosure_name}
                                        </TableCell>
                                    </Tooltip>
                                ) : (
                                    <TableCell>{row.rank}</TableCell>
                                )}
                                <TableCell>
                                    {row.fields.length > 0
                                        ? row.fields.map((field) => <p>{field.label}</p>)
                                        : `-`}
                                </TableCell>
                                <Tooltip title={row.guide_note} placement="left">
                                    <TableCell
                                        sx={{
                                            maxWidth: '250px',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'pre-line',
                                        }}
                                    >
                                        {row.guide_note?.length > 200
                                            ? `${row.guide_note.substring(0, 200)}...`
                                            : row.guide_note}
                                    </TableCell>
                                </Tooltip>
                                <TableCell align="right" className="nowrap">
                                    <Link to={`/${type}questions/edit/${row.id}`}>
                                        <Tooltip title="Edit">
                                            <IconButton>
                                                <EditIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </Link>
                                    <IconButton
                                        onClick={() => {
                                            showModal(MODAL_TYPE.CONFIRMATION, {
                                                title: `Are you sure you want to delete this ${type} question?`,
                                                content:
                                                    type === 'esg'
                                                        ? 'This action is irreversible and will remove connected esgs as well.'
                                                        : 'This action is irreversible',
                                                confirm: () => deleteQuestion(row.id),
                                            });
                                        }}
                                    >
                                        <Tooltip title="Delete">
                                            <DeleteIcon />
                                        </Tooltip>
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))
                    )}
                </TableBody>
            </Table>
            <TablePagination
                count={total || 0}
                rowsPerPage={limit}
                component="div"
                page={page}
                onPageChange={(_, newPage) => handlePageChange(newPage, setParams, limit)}
                rowsPerPageOptions={TABLE_ROW_COUNT_OPTIONS}
                onRowsPerPageChange={(event) => handleChangeRowsPerPage(event, setParams)}
            />
        </>
    );
};

export default QuestionsTable;
