import { CircularProgress, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { doSwaggerCall, useApi } from '../../hooks/useApi';
import useNotify from '../../hooks/useNotify';
import SearchInput from '../../components/atom/SearchInput';
import { MODAL_TYPE, useModals } from '../../hooks/useModal';
import useSorting from '../../hooks/useSorting';
import { TABLE_ROW_COUNT_DEFAULT } from '../../helpers/constans';
import InputButton from '../../components/atom/InputButton';
import handleEsgImport from '../../helpers/excelImportEsgQuestionsHelpers';
import { exportToXlsxEsg } from '../../helpers/excelExportEsgsHelpers';
import EsgEditorSwitcher from '../esghierarchy/components/EsgEditorSwitcher';
import QuestionsTable from '../../components/questioncomponents/QuestionsTable';
import PageWithHeader from '../../components/header/PageWithHeader';
import ButtonWithIcon from '../../components/header/ButtonWithIcon';
import transformEsgQuestionFields from './helpers/transformEsgQuestionFields';

const EsgQuestionsPage = () => {
    const { notifyError, notifySuccess } = useNotify();
    const [search, setSearch] = useState('');
    const { sort, sortDir, setSorting } = useSorting(['created_at', 'ASC']);
    const [{ limit, page }, setParams] = useState({ limit: TABLE_ROW_COUNT_DEFAULT, page: 0 });
    const { showModal } = useModals();
    const [generatingXlsx, setGeneratingXlsx] = useState(false);
    const [importingXlsx, setImportingXlsx] = useState(false);

    const [data, loading, error, setData, reloadData] = useApi('EsgQuestions', 'getEsgQuestions', {
        search,
        limit,
        offset: page * limit,
        sort,
        sortDir,
    });

    const collectAllData = async () => {
        // esg questions tab
        const allEsgQuestions = await doSwaggerCall('EsgQuestions', 'getEsgQuestions', {
            search: '',
            limit: 100000,
            offset: 0,
        });
        const mappedEsgQuestions = allEsgQuestions.esgQuestions.map((question) => ({
            category: question.esg_category,
            subcategory: question.esg_subcategory,
            disclosure: question.esg_disclosure_name,
            question_id: question.id,
            question: question.name,
            ...transformEsgQuestionFields(question.fields),
            marked_for_deletion: '',
        }));

        // frameworks tab
        const allFrameworkQuestions = await doSwaggerCall(
            'EsgFrameworks',
            'getEsgFrameworksWithQuestions',
            {}
        );
        const mappedEsgFrameworkQuestions = allFrameworkQuestions.frameworksWithQuestions.map(
            (f) => ({
                framework_name: f.name,
                standard: f.standard,
                disclosure: f.disclosure,
                question_id: f.question_id,
                internal_question: f.internal_question,
                internal_category: f.internal_category,
                internal_subcategory: f.internal_subcategory,
                internal_disclosure: f.internal_disclosure,
                guidance: f.guide_note,
                marked_for_deletion: '',
            })
        );
        return { mappedEsgQuestions, mappedEsgFrameworkQuestions };
    };

    const handleImportEsgs = async (file) => {
        try {
            await handleEsgImport(file, true).then((res) => {
                showModal(MODAL_TYPE.CONFIRMATION_IMPORT_ESG_QUESTIONS, {
                    title: `Are you sure you want to import ESG questions from this xlsx?`,
                    content: 'This action is irreversible.',
                    data: res,
                    setImportingXlsx,
                    confirm: async () => {
                        await handleEsgImport(file, false);
                        notifySuccess('Import ESG questions successful');
                        setImportingXlsx(false);
                        reloadData();
                    },
                });
            });
        } catch (err) {
            setImportingXlsx(false);
            notifyError(err);
        }
    };

    useEffect(() => {
        if (error) {
            notifyError(error);
        }
    }, [error, notifyError]);

    if (error) {
        return null;
    }

    return (
        <>
            <EsgEditorSwitcher />
            <PageWithHeader
                title="ESG Questions"
                searchInput={
                    <SearchInput
                        search={search}
                        setSearch={setSearch}
                        setParams={setParams}
                        limit={limit}
                        fullWidth
                    />
                }
                buttons={[
                    <ButtonWithIcon
                        title="Add ESG question"
                        onClick={() => {
                            showModal(MODAL_TYPE.ADD_ESG_QUESTION);
                        }}
                        icon={<AddIcon />}
                    />,
                    <ButtonWithIcon
                        title="Export ESG master sheet"
                        onClick={async () => {
                            setGeneratingXlsx(true);

                            let listToExport = [];
                            try {
                                listToExport = await collectAllData();
                            } catch (err) {
                                notifyError(err);
                            }
                            exportToXlsxEsg(listToExport, `master_esg_sheet`);
                            setGeneratingXlsx(false);
                            notifySuccess('ESG master sheet exported');
                        }}
                        icon={
                            generatingXlsx ? (
                                <CircularProgress color="inherit" size={20} />
                            ) : (
                                <FileDownloadIcon />
                            )
                        }
                    />,
                    <InputButton
                        id="esg-list-upload"
                        onChange={(e) => {
                            if (e.target.files.length === 0) {
                                return;
                            }
                            setImportingXlsx(true);
                            handleImportEsgs(e.target.files[0]).catch((err) => {
                                notifyError(err);
                            });
                            e.target.value = '';
                        }}
                        accept=".csv, .xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        title="Import ESG master sheet"
                        type="file"
                        loading={importingXlsx}
                        fullWidth
                    />,
                ]}
            >
                <Grid item xs={12}>
                    <QuestionsTable
                        rows={data?.esgQuestions}
                        limit={limit}
                        page={page}
                        setParams={setParams}
                        total={data?.total}
                        loading={loading}
                        reloadData={reloadData}
                        sort={sort}
                        sortDir={sortDir}
                        setSorting={setSorting}
                        type="esg"
                    />
                </Grid>
            </PageWithHeader>
        </>
    );
};

export default EsgQuestionsPage;
