import {
    Grid,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from '@mui/material';
import React, { useState } from 'react';
import Add from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import CommonButton from '../../../components/atom/CommonButton';

const Conversion = ({
    idx,
    values,
    setFieldValue,
    removeConversionLogic,
    isChild = false,
    isConnectedToTemplate = false,
}) => {
    const [fieldId, setFieldId] = useState('');
    const [fieldLabel, setFieldLabel] = useState('');

    const onDeleteField = (fieldIdx) => {
        const editedFields = values.unit_conversion_logic[idx].fields;
        editedFields.splice(fieldIdx, 1);
        return editedFields;
    };

    const onAddField = () => {
        const newField = {
            field_id: fieldId,
            label: fieldLabel,
        };
        const editedFields = [...values.unit_conversion_logic[idx].fields, newField];
        return editedFields;
    };

    return (
        <Grid container>
            {!isChild && !isConnectedToTemplate && (
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        required
                        size="medium"
                        label="Conversion logic name"
                        value={values.unit_conversion_logic[idx].name}
                        onChange={(ev) =>
                            setFieldValue(`unit_conversion_logic[${idx}].name`, ev.target.value)
                        }
                        disabled={isConnectedToTemplate}
                    />
                </Grid>
            )}
            <Grid item xs={12}>
                <TextField
                    fullWidth
                    label="Conversion logic source (optional)"
                    multiline
                    value={values.unit_conversion_logic[idx].source || ''}
                    onChange={(ev) =>
                        setFieldValue(`unit_conversion_logic[${idx}].source`, ev.target.value)
                    }
                    disabled={isConnectedToTemplate}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    label="Admin note (optional)"
                    fullWidth
                    multiline
                    value={values.unit_conversion_logic[idx].admin_note || ''}
                    onChange={(ev) =>
                        setFieldValue(`unit_conversion_logic[${idx}].admin_note`, ev.target.value)
                    }
                    disabled={isConnectedToTemplate}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    fullWidth
                    required
                    label="Formula"
                    value={values.unit_conversion_logic[idx].formula}
                    onChange={(ev) =>
                        setFieldValue(`unit_conversion_logic[${idx}].formula`, ev.target.value)
                    }
                    disabled={isConnectedToTemplate}
                />
            </Grid>
            <Grid item xs={12}>
                <Grid item>
                    <Typography variant="h6">Fields</Typography>
                </Grid>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Field ID</TableCell>
                            <TableCell>Label</TableCell>
                            <TableCell>Prefix</TableCell>
                            <TableCell>Postfix</TableCell>
                            <TableCell width="5%">&nbsp;</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {values.unit_conversion_logic[idx]?.fields?.map((field, fieldIdx) => (
                            <TableRow>
                                <TableCell>
                                    <TextField
                                        fullWidth
                                        value={field.field_id}
                                        onChange={(ev) =>
                                            setFieldValue(
                                                `unit_conversion_logic[${idx}].fields[${fieldIdx}].field_id`,
                                                ev.target.value
                                            )
                                        }
                                        disabled={isConnectedToTemplate}
                                    />
                                </TableCell>
                                <TableCell>
                                    <TextField
                                        fullWidth
                                        value={field.label}
                                        onChange={(ev) =>
                                            setFieldValue(
                                                `unit_conversion_logic[${idx}].fields[${fieldIdx}].label`,
                                                ev.target.value
                                            )
                                        }
                                        disabled={isConnectedToTemplate}
                                    />
                                </TableCell>
                                <TableCell>
                                    <TextField
                                        fullWidth
                                        value={field.prefix}
                                        onChange={(ev) =>
                                            setFieldValue(
                                                `unit_conversion_logic[${idx}].fields[${fieldIdx}].prefix`,
                                                ev.target.value
                                            )
                                        }
                                        disabled={isConnectedToTemplate}
                                    />
                                </TableCell>
                                <TableCell>
                                    <TextField
                                        fullWidth
                                        value={field.postfix}
                                        onChange={(ev) =>
                                            setFieldValue(
                                                `unit_conversion_logic[${idx}].fields[${fieldIdx}].postfix`,
                                                ev.target.value
                                            )
                                        }
                                        disabled={isConnectedToTemplate}
                                    />
                                </TableCell>
                                {!isChild && !isConnectedToTemplate && (
                                    <TableCell>
                                        <IconButton
                                            onClick={() =>
                                                setFieldValue(
                                                    `unit_conversion_logic[${idx}].fields`,
                                                    onDeleteField(fieldIdx)
                                                )
                                            }
                                            disabled={isConnectedToTemplate}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </TableCell>
                                )}
                            </TableRow>
                        ))}
                        {!isChild && !isConnectedToTemplate && (
                            <TableRow>
                                <TableCell colSpan={4} />
                                <TableCell>
                                    <IconButton
                                        onClick={() => {
                                            setFieldValue(
                                                `unit_conversion_logic[${idx}].fields`,
                                                onAddField()
                                            );
                                            setFieldId('');
                                            setFieldLabel('');
                                        }}
                                    >
                                        <Add />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </Grid>
            {!isChild && !isConnectedToTemplate && (
                <Grid item xs={12} md={12}>
                    <CommonButton
                        fullWidth
                        onClick={() => {
                            removeConversionLogic(values, idx);
                        }}
                        label="Delete logic"
                        buttonType="secondary"
                    />
                </Grid>
            )}
        </Grid>
    );
};

export default Conversion;
