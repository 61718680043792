import {
    Checkbox,
    FormControl,
    FormControlLabel,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
} from '@mui/material';
import React, { useCallback } from 'react';
import Add from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { jsonConstants } from '../../../helpers/esgUnitConversions';

const QUESTION_TYPES = [
    { value: 'text', label: 'Text' },
    { value: 'number', label: 'Number' },
    { value: 'select', label: 'Yes/No Questions' },
    { value: 'certification', label: 'Certification' },
    { value: 'file', label: 'File Upload' },
];

const CustomQuestionFieldEditor = ({ values, setFieldValue }) => {
    const { fields } = values;
    const setField = useCallback(
        (newValue) => {
            setFieldValue('fields', newValue);
        },
        [setFieldValue]
    );

    return (
        <Table size="small">
            <TableHead>
                <TableRow>
                    <TableCell width="40%">Label</TableCell>
                    <TableCell width="20%">Type</TableCell>
                    <TableCell width="20%">Default measure</TableCell>
                    <TableCell width="15%" align="center">
                        Required
                    </TableCell>
                    <TableCell width="5%">&nbsp;</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {fields.map(({ id, label, type, defaultMeasure, required }) => (
                    <TableRow key={id}>
                        <TableCell>
                            <TextField
                                fullWidth
                                type="text"
                                autoComplete="off"
                                value={label}
                                label="field label"
                                required
                                onChange={(ev) => {
                                    setField(
                                        fields.map((line) => {
                                            if (line.id === id) {
                                                return {
                                                    id,
                                                    label: ev.target.value,
                                                    type: line.type,
                                                    defaultMeasure: line.defaultMeasure,
                                                    required: line.required,
                                                };
                                            }
                                            return line;
                                        })
                                    );
                                }}
                            />
                        </TableCell>
                        <TableCell>
                            <FormControl fullWidth>
                                <InputLabel>{`${label} type`}</InputLabel>
                                <Select
                                    value={type}
                                    required
                                    onChange={(ev) =>
                                        setField(
                                            fields.map((line) => {
                                                if (line.id === id) {
                                                    return {
                                                        id,
                                                        label: line.label,
                                                        type: ev.target.value,
                                                        // only number can have different measures
                                                        defaultMeasure:
                                                            ev.target.value === 'number'
                                                                ? line.defaultMeasure
                                                                : 'Without unit',
                                                        required: line.required,
                                                    };
                                                }
                                                return line;
                                            })
                                        )
                                    }
                                >
                                    {QUESTION_TYPES.map((value) => (
                                        <MenuItem value={value.value}>{value.label}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </TableCell>
                        <TableCell>
                            <FormControl fullWidth>
                                <InputLabel>Default measure</InputLabel>
                                <Select
                                    value={defaultMeasure}
                                    required
                                    onChange={(ev) =>
                                        setField(
                                            fields.map((line) => {
                                                if (line.id === id) {
                                                    return {
                                                        id,
                                                        label: line.label,
                                                        type: line.type,
                                                        defaultMeasure: ev.target.value,
                                                        required: line.required,
                                                    };
                                                }
                                                return line;
                                            })
                                        )
                                    }
                                    disabled={type !== 'number'}
                                >
                                    {jsonConstants.map((value) => (
                                        <MenuItem value={value.name}>{value.name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </TableCell>
                        <TableCell align="center">
                            <FormControlLabel
                                sx={{ m: 0 }}
                                control={
                                    <Checkbox
                                        checked={required}
                                        onChange={(ev) =>
                                            setField(
                                                fields.map((line) => {
                                                    if (line.id === id) {
                                                        return {
                                                            id,
                                                            label: line.label,
                                                            type: line.type,
                                                            defaultMeasure: line.defaultMeasure,
                                                            required: ev.target.checked,
                                                        };
                                                    }
                                                    return line;
                                                })
                                            )
                                        }
                                    />
                                }
                            />
                        </TableCell>
                        <TableCell>
                            <IconButton
                                onClick={() => {
                                    setField(fields.filter((e) => e.id !== id));
                                }}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </TableCell>
                    </TableRow>
                ))}
                <TableRow>
                    <TableCell colSpan={4} />
                    <TableCell>
                        <IconButton
                            onClick={() => {
                                setField([
                                    ...fields,
                                    {
                                        id: `${Math.random()}`.substring(2),
                                        label: '',
                                        type: 'text',
                                        // set default measure to 'Without unit' for text fields
                                        defaultMeasure:
                                            jsonConstants[jsonConstants.length - 1].name,
                                        required: false,
                                    },
                                ]);
                            }}
                        >
                            <Add />
                        </IconButton>
                    </TableCell>
                </TableRow>
            </TableBody>
        </Table>
    );
};

export default CustomQuestionFieldEditor;
