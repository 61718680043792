import { Grid, Typography } from '@mui/material';
import React from 'react';
import SurveyQuestionHeadline from '../components/SurveyQuestionHeadline';
import {
    withFormikContext,
    withStepAndValidation,
    withSurveySection,
} from '../components/SurveyStep';

function QIntro1({ companyName, year }) {
    return (
        <Grid item xs={12}>
            <SurveyQuestionHeadline>
                Welcome to the {companyName} Sustainability Survey
            </SurveyQuestionHeadline>
            <Typography variant="body2">
                This survey will help you in reporting your company&apos;s emissions and impacts
                associated with {companyName} for the year {year}.
            </Typography>
        </Grid>
    );
}

export default withStepAndValidation(
    withSurveySection(withFormikContext(QIntro1)),
    1,
    []
    // no validation
    // always active, no need to set that check
);
