import React from 'react';
import QNetzeroTarget from '../questions/ghg/QNetzeroTarget';
import QNetzeroTargetScopes from '../questions/ghg/QNetzeroTargetScopes';
import QReportHas from '../questions/ghg/QReportHas';
import QReportTotal from '../questions/ghg/QReportTotal';
import QReportPerScopeHas from '../questions/ghg/QReportPerScopeHas';
import QReportPerScopeNumbers from '../questions/ghg/QReportPerScopeNumbers';
import QCalculatorPercentage from '../questions/ghg/QCalculatorPercentage';
import QCalculator from '../questions/ghg/QCalculator';
import QScopeHas from '../questions/ghg/QScopeHas';
import QScopePercentage from '../questions/ghg/QScopePercentage';
import QScopeOneTwo from '../questions/ghg/QScopeOneTwo';
import QScopeThreeHasCatOne from '../questions/ghg/QScopeThreeHasCatOne';
import QScopeThreeCategoryOne from '../questions/ghg/QScopeThreeCategoryOne';
import QScopeThreeHasCatFive from '../questions/ghg/QScopeThreeHasCatFive';
import QScopeThreeCategoryFive from '../questions/ghg/QScopeThreeCategoryFive';

const GhgPart = ({ survey, token }) => {
    return (
        <>
            {/* --- Net Zero target --- */}
            <QNetzeroTarget />
            <QNetzeroTargetScopes year={survey.year} />
            {/* --- Directly associated GHG emissions --- */}
            <QReportHas companyName={survey.companyName} year={survey.year} />
            <QReportTotal companyName={survey.companyName} year={survey.year} />
            <QReportPerScopeHas companyName={survey.companyName} year={survey.year} />
            <QReportPerScopeNumbers companyName={survey.companyName} year={survey.year} />
            {/* --- Company-Wide GHG emissions --- */}
            <QScopeHas year={survey.year} />
            <QScopeOneTwo year={survey.year} />
            <QScopeThreeHasCatOne year={survey.year} />
            <QScopeThreeCategoryOne token={token} />
            <QScopeThreeHasCatFive year={survey.year} />
            <QScopeThreeCategoryFive token={token} />
            <QScopePercentage companyName={survey.companyName} year={survey.year} />
            {/* --- GHG Emissions calculator --- */}
            <QCalculatorPercentage
                companyName={survey.companyName}
                companyFormula={survey.companyFormula}
            />
            <QCalculator token={token} />
        </>
    );
};
export default GhgPart;
