import { ButtonGroup, CircularProgress, Grid } from '@mui/material';
import React, { useState } from 'react';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import AddIcon from '@mui/icons-material/Add';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { exportToXlsx, getData } from '../../helpers/excelExportHelpers';
import useNotify from '../../hooks/useNotify';
import InputButton from './InputButton';
import { doSwaggerCall } from '../../hooks/useApi';
import ButtonWithIcon from '../header/ButtonWithIcon';
import { EXPORT_SHEET_TYPES } from '../../helpers/constans';

const ImportExportButtonGroup = ({
    type,
    exportType,
    parentLocation,
    companyId,
    handleImport,
    importingXlsx,
    setImportingXlsx,
    navigate,
    parentId = null,
}) => {
    const { notifyError, notifySuccess } = useNotify();
    const [generatingXlsx, setGeneratingXlsx] = useState(false);
    const navigateTo = useNavigate();

    // Helper function to get all locations for export
    const getAllLocationsForExport = async () => {
        const locations = await doSwaggerCall('Locations', 'getAllLocations', {
            pageSize: 10000,
            page: 0,
        });
        return locations.locations.map((l) => ({ name: l.name }));
    };
    const handleClick = async () => {
        setGeneratingXlsx(true);
        let listToExport = [];
        let locationsList = null;
        try {
            if (exportType === EXPORT_SHEET_TYPES.SITE) {
                locationsList = await getAllLocationsForExport();
                listToExport = (await getData(exportType, companyId)).map((s) => ({
                    id: s.id,
                    name: s.name,
                    location: s.location_name,
                    tags: s.tags.map((t) => t).join(', '),
                    marked_for_deletion: '',
                }));
            } else {
                listToExport = (await getData(EXPORT_SHEET_TYPES.LOCATIONS))
                    .map((l) => ({
                        id: l.id,
                        name: l.name,
                        parent_name: l.parent_name,
                        marked_for_deletion: '',
                    }))
                    .sort((a, b) => a.id - b.id);
            }
            exportToXlsx(listToExport, type, exportType, locationsList);
        } catch (err) {
            notifyError(err);
        }
        setGeneratingXlsx(false);
        notifySuccess(
            exportType === EXPORT_SHEET_TYPES.SITE ? 'Sites exported' : 'Locations exported'
        );
    };

    return (
        <Grid item>
            <ButtonGroup color="primary">
                {/* Back button for locations page */}
                {type === 'locations' && parentId !== '1' ? (
                    <ButtonWithIcon
                        title="Back"
                        onClick={() => navigateTo(`/locations/${parentLocation.parent_id}`)}
                        disabled={!parentLocation}
                        icon={<ArrowBackIcon />}
                    />
                ) : null}
                <ButtonWithIcon
                    title={`Add new ${type}`}
                    onClick={navigate}
                    icon={<AddIcon />}
                    disabled={type === 'locations' && !parentLocation} // Disable add location button if no parent
                />
                <ButtonWithIcon
                    title="Export to xlsx"
                    onClick={handleClick}
                    icon={
                        generatingXlsx ? (
                            <CircularProgress color="inherit" size={20} />
                        ) : (
                            <FileDownloadIcon />
                        )
                    }
                />
                <InputButton
                    id="list-upload"
                    onChange={(e) => {
                        if (e.target.files.length === 0) {
                            return;
                        }
                        setImportingXlsx(true);
                        handleImport(e).catch((err) => {
                            notifyError(err);
                        });
                        e.target.value = '';
                    }}
                    accept=".csv, .xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    title="Import from xlsx"
                    type="file"
                    loading={importingXlsx}
                    fullWidth
                />
            </ButtonGroup>
        </Grid>
    );
};
export default ImportExportButtonGroup;
