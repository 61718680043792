import React, { useEffect, useState } from 'react';
import { Grid, LinearProgress, Typography } from '@mui/material';
import useNotify from '../../../hooks/useNotify';
import { doSwaggerCall } from '../../../hooks/useApi';
import EsgQuestionLineChart from './charts/EsgQuestionLineChart';

const transformDataForChart = (scenarioData) => {
    // Group by base site ID and measure
    const groupedData = scenarioData.reduce((acc, series) => {
        const baseId = series.id.replace('-actual', '');
        const { measure, fieldId } = series;
        const key = `${baseId}-${fieldId}-${measure}`;

        if (!acc[key]) {
            acc[key] = {
                measure,
                siteId: baseId,
                actualData: [],
                projectedData: [],
                fieldName: series.data[0]?.fieldName || '',
            };
        }

        // Add data to appropriate array
        if (series.id.includes('-actual')) {
            acc[key].actualData = series.data;
        } else {
            acc[key].projectedData = series.data;
        }

        return acc;
    }, {});

    // Transform grouped data into chart format
    const chartGroups = Object.values(groupedData).map((group) => {
        const chartData = [];

        // Add actual data line if it exists
        if (group.actualData.length > 0) {
            chartData.push({
                id: `${group.siteId} (Actual)`,
                data: group.actualData.map((point) => ({
                    x: point.x,
                    y: point.y,
                })),
            });
        }

        // Add projected data line if it exists
        if (group.projectedData.length > 0) {
            chartData.push({
                id: `${group.siteId} (Planning)`,
                data: group.projectedData.map((point) => ({
                    x: point.x,
                    y: point.y,
                })),
            });
        }

        return {
            measure: group.measure,
            data: chartData,
            fieldName: group.fieldName,
        };
    });

    return chartGroups;
};

const BySelectedEsgScenario = ({ scenario, esgQuestion, fields, setChartData }) => {
    const { notifyError } = useNotify();
    const [scenarioLineData, setScenarioLineData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        doSwaggerCall(
            'Dashboards',
            'getDataForEsgScenarioLineChart',
            {
                scenarioId: scenario,
                esgQuestionId: esgQuestion,
            },
            { fields }
        )
            .then((res) => {
                setScenarioLineData(res);
                setChartData(res.scenarioData);
                setLoading(false);
            })
            .catch((error) => {
                notifyError(error);
                setLoading(false);
            });
    }, [scenario, esgQuestion, JSON.stringify(fields), setChartData, notifyError]);

    return (
        <Grid item xs={12}>
            {scenarioLineData.length === 0 ? (
                <Grid item>
                    {loading ? (
                        <LinearProgress sx={{ mt: 0.25 }} />
                    ) : (
                        <Typography>No data available</Typography>
                    )}
                </Grid>
            ) : (
                <>
                    <Grid item>
                        <Typography variant="h5">{scenarioLineData.questionName}</Typography>
                    </Grid>
                    {transformDataForChart(scenarioLineData.scenarioData).map((group, index) => (
                        <EsgQuestionLineChart
                            key={`${group.measure}-${index}`}
                            data={group.data}
                            fieldName={group.fieldName}
                            axisLeftLegend={group.measure}
                        />
                    ))}
                </>
            )}
        </Grid>
    );
};

export default BySelectedEsgScenario;
