import { TextField } from '@mui/material';
import React from 'react';
import Cleave from 'cleave.js/react';

function CleaveInput({ inputRef, options, ...otherProps }) {
    return <Cleave {...otherProps} htmlRef={inputRef} options={options} />;
}

const FloatInput = ({
    value,
    onChange,
    label,
    prefix = '',
    positiveOnly = true,
    InputProps = {},
    error,
    helperText,
    ...otherProps
}) => {
    return (
        <TextField
            {...otherProps}
            type="text"
            value={value}
            onChange={onChange}
            label={label}
            autoComplete="off"
            inputProps={{
                options: {
                    numeral: true,
                    numeralPositiveOnly: positiveOnly,
                    numericOnly: true,
                    numeralDecimalScale: 5,
                    noImmediatePrefix: true,
                    stripLeadingZeros: true,
                    prefix,
                    rawValueTrimPrefix: true,
                    onChange,
                },
            }}
            InputLabelProps={{
                shrink: true,
            }}
            InputProps={{
                inputComponent: CleaveInput,
                ...InputProps,
            }}
            error={error}
            helperText={helperText}
        />
    );
};

export default FloatInput;
