import React, { useCallback, useEffect, useState } from 'react';
import { doSwaggerCall, useApi } from '../../hooks/useApi';
import useNotify from '../../hooks/useNotify';
import { convertUnitGToKg } from '../../helpers/convertUnits';
import { useGlobalFilters } from '../../hooks/useGlobalFilters';
import DatalineForm from './components/DatalineForm';
import convertDatalineTags from './helpers/convertDatalineTags';

function EditDatalinePage({
    newDataline,
    datalineIdParam = false,
    reloadData,
    setEdited,
    emissionSourceId,
    sites,
    setGroupOpen,
    isAirportCalculatorVisible,
}) {
    const datalineId = datalineIdParam;
    const [loading, setLoading] = useState(true);
    const [loadingDataline, setLoadingDataline] = useState(true);
    const { year, emissionSource, closedYear } = useGlobalFilters();
    const { notifySuccess, notifyError } = useNotify();

    // call getSitesForUsers for get site default_field
    const [siteData, loadingSiteData, errorSiteData] = useApi('Sites', 'getSitesForUsers', {
        pageSize: 1,
        idList: Array.isArray(sites) && sites.length === 1 ? sites[0] : null,
    });
    useEffect(() => {
        if (errorSiteData) {
            notifyError(errorSiteData);
        }
    }, [errorSiteData, notifyError]);

    const [dataline, setDataline] = useState({
        company_id: 1,
        year,
        emission_source_id: undefined,
        emission_source: '',
        emission_description: '',
        calculation_type: null,
        site_id: Array.isArray(sites) && sites.length === 1 ? sites[0] : null,
        co2e: 0,
        fields: {},
        amount: '',
        notes: null,
        sop: null,
        is_estimated: false,
        assigned_user: null,
        owner_user: null,
        documents: null,
        datalineTags: [],
        from_date: null,
        to_date: null,
        cost: null,
        system_managed: false,
        system_managed_emission_source: false,
    });
    const [percentages, setPercentages] = useState([]);

    useEffect(() => {
        if (Array.isArray(sites) && sites.length === 1) {
            setDataline((oldDataline) => {
                return {
                    ...oldDataline,
                    site_default_field: siteData?.sites[0],
                };
            });
        }
    }, [siteData, setDataline]);

    useEffect(() => {
        // emissionSourceId from grouping when source filter is not selected
        if (!emissionSource && !emissionSourceId) {
            setLoading(false);
            return;
        }
        setLoading(true);
        doSwaggerCall('EmissionSources', 'getEmissionSource', {
            id: emissionSourceId,
        })
            .then((s) => {
                setDataline((oldDataline) => {
                    // if the "old dataline" value has anything, no need to override everything
                    if (oldDataline.emission_source !== '') {
                        return oldDataline;
                    }
                    return {
                        ...oldDataline,
                        emission_source_id: emissionSourceId,
                        emission_source: s,
                        emission_description: s.name,
                        calculation_type: s.default_calculation_logic,
                        incorrect: s.base_unit === '',
                    };
                });
                setLoading(false);
            })
            .catch(console.log);
    }, [emissionSource, emissionSourceId, setLoading, setDataline]);

    const loadDataline = useCallback(() => {
        setLoadingDataline(true);
        doSwaggerCall('Datalines', 'getDataline', { id: datalineId })
            .then((res) => {
                // getDataline does not return the actual emission source any more
                return doSwaggerCall('EmissionSources', 'getEmissionSource', {
                    id: res.emission_source_id,
                }).then((fullSource) => {
                    setDataline({
                        id: res.id,
                        year: res.year,
                        emission_source_id: res.emission_source_id,
                        emission_source: fullSource,
                        emission_description: res.emission_description,
                        site_id: res.site_id === null ? 0 : res.site_id,
                        co2e: convertUnitGToKg(res.co2e),
                        calculation_type: res.calculation_type,
                        fields: res.fields,
                        amount: res.amount,
                        notes: res.notes,
                        sop: res.sop,
                        is_estimated: res.is_estimated,
                        assigned_user: res.assignedUser,
                        owner_user: res.ownerUser,
                        modified_user: res.modifiedUser,
                        modified_at: res.modified_at,
                        documents: res.documents,
                        incorrect: res.incorrect,
                        datalineTags: res.datalineTags.map((d) => d.name),
                        from_date: res.from_date,
                        to_date: res.to_date,
                        cost: res.cost,
                        system_managed: res.system_managed,
                        system_managed_emission_source: fullSource.system_managed,
                    });
                    setPercentages(res.datalineTags.map((d) => d.percentage));
                    setLoadingDataline(false);
                });
            })
            .catch((err) => {
                setLoadingDataline(false);
                notifyError(err);
            });
    }, []);

    useEffect(() => {
        if (!newDataline) {
            loadDataline();
        } else {
            setLoadingDataline(false);
        }
    }, [datalineId]);

    const onSubmit = (values, { setSubmitting }) => {
        if (newDataline) {
            const req = doSwaggerCall(
                'Datalines',
                'addDataline',
                { dryRun: false },
                {
                    year: parseInt(values.year, 10),
                    emissionSourceId: values.emission_source_id,
                    emissionDescription: values.emission_description,
                    calculationType: values.calculation_type,
                    siteId: values.site_id,
                    fields: values.fields,
                    amount: parseFloat(values.amount),
                    notes: values.notes,
                    sop: values.sop,
                    isEstimated: values.is_estimated,
                    assignedToUserId: values.assigned_user?.user_id,
                    ownerUserId: values.owner_user?.user_id,
                    documentIdList: values.documents?.map((e) => e.id),
                    datalineTagList: convertDatalineTags(values.datalineTags, percentages),
                    fromDate: values.from_date,
                    toDate: values.to_date,
                    cost: parseFloat(values.cost),
                }
            );
            req.then(() => {
                setEdited(false);
                reloadData();
                notifySuccess('Dataline added');
            }).catch((err) => {
                setEdited(false);
                reloadData();
                notifyError(err);
            });
        } else {
            const req = doSwaggerCall(
                'Datalines',
                'editDataline',
                { id: datalineId },
                {
                    year: parseInt(values.year, 10),
                    emissionSourceId: values.emission_source_id,
                    emissionDescription: values.emission_description,
                    siteId: values.site_id,
                    calculationType: values.calculation_type,
                    fields: values.fields,
                    amount: parseFloat(values.amount),
                    notes: values.notes,
                    sop: values.sop,
                    isEstimated: values.is_estimated,
                    assignedToUserId: values.assigned_user?.user_id,
                    ownerUserId: values.owner_user?.user_id,
                    documentIdList: values.documents.map((e) => e.id),
                    datalineTagList: convertDatalineTags(values.datalineTags, percentages),
                    fromDate: values.from_date || null,
                    toDate: values.to_date || null,
                    cost: parseFloat(values.cost) || null,
                }
            );
            req.then(() => {
                loadDataline();
                setGroupOpen((prev) => {
                    // source has been moved to another site
                    if (dataline.site_id !== values.site_id) {
                        return prev.filter((e) => e !== `${values.site_id}-site`);
                    }

                    return prev;
                });
                setSubmitting(false);
                setEdited(false);
                reloadData();
                notifySuccess('Dataline updated');
            }).catch((err) => {
                notifyError(err);
                setSubmitting(false);
            });
        }
    };

    if (loading || loadingSiteData || loadingDataline) {
        return true;
    }

    return (
        <DatalineForm
            dataline={dataline}
            onSubmit={onSubmit}
            newDataline={newDataline}
            setEdited={setEdited}
            percentages={percentages}
            setPercentages={setPercentages}
            closedYear={closedYear}
            isAirportCalculatorVisible={isAirportCalculatorVisible}
            year={year}
        />
    );
}

export default EditDatalinePage;
