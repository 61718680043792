import React, { useCallback, useEffect, useState } from 'react';
import { Grid, TextField } from '@mui/material';
import { Form, Formik } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { doSwaggerCall } from '../../../hooks/useApi';
import useNotify from '../../../hooks/useNotify';
import BlockWithTitle from '../../../components/atom/BlockWithTitle';
import CommonButton from '../../../components/atom/CommonButton';
import PageWithHeader from '../../../components/header/PageWithHeader';

function EditSubcategoryPage({ newSubcategory }) {
    const { categoryId, subcategoryId } = useParams();
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const [subcategory, setSubcategory] = useState(null);
    const { notifyError, notifySuccess } = useNotify();

    const defaultSubcategory = {
        name: 'New Subcategory',
        guide_note: null,
    };

    const loadSubcategory = useCallback(() => {
        setLoading(true);
        doSwaggerCall('Subcategories', 'getSubcategory', { id: subcategoryId })
            .then((res) => {
                setSubcategory({
                    id: res.id,
                    name: res.name,
                    guide_note: res.guide_note,
                });
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                notifyError(err);
                navigate(`/categories/${categoryId}/subcategories`);
            });
    }, []);

    useEffect(() => {
        if (!newSubcategory) {
            loadSubcategory();
        } else {
            setLoading(false);
        }
    }, [categoryId]);

    const onSubmit = (values, { setSubmitting }) => {
        if (newSubcategory) {
            const req = doSwaggerCall(
                'Subcategories',
                'addSubcategory',
                { id: categoryId },
                {
                    name: values.name.trim(),
                    guideNote: values.guide_note,
                }
            );
            req.then(() => {
                navigate(`/categories/${categoryId}/subcategories`);
                notifySuccess('Subcategory added');
            }).catch((err) => notifyError(err));
        } else {
            const req = doSwaggerCall(
                'Subcategories',
                'editSubcategory',
                { id: subcategoryId },
                {
                    name: values.name.trim(),
                    guideNote: values.guide_note,
                }
            );
            req.then(() => {
                loadSubcategory();
                setSubmitting(false);
                navigate(`/categories/${categoryId}/subcategories`);
                notifySuccess('Subcategory updated');
            }).catch((err) => {
                notifyError(err);
                setSubmitting(false);
            });
        }
    };

    if (loading) {
        return true;
    }

    const validateSchema = Yup.object().shape({
        name: Yup.string().required('Required'),
    });

    return (
        <Formik
            onSubmit={onSubmit}
            initialValues={subcategory || { ...defaultSubcategory }}
            validationSchema={validateSchema}
        >
            {({ isSubmitting, values, setFieldValue, touched, errors }) => (
                <Form>
                    <PageWithHeader
                        title={newSubcategory ? 'Add new Subcategory' : 'Edit Subcategory'}
                    >
                        <Grid item xs={6}>
                            <BlockWithTitle title="Subcategory">
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        label="Name"
                                        autoComplete="off"
                                        value={values.name}
                                        onChange={(ev) => setFieldValue('name', ev.target.value)}
                                        error={touched.name && Boolean(errors.name)}
                                        helperText={touched.name ? errors.name : ''}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        label="Note for guidance (optional)"
                                        fullWidth
                                        autoComplete="off"
                                        multiline
                                        value={values?.guide_note}
                                        onChange={(ev) =>
                                            setFieldValue('guide_note', ev.target.value)
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <CommonButton
                                        fullWidth
                                        onClick={() =>
                                            navigate(`/categories/${categoryId}/subcategories`)
                                        }
                                        label="Cancel"
                                        buttonType="secondary"
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <CommonButton
                                        fullWidth
                                        disabled={isSubmitting}
                                        type="submit"
                                        label="Save"
                                        buttonType="primary"
                                    />
                                </Grid>
                            </BlockWithTitle>
                        </Grid>
                    </PageWithHeader>
                </Form>
            )}
        </Formik>
    );
}

export default EditSubcategoryPage;
