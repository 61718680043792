import { read, utils } from 'xlsx';
import { doSwaggerCall } from '../hooks/useApi';

// helper function to check if email is valid
function validateEmail(email) {
    if (!email) {
        return false;
    }

    if (email.length > 254) {
        return false;
    }

    const tester =
        /^[-!#$%&'*+/0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;
    const valid = tester.test(email);
    if (!valid) {
        return false;
    }

    const parts = email.split('@');
    if (parts[0].length > 64) {
        return false;
    }

    const domainParts = parts[1].split('.');
    if (domainParts.some((part) => part.length > 63)) {
        return false;
    }

    return true;
}

// helper function to check rows
// eslint-disable-next-line require-await
async function checkData(data) {
    const supplierData = data;
    for (let i = 0; i < data.length; i++) {
        // check marked for deletion
        if (
            typeof data[i].Marked_for_deletion !== 'undefined' &&
            data[i].Marked_for_deletion !== ''
        ) {
            supplierData[i].marked_for_deletion = true;
        }
        // check incorrect data (empty cells)
        if (
            typeof data[i].Name === 'undefined' ||
            data[i].Name === '' ||
            typeof data[i].Industry === 'undefined' ||
            data[i].Industry === '' ||
            typeof data[i].Location === 'undefined' ||
            data[i].Location === '' ||
            typeof data[i].ContactName1 === 'undefined' ||
            data[i].ContactName1 === '' ||
            typeof data[i].ContactEmail1 === 'undefined' ||
            data[i].ContactEmail1 === ''
        ) {
            supplierData[i].wrong = 'Wrong data';
        }
    }
    // check duplicates
    supplierData.map((oneSupplierData, index) => {
        supplierData.find((sdata, idx) => {
            if (
                sdata.Name === oneSupplierData.Name &&
                sdata.Industry === oneSupplierData.Industry &&
                sdata.Location === oneSupplierData.Location &&
                idx !== index &&
                typeof sdata.wrong === 'undefined' &&
                typeof oneSupplierData.wrong === 'undefined' &&
                !sdata.marked_for_deletion &&
                !oneSupplierData.marked_for_deletion
            ) {
                supplierData[index].wrong = 'Duplicate';
                supplierData[idx].wrong = 'Duplicate';
                return true;
            }
            return false;
        });
        return supplierData;
    });

    // check email validity
    supplierData.map((oneSupplierData, index) => {
        Object.keys(oneSupplierData).forEach((key) => {
            if (key.includes('ContactEmail')) {
                if (!validateEmail(oneSupplierData[key])) {
                    supplierData[index].wrong = 'Wrong email';
                    return true;
                }
            }
            return false;
        });
        return supplierData;
    });

    // if there is an email, there must be a name
    supplierData.map((oneSupplierData, index) => {
        Object.keys(oneSupplierData).forEach((key) => {
            if (key.includes('ContactEmail')) {
                if (
                    typeof oneSupplierData[key.replace('Email', 'Name')] === 'undefined' ||
                    oneSupplierData[key.replace('Email', 'Name')] === ''
                ) {
                    supplierData[index].wrong = 'Name is missing';
                    return true;
                }
            }
            return false;
        });
        return supplierData;
    });

    // check if there is name, email can not be empty
    supplierData.map((oneSupplierData, index) => {
        Object.keys(oneSupplierData).forEach((key) => {
            if (key.includes('ContactName')) {
                if (
                    typeof oneSupplierData[key.replace('Name', 'Email')] === 'undefined' ||
                    oneSupplierData[key.replace('Name', 'Email')] === ''
                ) {
                    supplierData[index].wrong = 'Email is missing';
                    return true;
                }
            }
            return false;
        });
        return supplierData;
    });

    // check if there is scope, category, must fill both
    supplierData.map((oneSupplierData, index) => {
        const oneExists =
            (typeof oneSupplierData.Scope !== 'undefined' && oneSupplierData.Scope !== '') ||
            (typeof oneSupplierData.Category !== 'undefined' && oneSupplierData.Category !== '');
        if (oneExists && (!oneSupplierData.Scope || !oneSupplierData.Category)) {
            supplierData[index].wrong = 'Scope, category is missing';
            return true;
        }
        return false;
    });

    const checkedData = supplierData.map((row) => ({
        id: row.Id,
        name: row.Name,
        industry: row.Industry,
        location: row.Location,
        contacts: Object.keys(row)
            .filter((key) => key.includes('ContactName'))
            .map((key) => ({
                name: row[key],
                email: row[key.replace('Name', 'Email')],
                phone: row[key.replace('Name', 'Phone')],
            })),
        scope: row.Scope,
        category: row.Category,
        tags: row.Tags ? row.Tags.split('\n').map((tag) => tag.trim()) : [],
        marked_for_deletion: row.marked_for_deletion,
        wrong: row.wrong,
    }));
    return checkedData;
}

// Parse xlsx sheets
export const parseXlsxSheets = (fileName, cb, failCb = () => {}) => {
    const fileReader = new FileReader();
    fileReader.readAsArrayBuffer(fileName);
    fileReader.onload = (e) => {
        const bufferArray = e?.target.result;
        const wb = read(bufferArray, { type: 'buffer' });
        // skip helper sheet (Locations)
        const wsname = wb.SheetNames.filter(
            (name) => name !== 'Locations' && name !== 'Supplier tags'
        );
        const ws = wb.Sheets[wsname];

        const data = utils.sheet_to_json(ws);
        checkData(data)
            .then((checkedData) => {
                cb(checkedData);
            })
            .catch((err) => {
                console.log(err);
                failCb(err);
            });
    };
};

// Handle import
export const runImport = (parsedData, dryRun, year) => {
    let filteredSuppliers = parsedData;
    if (!dryRun) {
        // filter wrong data, duplicate
        filteredSuppliers = parsedData.filter((d) => typeof d.wrong === 'undefined');
    }

    // import only good rows
    try {
        return doSwaggerCall(
            'Suppliers',
            'importSuppliers',
            { dryRun, year },
            { filteredSuppliers }
        );
    } catch (error) {
        throw new Error(error);
    }
};
