import React from 'react';
import { Alert, Grid } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { doSwaggerCall, useApi } from '../../hooks/useApi';
import useNotify from '../../hooks/useNotify';
import QuestionForm from '../../components/questioncomponents/QuestionForm';
import PageWithHeader from '../../components/header/PageWithHeader';
import ConnectedFrameworksTable from './components/ConnectedFrameworksTable';

const validateSchema = Yup.object().shape({
    name: Yup.string().required('Required'),
});

const EsgQuestionEditPage = () => {
    const { notifyError, notifySuccess } = useNotify();
    const { esgQuestionId } = useParams();
    const navigate = useNavigate();
    const [data, loading, error, setData, reloadData] = useApi('EsgQuestions', 'getEsgQuestion', {
        id: esgQuestionId,
    });
    if (error) {
        notifyError(error);
        navigate('/esgquestions');
    }

    if (loading) {
        return null;
    }

    const onSubmit = (values) => {
        const req = doSwaggerCall(
            'EsgQuestions',
            'editEsgQuestion',
            { id: esgQuestionId },
            {
                name: values.name ? values.name.trim() : null,
                esgDisclosureId: values.esg_disclosure_id
                    ? parseInt(values.esg_disclosure_id, 10)
                    : null,
                fields: values.fields || null,
                guideNote: values.guide_note || null,
            }
        );
        req.then(() => {
            reloadData();
            notifySuccess('ESG question edited');
        }).catch((err) => notifyError(err));
    };

    return (
        <PageWithHeader title={`${data?.name || 'Loading...'}`}>
            <Formik
                onSubmit={onSubmit}
                enableReinitialize
                initialValues={data}
                validationSchema={validateSchema}
            >
                {({ isSubmitting, values, touched, errors, setFieldValue }) => (
                    <Form>
                        <Grid container spacing={2} sx={{ mt: 1 }}>
                            {Object.keys(errors).length > 0 && (
                                <Grid item xs={12}>
                                    <Alert severity="error">
                                        Fix the following errors:
                                        <ul>
                                            {Object.entries(errors).map(([field, errorMessage]) => (
                                                <li key={field}>
                                                    <b>{field}:</b> {errorMessage}
                                                </li>
                                            ))}
                                        </ul>
                                    </Alert>
                                </Grid>
                            )}
                            <Grid item xs={12} lg={8}>
                                <QuestionForm
                                    values={values}
                                    setFieldValue={setFieldValue}
                                    loading={loading}
                                    touched={touched}
                                    isSubmitting={isSubmitting}
                                    errors={errors}
                                    type="esg"
                                />
                            </Grid>
                            <Grid item xs={12} lg={4}>
                                <ConnectedFrameworksTable
                                    esgFrameworkQuestions={data?.esgFrameworkQuestions || []}
                                />
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </PageWithHeader>
    );
};

export default EsgQuestionEditPage;
