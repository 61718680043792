import { Grid, IconButton, Tooltip, Typography } from '@mui/material';
import React from 'react';
import domtoimage from 'dom-to-image-more';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { DASHBOARD_DEFAULT_DESCRIPTIONS } from '../../../helpers/constans';
import useNotify from '../../../hooks/useNotify';
import ActionsDropdown from './ActionsDropdown';
import { exportToXlsx } from '../../../helpers/excelExportChartHelpers';

// Filter out the button group from the exported image
function filter(node) {
    if (node.classList) return !node.classList.contains('ChartMenuButton');
    return true;
}

const SCALE_FACTOR = 2;

const PanelTitle = ({
    panel,
    onPanelUpdate,
    onPanelDelete,
    panelId,
    readOnly,
    chartRef,
    hideExportButtons,
    chartData,
}) => {
    const { notifyError } = useNotify();

    // Popover for export buttons
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const exportChartToPng = (panelName) => {
        if (chartRef.current) {
            domtoimage
                .toPng(chartRef.current, {
                    // scaling needed due to legend align issue
                    width: chartRef.current.offsetWidth * SCALE_FACTOR,
                    height: chartRef.current.offsetHeight * SCALE_FACTOR,
                    filter,
                    style: {
                        transform: `scale(${SCALE_FACTOR})`,
                        transformOrigin: 'top left',
                    },
                })
                .then((dataUrl) => {
                    const link = document.createElement('a');
                    link.href = dataUrl;
                    link.download = `${panelName}.png`;
                    link.click();
                })
                .catch((error) => {
                    notifyError('Error exporting chart:', error);
                });
        }
    };

    const exportChartToSvg = (panelName) => {
        if (chartRef.current) {
            domtoimage
                .toSvg(chartRef.current, {
                    // scaling needed due to legend align issue
                    width: chartRef.current.offsetWidth * SCALE_FACTOR,
                    height: chartRef.current.offsetHeight * SCALE_FACTOR,
                    filter,
                    style: {
                        transform: `scale(${SCALE_FACTOR})`,
                        transformOrigin: 'top left',
                    },
                })
                .then((dataUrl) => {
                    const link = document.createElement('a');
                    link.href = dataUrl;
                    link.download = `${panelName}.svg`;
                    link.click();
                })
                .catch((error) => {
                    notifyError('Error exporting chart:', error);
                });
        }
    };

    const handleActionsClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleActionsClose = () => {
        setAnchorEl(null);
    };

    const exportChartToXlsx = () => {
        try {
            exportToXlsx(chartData, panel.name, panel.config.year);
        } catch (err) {
            notifyError(err);
        }
    };

    return (
        <Grid item xs={12} key={panelId}>
            <Grid container item xs={12} sx={{ pb: 1 }} justifyContent="space-between">
                <Grid item xs={9} md={6} sm={6}>
                    <Typography variant="h4">{panel.name}</Typography>
                </Grid>
                {readOnly && hideExportButtons ? null : (
                    <Grid
                        item
                        className="ChartMenuButton"
                        sx={{
                            '@media print': {
                                display: 'none !important',
                            },
                        }}
                    >
                        <Tooltip title="Select action">
                            <IconButton onClick={handleActionsClick}>
                                <MoreHorizIcon />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                )}
                <Grid
                    item
                    xs={3}
                    md={6}
                    sm={6}
                    sx={{
                        textAlign: 'right',
                    }}
                >
                    <ActionsDropdown
                        open={open}
                        anchorEl={anchorEl}
                        setAnchorEl={setAnchorEl}
                        onClose={handleActionsClose}
                        hideExportButtons={hideExportButtons}
                        onPanelUpdate={onPanelUpdate}
                        onPanelDelete={onPanelDelete}
                        exportChartToPng={exportChartToPng}
                        exportChartToSvg={exportChartToSvg}
                        exportChartToXlsx={exportChartToXlsx}
                        panel={panel}
                        panelId={panelId}
                        readOnly={readOnly}
                    />
                </Grid>
            </Grid>
            <Grid container sx={{ pb: 2 }}>
                <Grid item xs={12}>
                    <Typography variant="body1">
                        {panel.description !== ''
                            ? panel.description
                            : DASHBOARD_DEFAULT_DESCRIPTIONS[panel.visual_type]}
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default PanelTitle;
