import {
    Checkbox,
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Tooltip,
    Typography,
} from '@mui/material';
import React from 'react';
import SkeletonTable from '../../../components/atom/SkeletonTable';
import { TABLE_ROW_COUNT_OPTIONS } from '../../../helpers/constans';
import { handleChangeRowsPerPage, handlePageChange } from '../../../helpers/handleTableFunctions';
import SearchInput from '../../../components/atom/SearchInput';

const EsgQuestionsTable = ({
    esgQuestions,
    selectedEsgQuestions,
    setSelectedEsgQuestions,
    limit,
    page,
    setParams,
    loading,
    inputValue,
    setInputValue,
}) => {
    return (
        <Grid item xs={12}>
            <TableContainer component={Paper}>
                <Grid item>
                    <SearchInput
                        search={inputValue}
                        setSearch={setInputValue}
                        setParams={setParams}
                        limit={limit}
                        fullWidth
                    />
                </Grid>
                <div style={{ maxHeight: '600px', overflowY: 'auto', marginTop: '4px' }}>
                    <TableContainer component={Paper}>
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell width="5%" />
                                    <TableCell width="45%">Name</TableCell>
                                    <TableCell width="50%">Fields</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {loading ? (
                                    <SkeletonTable rows={limit} cells={3} />
                                ) : (
                                    esgQuestions?.esgQuestions?.map((esgQuestion) => (
                                        <TableRow key={esgQuestion.id}>
                                            <TableCell>
                                                <Checkbox
                                                    onChange={(e) => {
                                                        if (e.target.checked) {
                                                            setSelectedEsgQuestions([
                                                                ...selectedEsgQuestions,
                                                                esgQuestion,
                                                            ]);
                                                        } else {
                                                            setSelectedEsgQuestions(
                                                                selectedEsgQuestions.filter(
                                                                    (q) => q.id !== esgQuestion.id
                                                                )
                                                            );
                                                        }
                                                    }}
                                                    checked={selectedEsgQuestions.some(
                                                        (q) => q.id === esgQuestion.id
                                                    )}
                                                />
                                            </TableCell>
                                            <Tooltip title={esgQuestion.esg_hierarchy}>
                                                <TableCell>{esgQuestion.name}</TableCell>
                                            </Tooltip>
                                            <TableCell>
                                                {esgQuestion.fields?.map((field) => (
                                                    <p>{field?.label}</p>
                                                ))}
                                            </TableCell>
                                        </TableRow>
                                    ))
                                )}
                            </TableBody>
                        </Table>
                        <TablePagination
                            count={esgQuestions?.total || 0}
                            rowsPerPage={limit}
                            component="div"
                            page={page}
                            onPageChange={(_, newPage) =>
                                handlePageChange(newPage, setParams, limit)
                            }
                            rowsPerPageOptions={TABLE_ROW_COUNT_OPTIONS}
                            onRowsPerPageChange={(event) =>
                                handleChangeRowsPerPage(event, setParams)
                            }
                        />
                    </TableContainer>
                </div>
            </TableContainer>
            <Grid item xs={12} sx={{ mt: 2 }}>
                <Typography variant="subtitle2">
                    Number of selected questions: {selectedEsgQuestions.length}
                </Typography>
            </Grid>
        </Grid>
    );
};

export default EsgQuestionsTable;
