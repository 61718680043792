import { Autocomplete, CircularProgress, Grid, TextField } from '@mui/material';
import React from 'react';
import { useApi } from '../../hooks/useApi';

const FreeSoloAutoComplete = ({ value, setValue, fullWidth, label = '', companyId }) => {
    const [tags, loading, error] = useApi('Tags', 'getTagsInCompany', { id: companyId });

    if (loading) {
        return (
            <Grid
                item
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                }}
            >
                <CircularProgress size="1.5rem" />
            </Grid>
        );
    }

    if (error) {
        return (
            <Grid
                item
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                }}
            >
                <p>Error fetching tags</p>
            </Grid>
        );
    }

    return (
        <Autocomplete
            multiple
            freeSolo
            options={tags.tags.map((tag) => tag.name)}
            value={value}
            onChange={(_, newValue) => {
                setValue(newValue);
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={label}
                    placeholder="Press enter to add new"
                    fullWidth={fullWidth}
                    style={{ minWidth: '200px' }}
                />
            )}
            getOptionLabel={(option) => {
                return `${option}`;
            }}
        />
    );
};

export default FreeSoloAutoComplete;
