import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { doSwaggerCall } from '../../../hooks/useApi';
import useNotify from '../../../hooks/useNotify';
import EsgFrameworkQuestionEditForm from '../components/EsgFrameworkQuestionEditForm';

function EditEsgFrameworkQuestionPage({ newEsgFrameworkQuestion }) {
    const { esgFrameworkId, esgFrameworkQuestionId } = useParams();
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const [frameworkQuestion, setFrameworkQuestion] = useState(null);
    const { notifyError, notifySuccess } = useNotify();

    const defaultframeworkquestion = {
        name: 'New ESG Framework Question',
        rank: 1,
        esg_framework_id: esgFrameworkId,
        hierarchy: '',
    };

    const navigateBack = `/esgframeworks/${esgFrameworkId}/esgframeworkquestions`;

    const loadCategory = useCallback(() => {
        setLoading(true);
        doSwaggerCall('EsgFrameworkQuestions', 'getEsgFrameworkQuestion', {
            id: esgFrameworkQuestionId,
        })
            .then((res) => {
                setFrameworkQuestion({
                    id: res.id,
                    name: res.name,
                    rank: res.rank,
                    esg_framework_id: res.esg_framework_id,
                    esg_question_ids: res.esg_question_ids,
                    hierarchy: res.hierarchy,
                    guide_note: res.guide_note,
                });
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                notifyError(err);
                navigate(navigateBack);
            });
    }, []);

    useEffect(() => {
        if (!newEsgFrameworkQuestion) {
            loadCategory();
        } else {
            setLoading(false);
        }
    }, [esgFrameworkQuestionId]);

    const onSubmit = async (values, { setSubmitting }) => {
        try {
            if (newEsgFrameworkQuestion) {
                await doSwaggerCall(
                    'EsgFrameworkQuestions',
                    'addEsgFrameworkQuestion',
                    {},
                    {
                        name: values.name.trim(),
                        rank: parseInt(values.rank, 10),
                        esgFrameworkId: parseInt(values.esg_framework_id, 10),
                        // only one question can be added to a framework in case of add
                        esgQuestionIds: [parseInt(values.esg_question_ids, 10)],
                        hierarchy: values.hierarchy,
                        guideNote: values.guide_note,
                    }
                );
                navigate(navigateBack);
                notifySuccess('ESG framework question added');
            } else {
                await doSwaggerCall(
                    'EsgFrameworkQuestions',
                    'editEsgFrameworkQuestion',
                    { id: esgFrameworkQuestionId },
                    {
                        esgFrameworkId: parseInt(values.esg_framework_id, 10),
                        name: values.name.trim(),
                        rank: parseInt(values.rank, 10),
                        esgQuestionIds: values.esg_question_ids.map((id) => parseInt(id, 10)),
                        hierarchy: values.hierarchy,
                        guideNote: values.guide_note,
                    }
                );
                setSubmitting(false);
                navigate(navigateBack);
                notifySuccess('ESG framework question updated');
            }
        } catch (err) {
            notifyError(err);
            setSubmitting(false);
        }
    };

    if (loading) {
        return true;
    }

    return (
        <EsgFrameworkQuestionEditForm
            initialValues={frameworkQuestion || { ...defaultframeworkquestion }}
            onSubmit={onSubmit}
            title="ESG Framework question"
            isNew={newEsgFrameworkQuestion}
            navigateBack={`/esgframeworks/${esgFrameworkId}/esgframeworkquestions`}
        />
    );
}

export default EditEsgFrameworkQuestionPage;
