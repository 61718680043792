import { Grid, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import { useParams } from 'react-router-dom';
import { doSwaggerCall, useApi } from '../../../hooks/useApi';
import useNotify from '../../../hooks/useNotify';
import { MODAL_TYPE, useModals } from '../../../hooks/useModal';
import { convertUnitGToKg, convertUnitKgToG } from '../../../helpers/convertUnits';
import { parseXlsx, runImport } from '../../../helpers/excelImportHelpers';
import { useGlobalFilters } from '../../../hooks/useGlobalFilters';
import {
    TABLE_ROW_COUNT_DEFAULT,
    WARNING_CONTENT_RECALCULATE_TEXT,
} from '../../../helpers/constans';
import EmissionFactorsTable from './EmissionFactorsTable';
import EmissionFactorButtonGroup from './EmissionFactorButtonGroup';

const EmissionFactorForm = () => {
    const { notifyError, notifySuccess } = useNotify();
    const { showModal } = useModals();
    const [{ limit, page }, setParams] = useState({ limit: TABLE_ROW_COUNT_DEFAULT, page: 0 });
    const { emissionSourceId } = useParams();
    const { year } = useGlobalFilters();
    const [addValue, setAddValue] = useState({
        locationId: 1,
        co2Factor: 0,
        ch4Factor: 0,
        n2oFactor: 0,
        source: '-',
        year,
        emissionSourceId,
    });

    const [rowEdit, setRowEdit] = useState(false);
    const [importingXlsx, setImportingXlsx] = useState(false);
    const [generatingXlsx, setGeneratingXlsx] = useState(false);

    useEffect(() => {
        setParams({ page: 0, limit });
    }, [year]);

    const [data, loading, error, setData, reloadData] = useApi(
        'EmissionFactors',
        'getEmissionFactors',
        {
            id: emissionSourceId,
            year,
            limit,
            offset: page * limit,
        }
    );
    if (error) {
        notifyError(error);
    }

    const initialData = data
        ? {
              total: data.total,
              factors: data.factors.map((factor) => ({
                  id: factor.id,
                  location_id: factor.location_id,
                  year: factor.year,
                  co2_factor: convertUnitGToKg(factor.co2_factor),
                  ch4_factor: parseFloat(factor.ch4_factor),
                  n2o_factor: parseFloat(factor.n2o_factor),
                  source: factor.source,
              })),
              systemManaged: data.systemManaged,
          }
        : data;

    const deleteFactor = useCallback((id) => {
        doSwaggerCall('EmissionFactors', 'deleteEmissionFactor', { id })
            .then(() => {
                notifySuccess('Factor deleted');
                reloadData();
            })
            .catch((err) => {
                notifyError(err);
            });
    }, []);

    const onSubmit = () => {
        showModal(MODAL_TYPE.CONFIRMATION, {
            title: `Are you sure you want to add this local factor?`,
            warningContent: WARNING_CONTENT_RECALCULATE_TEXT,
            confirm: () => {
                doSwaggerCall(
                    'EmissionFactors',
                    'addEmissionFactor',
                    {},
                    {
                        locationId: parseInt(addValue.locationId, 10),
                        co2Factor: convertUnitKgToG(addValue.co2Factor),
                        ch4Factor: parseFloat(addValue.ch4Factor),
                        n2oFactor: parseFloat(addValue.n2oFactor),
                        year,
                        emissionSourceId: parseInt(addValue.emissionSourceId, 10),
                        source: addValue.source,
                    }
                )
                    .then(() => {
                        notifySuccess('Emission source added');
                        reloadData();
                        setAddValue({
                            locationId: 1,
                            co2Factor: 0,
                            ch4Factor: 0,
                            n2oFactor: 0,
                            year,
                            source: '-',
                            emissionSourceId,
                        });
                        setRowEdit(false);
                    })
                    .catch((err) => notifyError(err));
            },
        });
    };

    const onEdit = (values, idx) => {
        showModal(MODAL_TYPE.CONFIRMATION, {
            title: `Are you sure you want to edit this local factor?`,
            warningContent: WARNING_CONTENT_RECALCULATE_TEXT,
            confirm: () => {
                doSwaggerCall(
                    'EmissionFactors',
                    'editEmissionFactor',
                    { id: values.factors[idx].id },
                    {
                        locationId: parseInt(values.factors[idx].location_id, 10),
                        co2Factor: convertUnitKgToG(values.factors[idx].co2_factor),
                        ch4Factor: parseFloat(values.factors[idx].ch4_factor),
                        n2oFactor: parseFloat(values.factors[idx].n2o_factor),
                        year,
                        emissionSourceId: parseInt(emissionSourceId, 10),
                        source: values.factors[idx].source,
                    }
                )
                    .then(() => {
                        notifySuccess('Emission factor edited');
                    })
                    .catch((err) => notifyError(err));
            },
        });
    };

    const handleImport = (e) => {
        try {
            return parseXlsx(e.target.files[0], (parsedData) => {
                showModal(MODAL_TYPE.CONFIRMATION_IMPORT, {
                    title: `Are you sure you want to import this xlsx?`,
                    content: 'This action is irreversible.',
                    warningContent: WARNING_CONTENT_RECALCULATE_TEXT,
                    data: parsedData,
                    setImportingXlsx,
                    confirm: () => {
                        runImport(parsedData, emissionSourceId, year);
                        notifySuccess('Import successful');
                        setImportingXlsx(false);
                        reloadData();
                    },
                });
            });
        } catch (err) {
            setImportingXlsx(false);
            notifyError(err);
        }
    };

    return (
        <Formik onSubmit={onSubmit} enableReinitialize initialValues={initialData}>
            {({ isSubmitting, values, handleChange, setFieldValue }) => (
                <Form>
                    <Grid container item xs={6} sx={{ mt: 1 }}>
                        <Typography variant="h5">Emission local factors</Typography>
                    </Grid>
                    <Grid
                        item
                        container
                        direction="row"
                        alignItems="center"
                        justifyContent="flex-end"
                    >
                        {!values?.systemManaged ? (
                            <EmissionFactorButtonGroup
                                emissionSourceId={emissionSourceId}
                                year={year}
                                setGeneratingXlsx={setGeneratingXlsx}
                                generatingXlsx={generatingXlsx}
                                setImportingXlsx={setImportingXlsx}
                                importingXlsx={importingXlsx}
                                handleImport={handleImport}
                                reloadData={reloadData}
                            />
                        ) : (
                            <Grid item xs={12} />
                        )}
                        <EmissionFactorsTable
                            loading={loading}
                            limit={limit}
                            values={values}
                            setFieldValue={setFieldValue}
                            rowEdit={rowEdit}
                            handleChange={handleChange}
                            onEdit={onEdit}
                            setRowEdit={setRowEdit}
                            addValue={addValue}
                            setAddValue={setAddValue}
                            isSubmitting={isSubmitting}
                            data={data}
                            page={page}
                            setParams={setParams}
                            deleteFactor={deleteFactor}
                        />
                    </Grid>
                </Form>
            )}
        </Formik>
    );
};

export default EmissionFactorForm;
