import React from 'react';
import { Grid, Typography } from '@mui/material';
import recursiveSumChildren from './helpers/recursiveSumChildren';
import EmissionBox from '../../../components/atom/EmissionBox';

const convertAndSetTotalEmissionsByScope = (treeWithSum) => {
    if (!Array.isArray(treeWithSum.children)) {
        return { totalSum: 0, statisticsData: [] };
    }

    const statisticsData = [];
    treeWithSum.children.forEach((scope) => {
        statisticsData.push({
            scope: scope.name,
            sum: scope.sum,
        });
    });

    return { totalSum: treeWithSum.sum, statisticsData };
};

const TotalEmissionsPaper = ({ yearSumData, customDashboard = false }) => {
    // extend the tree with sum
    const treeWithSum = recursiveSumChildren({ children: yearSumData });
    // convert and set Total emissions
    const { totalSum, statisticsData } = convertAndSetTotalEmissionsByScope(treeWithSum);

    return (
        <>
            <Grid container spacing={2}>
                <EmissionBox key="overall" title="Total (Market-Based)" co2={totalSum} />
                {statisticsData.map((e) => (
                    <EmissionBox
                        key={e.scope}
                        title={`${e.scope}`}
                        co2={e.sum}
                        customDashboard={customDashboard}
                    />
                ))}
            </Grid>
            {customDashboard && (
                <Grid
                    style={{
                        textAlign: 'right',

                        marginTop: '-10px',
                    }}
                >
                    <Typography
                        variant="body2"
                        sx={{
                            fontStyle: 'italic',
                        }}
                    >
                        All values are in CO2e (mt)
                    </Typography>
                </Grid>
            )}
        </>
    );
};

export default TotalEmissionsPaper;
