/**
 * Convert datalines and percentages to one list
 * @param datalineTags datalineTag names list
 * @param percentages percentages list
 */
export default function convertDatalineTags(datalineTags, percentages) {
    const datalineTagList = [];
    datalineTags.forEach((datalineTagName, index) => {
        datalineTagList.push({
            name: datalineTagName,
            percentage: percentages[index],
        });
    });
    return datalineTagList;
}
