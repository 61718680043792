import React, { useEffect, useState } from 'react';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
} from '@mui/material';
import useNotify from '../hooks/useNotify';
import { doSwaggerCall } from '../hooks/useApi';
import { TABLE_ROW_COUNT_DEFAULT } from '../helpers/constans';
import CommonButton from '../components/atom/CommonButton';
import EsgQuestionsTable from '../pages/esgquestions/components/EsgQuestionsTable';
import useDebounce from '../hooks/useDebounce';

// remove ghg and use attribute from fields, set required to false
function modifyFields(fields) {
    return fields.map((field) => {
        const { ghg, use, ...rest } = field;
        return { ...rest, required: false };
    });
}

const CopyEsgQuestionsModal = ({ title, content, reloadData, showModal, MODAL_TYPE }) => {
    const { notifyError, notifySuccess } = useNotify();
    const [esgQuestions, setEsgQuestions] = useState([]);
    const [selectedEsgQuestions, setSelectedEsgQuestions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [{ limit, page }, setParams] = useState({ limit: TABLE_ROW_COUNT_DEFAULT, page: 0 });
    const [saving, setSaving] = useState(false);
    const [search, setSearch] = useState('');
    const [inputValue, setInputValue] = useState(search);
    const debouncedSearch = useDebounce(inputValue, 500);

    const loadEsgQuestions = async (searchParam) => {
        setLoading(true);
        try {
            const res = await doSwaggerCall('EsgQuestions', 'getEsgQuestions', {
                limit,
                offset: page * limit,
                search: searchParam,
            });
            setEsgQuestions(res);
            setLoading(false);
        } catch (error) {
            notifyError(error);
            setLoading(false);
        }
    };

    useEffect(() => {
        if (debouncedSearch !== search) {
            if (setParams) {
                setParams({ page: 0, limit });
            }
            setSearch(debouncedSearch);
        }
    }, [debouncedSearch, search, setSearch, setParams, limit]);

    useEffect(() => {
        loadEsgQuestions(search);
    }, [page, limit, search]);

    const addEsgQuestions = async () => {
        if (selectedEsgQuestions.length === 0) {
            return;
        }
        setSaving(true);
        try {
            await Promise.all(
                selectedEsgQuestions.map((question) =>
                    doSwaggerCall(
                        'CustomQuestions',
                        'addCustomQuestion',
                        {},
                        {
                            name: question.name,
                            rank: 1,
                            fields: modifyFields(question.fields),
                            guideNote: question.guide_note,
                        }
                    )
                )
            );
            notifySuccess('ESG Questions added to Custom Survey Questions.');
            reloadData();
        } catch (error) {
            notifyError('Failed to add questions. Please try again.');
        } finally {
            setSaving(false);
        }
    };

    return (
        <Dialog
            aria-labelledby="copy-esg-questions-modal"
            open
            maxWidth="xl"
            fullWidth
            onClose={() => showModal(MODAL_TYPE.NONE)}
        >
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">{content}</DialogContentText>
                <Grid item xs={12} sx={{ mt: 2 }}>
                    <EsgQuestionsTable
                        esgQuestions={esgQuestions}
                        selectedEsgQuestions={selectedEsgQuestions}
                        setSelectedEsgQuestions={setSelectedEsgQuestions}
                        limit={limit}
                        page={page}
                        setParams={setParams}
                        loading={loading}
                        inputValue={inputValue}
                        setInputValue={setInputValue}
                    />
                </Grid>
            </DialogContent>
            <DialogActions>
                <CommonButton
                    variant="inherit"
                    onClick={() => showModal(MODAL_TYPE.NONE)}
                    label="Cancel"
                    buttonType="secondary"
                />
                <CommonButton
                    disabled={selectedEsgQuestions.length === 0 || saving}
                    label="Copy Questions"
                    buttonType="primary"
                    onClick={() => {
                        addEsgQuestions();
                        showModal(MODAL_TYPE.NONE);
                    }}
                />
            </DialogActions>
        </Dialog>
    );
};

export default CopyEsgQuestionsModal;
