/**
 * Convert esgs and percentages to one list
 * @param esgTags esgTag names list
 * @param percentages percentages list
 */
export default function convertEsgTags(esgTags, percentages) {
    const esgTagList = [];
    esgTags.forEach((esgTagName, index) => {
        esgTagList.push({
            name: esgTagName,
            percentage: percentages[index],
        });
    });
    return esgTagList;
}
