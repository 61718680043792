import { read, utils } from 'xlsx';
import { doSwaggerCall } from '../hooks/useApi';

// Parse xlsx sheets
const parseXlsxSheets = (file) => {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsArrayBuffer(file);
        fileReader.onload = (e) => {
            const bufferArray = e?.target.result;
            const wb = read(bufferArray, { type: 'buffer' });

            // [ { sheet: 'name', data: [...] } ]
            const result = [];
            for (let i = 0; i < wb.SheetNames.length; i++) {
                // Internal structure, Frameworks tabs (in excel)
                const sheetData = utils.sheet_to_json(wb.Sheets[wb.SheetNames[i]], {
                    raw: false, // Keep dates as Date objects
                });
                // check empty sheets
                if (sheetData.length === 1) {
                    let emptyRow = true;
                    Object.entries(sheetData[0]).forEach((attr) => {
                        if (attr[1] !== '') {
                            emptyRow = false;
                        }
                    });
                    if (emptyRow) {
                        continue;
                    }
                }
                result.push({
                    name: wb.SheetNames[i],
                    data: sheetData,
                });
            }
            return resolve(result);
        };
    });
};

// Handle import
export default async function handleEsgFrameworkQuestionsImport(file, frameworkName, dryRun) {
    const xlsxFile = await parseXlsxSheets(file);
    const allFrameworkQuestionLines = xlsxFile[0].data.map((item) => {
        return {
            ...item,
        };
    });

    // check data (dryRun) - show data on confirmation modal - run import (!dryRun)
    const importedEsgQuestions = await doSwaggerCall(
        'EsgFrameworkQuestions',
        'importEsgFrameworkQuestions',
        { dryRun, frameworkName },
        { allFrameworkQuestionLines }
    );
    return importedEsgQuestions;
}
