const MIN_SUPPORTED_SURVEY_VERSION_FOR_DETAILS = 4;

// helper function to filter suppliers for report
export default function filterSuppliersForReport(suppliers, selectedSupplierTag) {
    // only closed survey can be downloaded, so we need to filter those suppliers who have a closed survey or if the survey is not exist, also min supported survey version is important
    const suppliersWithClosedSurvey = suppliers.filter(
        (supplier) =>
            supplier.reviewedBy !== null &&
            supplier.datalineId !== null &&
            supplier.surveyId !== null &&
            (!supplier.details?.v ||
                supplier.details?.v >= MIN_SUPPORTED_SURVEY_VERSION_FOR_DETAILS)
    );
    // then, we need to filter those suppliers who have the selected tag if it is not empty
    return selectedSupplierTag
        ? suppliersWithClosedSurvey.filter((supplier) =>
              supplier.supplierTags.some((tag) => tag.id === selectedSupplierTag)
          )
        : suppliersWithClosedSurvey;
}
