import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Grid, IconButton, Paper, Tooltip, Typography } from '@mui/material';
import { Formik, Form } from 'formik';
import { useParams } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import { doSwaggerCall } from '../../hooks/useApi';
import useNotify from '../../hooks/useNotify';
import formatErrorToString from '../../helpers/formatErrorToString';
import ErrorComponent from './components/ErrorComponent';
import { SurveyStepContextProvider } from './components/SurveyStep';
import QIntro1 from './questions/QIntro1';
import QMethodology from './questions/ghg/QMethodology';
import QNote from './questions/ghg/QNote';
import QAssurance from './questions/ghg/QAssurance';
import SurveyHeader from './components/SurveyHeader';
import SurveyStepper from './components/SurveyStepper';
import SurveyFooterNew from './components/SurveyFooterNew';
import QCustom from './questions/custom/QCustom';
import GhgPart from './components/GhgPart';

const MIN_SUPPORTED_SURVEY_VERSION_FOR_QUESTIONS = 4;

const SURVEY_STEPS = [
    'Introduction',
    'Net Zero target',
    'Directly associated GHG emissions',
    'Company-Wide GHG emissions',
    'GHG Emissions calculator',
    'GHG Review',
    'Additional Questions',
];

const SURVEY_STEPS_WITHOUT_GHG = ['Introduction', 'Additional Questions'];

const DEFAULT_SURVEY = {
    intro: undefined,
    co2e: 0,
    netzero: undefined,
    netzeroTargets: [
        // set default to next year
        { scope: 'Scope 1', year: new Date().getUTCFullYear() + 1 },
        { scope: 'Scope 2 (Market-Based)', year: new Date().getUTCFullYear() + 1 },
        { scope: 'Scope 3', year: new Date().getUTCFullYear() + 1 },
    ],
    details: {
        totalCo2e: '',
        hasScope12FullReport: undefined,
        hasScope3FullReport: undefined,
        hasScope3Cat1Report: undefined,
        hasScope3Cat5Report: undefined,
        havePerProductEmissions: undefined,
        havePerScopeData: undefined,
        haveExactData: undefined,
        scope3Cat1: '',
        scope3Cat5: '',
        calculationPercentage: '',
        datalines: [],
        perProductEmissions: [],
        scope1Co2e: '',
        scope2Co2e: '',
        scope3Co2e: '',
        scopePercentage: '',
        scope1Sum: '',
        scope2Sum: '',
    },
    customDetails: [],
    customQuestions: [],
    notes: {
        methodologies: '',
        assurance: '',
        note: '',
    },
    isGhg: true,
};

const SurveyPage = () => {
    const { token } = useParams();
    const { notifyError, notifySuccess } = useNotify();
    const [loading, setLoading] = useState(true);
    const [survey, setSurvey] = useState();
    const [step, setStep] = useState(1);
    const [loadingError, setLoadingError] = useState(false);
    const [surveySent, setSurveySent] = useState(false);
    const [outdatedSurveyVersion, setOutdatedSurveyVersion] = useState(false);
    const [maxStep, setMaxStep] = useState(SURVEY_STEPS.length);
    const [surveySteps, setSurveySteps] = useState(SURVEY_STEPS);

    const validationFunctionRef = useRef(() => ({}));
    const onSubmit = async (values) => {
        try {
            await doSwaggerCall(
                'Surveys',
                'addSurvey',
                {},
                {
                    token,
                    netzero: values.netzero,
                    netzeroTargets: values.netzeroTargets,
                    details: values.details,
                    notes: values.notes,
                    // if the survey has custom questions, we need to add them as well
                    customDetails: { details: values.customDetails } || {},
                }
            );
            notifySuccess('Survey sent!');
            setSurveySent(true);
        } catch (err) {
            notifyError(err);
        }
    };
    const loadSurvey = useCallback(() => {
        setLoadingError(false);
        setLoading(true);
        doSwaggerCall('Surveys', 'getSurvey', { token })
            .then((res) => {
                if (
                    typeof res?.details?.v === 'number' &&
                    res?.details?.v < MIN_SUPPORTED_SURVEY_VERSION_FOR_QUESTIONS
                ) {
                    setOutdatedSurveyVersion(true);
                }
                setSurvey({
                    // just to fill in the default data
                    ...DEFAULT_SURVEY,
                    // real data from the survey
                    co2e: res.co2e,
                    netzero: res.netzero,
                    netzeroTargets: [...DEFAULT_SURVEY.netzeroTargets].map(({ scope }) => {
                        // if there is a value defined in the response, use that, otherwise keep it as an empty string
                        return {
                            scope,
                            year:
                                res.netzeroTargets?.find((target) => target.scope === scope)
                                    ?.year || '',
                        };
                    }),
                    year: res.year,
                    details: {
                        ...DEFAULT_SURVEY.details,
                        ...res.details,
                    },
                    notes: {
                        ...DEFAULT_SURVEY.notes,
                        ...res.notes,
                    },
                    companyName: res.companyName,
                    companyFormula: res.companyFormula,
                    // custom questions and their answers
                    customQuestions: res.customQuestions,
                    customDetails: res.customQuestions.map((q, qIdx) => {
                        return {
                            id: q.id,
                            name: q.name,
                            rank: q.rank,
                            guideNote: q.guideNote,
                            fields: q.fields.map((field) => {
                                const matchingDetail = res.customDetails.details[qIdx]?.fields.find(
                                    (f) => f.id === field.id
                                );
                                return {
                                    id: field.id,
                                    value: matchingDetail ? matchingDetail.value : '',
                                    measure: matchingDetail?.measure || field.defaultMeasure,
                                    required: field.required,
                                };
                            }),
                        };
                    }),
                    isGhg: res.isGhg,
                });
                // isGhg and customQuestions influence the steps
                const surveyStepsResult = [
                    ...(res.isGhg ? SURVEY_STEPS : SURVEY_STEPS_WITHOUT_GHG),
                ];
                if (res.customQuestions.length > 0) {
                    setSurveySteps(surveyStepsResult);
                    setMaxStep(surveyStepsResult.length);
                } else {
                    setSurveySteps(surveyStepsResult.filter((s) => s !== 'Additional Questions'));
                    setMaxStep(
                        surveyStepsResult.filter((s) => s !== 'Additional Questions').length
                    );
                }
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                notifyError(err);
                setLoadingError(formatErrorToString(err));
            });
    }, []);

    useEffect(() => {
        if (token) {
            loadSurvey();
        } else {
            setLoading(false);
        }
    }, [token]);

    const onNext = useCallback(() => {
        setStep((old) => old + 1);
    }, [setStep]);
    const onPrev = useCallback(() => {
        setStep((old) => old - 1);
    }, [setStep]);

    if (loading) {
        return true;
    }

    return (
        <Grid container alignContent="flex-start" alignItems="center">
            <Grid item xs={12} md={12}>
                {outdatedSurveyVersion && (
                    <Paper sx={{ p: 2, mb: 2 }}>
                        <Grid container spacing={2} style={{ position: 'relative' }}>
                            <Tooltip title="Dismiss" placement="top" arrow>
                                <IconButton
                                    disableRipple
                                    sx={{ position: 'absolute', top: -4, right: -16 }}
                                    onClick={() => setOutdatedSurveyVersion(false)}
                                >
                                    <CloseIcon />
                                </IconButton>
                            </Tooltip>
                            <Grid item xs={12}>
                                <Typography
                                    variant="h5"
                                    color="error"
                                    sx={{ textAlign: 'center', fontWeight: '700' }}
                                >
                                    This survey was created with an old version of the survey tool
                                    and might not have the answers provided in the past.
                                    <br />
                                    <br />
                                    If you encounter any issues, please contact support!
                                </Typography>
                            </Grid>
                        </Grid>
                    </Paper>
                )}
                {loadingError && <ErrorComponent error={loadingError} />}
                {!loadingError && surveySent && (
                    <Paper sx={{ p: 2 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant="h5" sx={{ textAlign: 'center' }}>
                                    Thank you for your time in completing our survey!
                                </Typography>
                            </Grid>
                        </Grid>
                    </Paper>
                )}
                {!loadingError && !surveySent && (
                    <Formik
                        initialValues={survey || { ...DEFAULT_SURVEY }}
                        onSubmit={onSubmit}
                        validate={validationFunctionRef.current}
                    >
                        <Form>
                            <SurveyStepContextProvider
                                setStep={setStep}
                                step={step}
                                validationFunctionRef={validationFunctionRef}
                                isGhg={survey.isGhg}
                                isCustomQuestion={survey.customQuestions.length > 0}
                            >
                                <SurveyHeader companyName={survey.companyName} year={survey.year} />
                                <Grid container>
                                    <SurveyStepper
                                        SURVEY_STEPS={surveySteps}
                                        step={step}
                                        setStep={setStep}
                                    />
                                    <Grid item xs={12} md={9}>
                                        <Grid container spacing={2}>
                                            {/* --- Introduction --- */}
                                            <QIntro1
                                                companyName={survey.companyName}
                                                year={survey.year}
                                            />
                                            {/* --- GHG part + GHG Review --- */}
                                            {survey.isGhg && (
                                                <>
                                                    <GhgPart survey={survey} token={token} />
                                                    <QMethodology />
                                                    <QAssurance />
                                                    <QNote />
                                                </>
                                            )}
                                            {/* --- Custom Questions --- */}
                                            {survey.customQuestions.length > 0 &&
                                                survey.customQuestions.map((q, qIdx) => (
                                                    <QCustom key={q.id} q={q} qIdx={qIdx} />
                                                ))}
                                            <SurveyFooterNew
                                                onNext={step < maxStep ? onNext : false}
                                                onPrev={step > 1 ? onPrev : false}
                                                showSave={step === maxStep}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </SurveyStepContextProvider>
                        </Form>
                    </Formik>
                )}
            </Grid>
        </Grid>
    );
};
export default SurveyPage;
