import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AddIcon from '@mui/icons-material/Add';
import { CircularProgress } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import useNotify from '../../../hooks/useNotify';
import { TABLE_ROW_COUNT_DEFAULT } from '../../../helpers/constans';
import useSorting from '../../../hooks/useSorting';
import { doSwaggerCall, useApi } from '../../../hooks/useApi';
import EsgEditorSwitcher from '../components/EsgEditorSwitcher';
import EsgFrameworkQuestionsTable from '../components/EsgFrameworkQuestionsTable';
import SearchInput from '../../../components/atom/SearchInput';
import PageWithHeader from '../../../components/header/PageWithHeader';
import ButtonWithIcon from '../../../components/header/ButtonWithIcon';
import InputButton from '../../../components/atom/InputButton';
import { MODAL_TYPE, useModals } from '../../../hooks/useModal';
import { exportToXlsxEsgFrameworkQuestion } from '../../../helpers/excelExportEsgFrameworkQuestionsHelpers';
import transformEsgQuestionFields from '../../esgquestions/helpers/transformEsgQuestionFields';
import handleEsgFrameworkQuestionsImport from '../../../helpers/excelImportEsgFrameworkQuestionsHelpers';

const EsgFrameworkQuestionsPage = () => {
    const navigate = useNavigate();
    const { notifyError, notifySuccess } = useNotify();
    const { esgFrameworkId } = useParams();
    const [search, setSearch] = useState('');
    const [frameworkName, setFrameworkName] = useState(null);
    const [{ limit, page }, setParams] = useState({ limit: TABLE_ROW_COUNT_DEFAULT, page: 0 });
    const { sort, sortDir, setSorting } = useSorting(['created_at', 'ASC']);
    const [data, loading, error, setData, reloadData] = useApi(
        'EsgFrameworkQuestions',
        'getEsgFrameworkQuestions',
        {
            esgFrameworkId,
            search,
            limit,
            offset: page * limit,
            sort,
            sortDir,
        }
    );
    const [generatingXlsx, setGeneratingXlsx] = useState(false);
    const [importingXlsx, setImportingXlsx] = useState(false);
    const { showModal } = useModals();

    const deleteFramework = (id) => {
        doSwaggerCall('EsgFrameworkQuestions', 'deleteEsgFrameworkQuestion', { id })
            .then(() => {
                notifySuccess('ESG framework question deleted');
                reloadData();
            })
            .catch((err) => {
                notifyError(err);
            });
    };

    const collectAllData = async () => {
        const allEsgFrameworkQuestions = await doSwaggerCall(
            'EsgFrameworkQuestions',
            'getEsgFrameworkQuestions',
            {
                search: '',
                limit: 100000,
                offset: 0,
                esgFrameworkId,
            }
        );
        const mappedEsgFrameworkQuestions = allEsgFrameworkQuestions.frameworkQuestions.map(
            (q) => ({
                id: q.id,
                standard: q.standard,
                disclosure: q.disclosure,
                marked_for_deletion: '',
                question_id: q.esg_question_id,
                question: q.esg_question_name,
                guidance: q.guide_note,
                ...transformEsgQuestionFields(q.esg_question_fields),
            })
        );
        return mappedEsgFrameworkQuestions;
    };

    const handleImportEsgFrameworkQuestions = async (file) => {
        try {
            await handleEsgFrameworkQuestionsImport(file, frameworkName, true).then((res) => {
                showModal(MODAL_TYPE.CONFIRMATION_IMPORT_ESG_FRAMEWORK_QUESTIONS, {
                    title: `Are you sure you want to import ESG framework questions from this xlsx?`,
                    content: 'This action is irreversible.',
                    data: res,
                    setImportingXlsx,
                    confirm: async () => {
                        await handleEsgFrameworkQuestionsImport(file, frameworkName, false);
                        notifySuccess('Import ESG framework questions successful');
                        setImportingXlsx(false);
                        reloadData();
                    },
                });
            });
        } catch (err) {
            setImportingXlsx(false);
            notifyError(err);
        }
    };

    const getFrameworkName = async () => {
        try {
            const res = await doSwaggerCall('EsgFrameworks', 'getEsgFramework', {
                id: esgFrameworkId,
            });
            setFrameworkName(res?.name);
        } catch (err) {
            notifyError(err);
        }
    };

    useEffect(() => {
        if (error) {
            notifyError(error);
        }
        if (data) {
            getFrameworkName();
        }
    }, [error, notifyError, data]);

    return (
        <>
            <EsgEditorSwitcher />
            <PageWithHeader
                title={`ESG Framework questions for (${frameworkName || 'Loading...'})`}
                searchInput={
                    <SearchInput
                        search={search}
                        setSearch={setSearch}
                        setParams={setParams}
                        limit={limit}
                        fullWidth
                    />
                }
                buttons={[
                    <ButtonWithIcon
                        title="Back"
                        onClick={() => navigate(`/esgframeworks`)}
                        icon={<ArrowBackIcon />}
                    />,
                    <ButtonWithIcon
                        title="Add new framework question"
                        onClick={() =>
                            navigate(`/esgframeworks/${esgFrameworkId}/esgframeworkquestions/new`)
                        }
                        icon={<AddIcon />}
                    />,
                    <ButtonWithIcon
                        title="Export ESG framework questions sheet"
                        onClick={async () => {
                            setGeneratingXlsx(true);

                            let listToExport = [];
                            try {
                                listToExport = await collectAllData();
                            } catch (err) {
                                notifyError(err);
                            }
                            exportToXlsxEsgFrameworkQuestion(
                                listToExport,
                                `esg_framework_questions_sheet`
                            );
                            setGeneratingXlsx(false);
                            notifySuccess('ESG framework questions sheet exported');
                        }}
                        icon={
                            generatingXlsx ? (
                                <CircularProgress color="inherit" size={20} />
                            ) : (
                                <FileDownloadIcon />
                            )
                        }
                    />,
                    <InputButton
                        id="esg-framework-question-list-upload"
                        onChange={(e) => {
                            if (e.target.files.length === 0) {
                                return;
                            }
                            setImportingXlsx(true);
                            handleImportEsgFrameworkQuestions(e.target.files[0]).catch((err) => {
                                notifyError(err);
                            });
                            e.target.value = '';
                        }}
                        accept=".csv, .xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        title="Import ESG framework questions"
                        type="file"
                        loading={importingXlsx}
                        fullWidth
                    />,
                ]}
            >
                <EsgFrameworkQuestionsTable
                    title="ESG Framework Questions"
                    rows={data?.frameworkQuestions}
                    limit={limit}
                    page={page}
                    setParams={setParams}
                    total={data?.total}
                    loading={loading}
                    sort={sort}
                    sortDir={sortDir}
                    setSorting={setSorting}
                    deleteRow={deleteFramework}
                />
            </PageWithHeader>
        </>
    );
};

export default EsgFrameworkQuestionsPage;
