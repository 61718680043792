import { Grid, InputAdornment } from '@mui/material';
import React from 'react';
import SurveyQuestionHeadline from '../../components/SurveyQuestionHeadline';
import {
    withFormikContext,
    withStepAndValidation,
    withSurveySection,
} from '../../components/SurveyStep';
import FloatInput from '../../../../components/atom/FloatInput';

function QScopeOneTwo({ values, setFieldValue, year }) {
    return (
        <>
            <Grid item xs={12}>
                <SurveyQuestionHeadline>
                    Please enter company&apos;s total Scope 1 and Scope 2 emissions in metric tons
                    (mt) of CO2e for the year {year}.
                </SurveyQuestionHeadline>
            </Grid>
            <Grid item xs={6}>
                <FloatInput
                    fullWidth
                    required
                    type="number"
                    label="Scope 1 sum CO2e (mt)"
                    value={values?.details?.scope1Sum}
                    onChange={(ev) => {
                        setFieldValue('details.scope1Sum', ev.target.rawValue);
                    }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                CO<sub>2</sub>e (mt)
                            </InputAdornment>
                        ),
                    }}
                />
            </Grid>
            <Grid item xs={6}>
                <FloatInput
                    fullWidth
                    required
                    type="number"
                    label="Scope 2 sum CO2e (mt)"
                    value={values?.details?.scope2Sum}
                    onChange={(ev) => {
                        setFieldValue('details.scope2Sum', ev.target.rawValue);
                    }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                CO<sub>2</sub>e (mt)
                            </InputAdornment>
                        ),
                    }}
                />
            </Grid>
        </>
    );
}

export default withStepAndValidation(
    withSurveySection(withFormikContext(QScopeOneTwo)),
    4,
    ['details.scope1Sum', 'details.scope2Sum'], // no validation
    (values, errors) => {
        const scope1 = parseFloat(values?.details?.scope1Sum);
        if (Number.isNaN(scope1) || scope1 <= 0) {
            // eslint-disable-next-line no-param-reassign
            errors['details.scope1Sum'] = 'Please enter a value for Scope 1.';
        }
        const scope2 = parseFloat(values?.details?.scope2Sum);
        if (Number.isNaN(scope2) || scope2 <= 0) {
            // eslint-disable-next-line no-param-reassign
            errors['details.scope2Sum'] = 'Please enter a value for Scope 2.';
        }
        return errors;
    },
    (values) => values?.details?.hasScope12FullReport === true
);
